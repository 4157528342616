import React, { useState, useCallback, useMemo, useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  Table,
  Label,
  Paragraph,
  Button,
  PrefabModal,
  Heading,
  Link,
  SpinnerLoader,
} from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { ConvertDecimalToString } from "../CommonComp/Functions";
import "../../Css/Claims/Claims.css";
import styles from "../../Css/Claims/Claims.module.scss";
import wizardStyles from "../../Css/Claims/ClaimSubmissionWizard.module.scss";
import FilesUpload from "./ClaimSubmissionWizard/FilesUpload";
import ClaimsStore from "../../Stores/ClaimsStore";
import SettingsStore from "../../Stores/SettingsStore";
import "../../Css/CommonCss/General.css";

const ClaimsDetailPanelContent = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { documentTypes, resubmitClaimFiles, getMyClaims } =
    useContext(ClaimsStore);
  const { privateSettings } = useContext(SettingsStore);
  const [files, setFiles] = useState([]);
  const [submittingFiles, setSubmittingFiles] = useState(false);
  const [showSubmissionSuccessModal, setShowSubmissionSuccessModal] =
    useState(false);
  const [showSubmissionFailModal, setShowSubmissionFailModal] = useState(false);

  const failureContactInfo = useMemo(() => {
    return DOMPurify.sanitize(
      privateSettings.eClaims.ResourceStrings[
        "NN.Claim.Submission.FailureContactInfo"
      ],
      { USE_PROFILES: { html: true } },
    );
  }, [privateSettings.eClaims.ResourceStrings]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
    },
    [files, setFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
    },
    [files, setFiles],
  );

  const fileTypes = useMemo(() => {
    return documentTypes?.map((documentType) => ({
      text: documentType.Title,
      value: documentType.Name,
      isMandatory: false,
    }));
  }, [documentTypes]);

  const submitFiles = useCallback(async () => {
    if (files.length > 0) {
      setSubmittingFiles(true);
      const isSuccess = await resubmitClaimFiles(
        props.rowData.ClaimNo,
        props.rowData.SourceSystemCaseNumber,
        files,
      );
      if (!isSuccess) {
        setShowSubmissionFailModal(true);
      } else {
        setShowSubmissionSuccessModal(true);
      }
    }
    setSubmittingFiles(false);
  }, [
    files,
    props.rowData.ClaimNo,
    props.rowData.SourceSystemCaseNumber,
    resubmitClaimFiles,
    setShowSubmissionFailModal,
    setShowSubmissionSuccessModal,
  ]);

  const handleContiune = useCallback(() => {
    if (showSubmissionSuccessModal) {
      getMyClaims();
      navigate("/myClaims");
    } else {
      setShowSubmissionFailModal(false);
    }
  }, [
    getMyClaims,
    navigate,
    showSubmissionSuccessModal,
    setShowSubmissionFailModal,
  ]);

  return (
    <>
      {props.rowData.Status === "PendingRefund" ? (
        <div className={styles.detailPanelContainerRejection}>
          <div className={styles.claimPendingRefundNotesSection}>
            <Label text={props.rowData.StatusName} />
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.rowData.StatusNotes, {
                  USE_PROFILES: { html: true },
                }),
              }}
            />
          </div>
        </div>
      ) : props.rowData.Status !== "Rejected" &&
        props.rowData.Status !== "Pending" ? (
        <div className={styles.detailPanelContainer}>
          <Table
            localeText={{ noRowsLabel: t("global.no-results-found") }}
            columns={[
              {
                align: "left",
                field: "BenefitName",
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.output-type"),
                sortable: false,
                width: 200,
              },
              {
                align: "left",
                field: "RequestedAmount",
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.amount-requested"),
                valueFormatter: (props) => {
                  return ConvertDecimalToString(props.value);
                },
                sortable: false,
                flex: 1,
              },
              {
                align: "left",
                field: "RemovableAmount",
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.amount-deductible"),
                valueFormatter: (props) => {
                  return ConvertDecimalToString(props.value);
                },
                sortable: false,
                flex: 1,
              },
              {
                align: "left",
                field: "CompensationPercent",
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.cost-performance"),
                valueFormatter: (props) => {
                  return props.value + "%";
                },
                sortable: false,
                width: 120,
              },
              {
                align: "left",
                field: "CompensationAmount",
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.compensation-amount"),
                valueFormatter: (props) => {
                  return ConvertDecimalToString(props.value);
                },
                sortable: false,
                flex: 1,
              },
            ]}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            getRowId={(row) => row.RowId}
            rows={props.rowData.Benefits}
          />
        </div>
      ) : (
        <div className={styles.detailPanelContainerRejection}>
          <div className={styles.claimRejectionReasonSection}>
            <Label
              className={styles.rejectionLabel}
              text={
                props.rowData.Status === "Rejected"
                  ? t("claim.rejection-reason")
                  : t("claim.pending-reason")
              }
            />
            <Paragraph>{props.rowData.StatusReason}</Paragraph>
          </div>
          {props.rowData.StatusNotes ? (
            <>
              <hr className={styles.rejectionDetailsPanelSeparator} />

              <div className={styles.claimRejectionNotesSection}>
                <Label text="Λεπτομέρειες" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.rowData.StatusNotes, {
                      USE_PROFILES: { html: true },
                    }),
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {props.rowData.Status === "Pending" ? (
            <div className={wizardStyles.claimSubmissionWizardContainer}>
              <PrefabModal
                isOpen={submittingFiles}
                width="500px"
                height="auto"
                headingText={" "}
                children={
                  <div className={wizardStyles.submissionModalLoading}>
                    <SpinnerLoader variant="Large" />
                    <Paragraph>{t("claim.processing-request")}</Paragraph>
                  </div>
                }
              />
              <PrefabModal
                isOpen={showSubmissionSuccessModal || showSubmissionFailModal}
                onClose={() => handleContiune()}
                hasCloseButton={true}
                width="500px"
                height="auto"
                headingText={" "}
                children={
                  <div className={wizardStyles.submissionModalResult}>
                    <span className={wizardStyles.header}>
                      <Heading isFirstSection>
                        {showSubmissionSuccessModal
                          ? t("claim.submission.completed-succesfull")
                          : t("claim.submission.completed-failure")}
                      </Heading>
                    </span>
                    <div className={wizardStyles.submissionModalResultDiv}>
                      {showSubmissionSuccessModal ? (
                        <Paragraph>{t("claim.return-list")}</Paragraph>
                      ) : (
                        <p
                          className="htmlParagraph"
                          dangerouslySetInnerHTML={{
                            __html: failureContactInfo,
                          }}
                        ></p>
                      )}
                      <Link
                        text={t("claim.compensation-list")}
                        variant="SecondaryStandAlone"
                        iconProps={{
                          name: "ChevronRight",
                          placement: "Right",
                        }}
                        onClick={handleContiune}
                      />
                    </div>
                  </div>
                }
              />
              <FilesUpload
                filesTypes={fileTypes}
                files={files}
                addFile={addFile}
                removeFile={removeFile}
                policyNumber={props.rowData.PolicyNo}
                onlyShowFilledFileTypes={true}
                hideHeader={true}
                noFileSelectedText={t("claim.add-documents")}
              />
              <div
                className={`${styles.filesSubmitButton} ${
                  files.length > 0 && !submittingFiles ? "" : styles.disabledBtn
                }`}
              >
                <Button
                  variant="PrimarySmall"
                  type="Button"
                  onClick={() => {
                    if (!submittingFiles) submitFiles();
                  }}
                >
                  {t("global.submission")}
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default observer(ClaimsDetailPanelContent);
