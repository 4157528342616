import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { Icon } from "@nn-design-system/react-component-library";
import HRStore from "../../Stores/HRStore";

const DetailsNavigation = ({ details, isDisabled }) => {
  const { t } = useTranslation();
  const { isHrRole } = useContext(HRStore);

  if (isDisabled) {
    return <span>{t("commons.do-not-have-access")}</span>;
  }

  return (
    <Link
      className="go-to-details"
      to={isHrRole ? "/hr/contracts/details" : "/myContracts/details"}
      state={toJS(details)}
    >
      <span className="go-to-details-text">{t("contracts.more")}</span>
      <Icon
        className="go-to-details-icon"
        name="ChevronRight"
        color="#EA650D"
        variant="Small"
      />
    </Link>
  );
};

export default DetailsNavigation;
