import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  Card,
  Heading,
  StepIndicator,
} from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import KycStore from "../../Stores/KycStore";
import LoaderStore from "../../Stores/LoaderStore";
import { useTranslation } from "react-i18next";
const LayoutKyc = () => {
  const { t } = useTranslation();
  const { currentPage, setCurrentPage, kycAcceptanceIsLoading } =
    useContext(KycStore);
  const loaderStore = useContext(LoaderStore);

  useEffect(() => {
    return () => {
      setCurrentPage(undefined);
    };
  }, []);

  useEffect(() => {
    loaderStore.setLoaderForData(kycAcceptanceIsLoading);
  }, [kycAcceptanceIsLoading]);

  return (
    <section className="nn-container">
      {currentPage && (
        <StepIndicator
          currentStepNumber={currentPage}
          variant="WithLabels"
          withLabelsVariantProps={{
            steps: [
              {
                labelText: `${t("kyc.step")} 1 - ${t("kyc.fill")}`,
              },
              {
                labelText: `${t("kyc.step")} 2 - ${t("kyc.completion")}`,
              },
            ],
          }}
        />
      )}
      <Card
        maxWidth="746px"
        mr="auto"
        ml="auto"
        mt="20px"
        mb="20px"
        variant="Attentional"
      >
        <section className="card-kyc-container">
          <Heading isFirstSection variant="Large">
            {t("kyc.app-update-details")}
          </Heading>
          <Outlet />
        </section>
      </Card>
    </section>
  );
};

export default observer(LayoutKyc);
