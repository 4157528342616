import React, { useCallback, useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ClaimsStore from "../../../Stores/ClaimsStore";
import OutsideHospital from "./OutsideHospital";
import Allowance from "./Allowance";
import OutOfHospitalMedicalProcedures from "./OutOfHospitalMedicalProcedures";
import { ExpenseCategory } from "../../../Consts/Claims";
import ConfirmationModal from "./ConfirmationModal";

const Step2 = (props) => {
  const { t } = useTranslation();
  const claimsStore = useContext(ClaimsStore);
  const { basicSubmissionInfoToSubmit } = claimsStore;
  const [showBackConfirmationModal, setShowBackConfirmationModal] =
    useState(false);

  const handleBackButton = useCallback(() => {
    if (claimsStore.claimsListToSubmit.length > 0) {
      setShowBackConfirmationModal(true);
    } else {
      props.previousStep();
    }
  }, [
    claimsStore.claimsListToSubmit.length,
    props,
    setShowBackConfirmationModal,
  ]);

  const renderBasicBenefiType = useCallback(
    (isVisible) => {
      if (isVisible) {
        switch (basicSubmissionInfoToSubmit.expenseCategory) {
          case ExpenseCategory.OutsideHospital:
            return (
              <OutsideHospital
                previousStep={handleBackButton}
                nextStep={props.nextStep}
              />
            );
          case ExpenseCategory.Allowance:
            return (
              <Allowance
                previousStep={handleBackButton}
                nextStep={props.nextStep}
              />
            );
          case ExpenseCategory.OutOfHospitalMedicalProcedures:
            return (
              <OutOfHospitalMedicalProcedures
                previousStep={handleBackButton}
                nextStep={props.nextStep}
              />
            );
          default:
            return null;
        }
      }
      return null;
    },
    [
      basicSubmissionInfoToSubmit?.expenseCategory,
      handleBackButton,
      props.nextStep,
    ],
  );

  useEffect(() => {
    if (basicSubmissionInfoToSubmit)
      claimsStore.loadDiagnosticCenters(
        basicSubmissionInfoToSubmit.contract,
        moment(basicSubmissionInfoToSubmit.eventDate).format("YYYY-MM-DD"),
      );
  }, [basicSubmissionInfoToSubmit, claimsStore]);

  return (
    <>
      <ConfirmationModal
        showConfirmationModal={showBackConfirmationModal}
        handleConfirm={() => {
          setShowBackConfirmationModal(false);
          props.previousStep();
        }}
        handleCancel={() => {
          setShowBackConfirmationModal(false);
        }}
        message={t("claim.step2.confirmation-msg")}
      />
      <div className={`${props.isVisible ? "" : "doNotDisplay"}`}>
        {renderBasicBenefiType(props.isVisible)}
      </div>
    </>
  );
};

export default observer(Step2);
