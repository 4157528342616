import React, { useState } from "react";
import { Table } from "@nn-design-system/react-component-library";
import { ClaimsDetailPanelTable } from "./ClaimsDetailPanelTable";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";
import { useTranslation } from "react-i18next";
export const ClaimsTable = ({ element, contract }) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const columns = [
    {
      field: "PayrollCode",
      headerName: t("hr.employee"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
    },
    {
      field: "MainInsuredName",
      headerName: t("hr.employee-name"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1.4,
      sortable: false,
    },
    {
      field: "ClaimNo",
      headerName: t("claim.table.header.compensation-number"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "InsuredName",
      headerName: t("hr.claims-concerns-insurance"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1.4,
      sortable: false,
    },
    {
      field: "ClaimEventDate",
      headerName: t("claim.table.header.event-date"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return setDateFormatForDateTime(props.value);
      },
    },
  ];
  const rows = element?.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      testId="payments-hr-table"
      mt="30px"
      hasPagination
      hasFooterPagination
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hasAutoHeight
      itemsPerPageProps={{
        itemsPerPage: [
          {
            text: "5",
            value: 5,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "25",
            value: 25,
          },
          {
            text: "50",
            value: 50,
          },
        ],
        name: "itemsPerPage",
        onChange: (test) => {
          setItemsPerPage(test);
        },
        value: itemsPerPage,
      }}
      page={pageNumber}
      pageNavigationProps={{
        name: "pageNavigation",
        onChange: (value) => {
          setPageNumber(value);
        },
        value: pageNumber,
      }}
      pageSize={itemsPerPage}
      getDetailPanelContent={(rowData) => {
        return (
          <div className="payments-hr-table">
            <ClaimsDetailPanelTable
              contract={contract}
              element={rowData.row.MedicalActions}
            />
          </div>
        );
      }}
      getDetailPanelHeight={() => {
        return "auto";
      }}
    ></Table>
  );
};
