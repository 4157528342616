import React, { useCallback, useMemo, useState, useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import {
  Autocomplete as MuiAutocomplete,
  InputAdornment as MuiInputAdornment,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
} from "@mui/material";
import { useDesignTokens } from "@nn-design-system/react-component-library";
import { getColor, getSelect } from "@nn-design-system/design-tokens/web";
import { Icon, Label, InlineMessage } from "@nn-design-system/react-component-library";
import "../../Css/Commons/autocomplete.css";

export const Autocomplete = ({
  id = `id-${uuidV4()}`,
  options: initialOptions,
  variant = "Select",
  testId = "Autocomplete",
  ariaLabelText,
  onChange,
  onBlur,
  onFocus,
  // Default value to make the component controlled
  value = "",
  dataState: initialDataState,
  ariaLabelElementId,
  labelText = "",
  validationText,
  subtext = "",
  popoverElement,
  isLoading,
  loadingText,
  noOptionsText,
  placeholderText,
}) => {
  const tokens = useDesignTokens(getSelect);

  const colorTokens = getColor();

  const [internalValue, setInternalValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const dataState = useMemo(() => {
    if (validationText) return tokens.constants.dataStates.DATA_STATE_INVALID;
    return initialDataState;
  }, [validationText, initialDataState]);

  const popupIcon = useMemo(() => <Icon name="ChevronDown" color={colorTokens.form.select.icon.value} />, []);
  const clearIcon = useMemo(() => <Icon name="Cross" color={colorTokens.form.select.icon.value} />, []);
  const options = useMemo(() => initialOptions.map((option) => option.value), [initialOptions]);
  const isSelectVariant = useMemo(() => variant === "Select", [variant]);
  const InputProps = useMemo(
    () => ({
      startAdornment: (
        <MuiInputAdornment position="start">
          <Icon name="Magnifier" color={colorTokens.form.input.icon.value} />
        </MuiInputAdornment>
      ),
    }),
    [colorTokens]
  );

  const extraProps = useMemo(() => (isSelectVariant ? {} : { InputProps }), [InputProps, isSelectVariant]);
  const internalOnFocus = useCallback((event) => {
    if (onFocus) {
      onFocus(event);
    }
  }, []);

  const internalOnBlur = useCallback((event) => {
    if (onBlur) {
      onBlur(event);
    }
  }, []);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const setLabelNames = useCallback(
    (option) => {
      return initialOptions.find((opt) => opt.value == option)?.text ?? "";
    },
    [initialOptions]
  );

  const internalOnChange = useCallback(
    (_event, text) => {
      setInternalValue(text);
      let internalValue = initialOptions.find((el) => el.text === text);

      onChange(text, internalValue?.value);
    },
    [initialOptions]
  );

  const renderOption = useCallback(
    (props, option, { selected }) => {
      return (
        <li {...props} className="">
          <MuiMenuItem
            className={selected ? "Mui-selected" : ""}
            key={option}
            value={option}
            disableRipple={true}
            component="button"
            role={"option"}
            data-testid={`${testId}-item-${option}`}
          >
            {setLabelNames(option)}
          </MuiMenuItem>
        </li>
      );
    },
    [initialOptions]
  );
  const renderInput = useCallback(
    (params) => (
      <div>
        {labelText ? (
          <Label
            text={labelText}
            subtext={subtext}
            onClick={() => (dataState !== "Disabled" ? setIsOpen(!isOpen) : null)}
            id={id}
            testId={`${testId}-label`}
            variant={tokens.elements.Label.components.Label.variant}
            popoverElement={popoverElement}
          />
        ) : null}
        <div className="autcomplete-input">
          <MuiTextField
            data-testid={`${testId}-input`}
            {...params}
            ref={params.InputProps.ref}
            inputProps={{
              ...params.inputProps,
              "aria-label": ariaLabelText,
              "aria-labelledby": ariaLabelElementId,
            }}
            {...extraProps}
            error={dataState === tokens.constants.dataStates.DATA_STATE_INVALID}
            placeholder={placeholderText}
          />
        </div>
        {validationText && (
          <div>
            <InlineMessage text={validationText} />
          </div>
        )}
      </div>
    ),
    [validationText, labelText, value, dataState]
  );

  const isOptionEqualToValue = useCallback((o, v) => {
    return o == v;
  }, []);

  return (
    <div className="autocomplete-container">
      <MuiAutocomplete
        outputValue
        isOptionEqualToValue={isOptionEqualToValue}
        inputValue={internalValue}
        open={isOpen}
        onBlur={internalOnBlur}
        onFocus={internalOnFocus}
        onInputChange={internalOnChange}
        disabled={dataState === "Disabled"}
        onOpen={onOpen}
        onClose={onClose}
        forcePopupIcon={isSelectVariant}
        popupIcon={popupIcon}
        clearIcon={clearIcon}
        renderOption={renderOption}
        options={options}
        loading={isLoading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        getOptionLabel={(option) => setLabelNames(option)}
        renderInput={renderInput}
      />
    </div>
  );
};
