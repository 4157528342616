import React from "react";
import { Image } from "@nn-design-system/react-component-library";
import img from "../../assets/index";

const RegisterPromo = () => {
  return (
    <div>
      <Image
        alternativeText="Test imagen default"
        source={img.myNN_login_photo}
        testId="imagen test default"
        variant="Landscape"
      />
    </div>
  );
};

export default RegisterPromo;
