import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Label,
  Select,
  AmountInput,
  TextInput,
  DatepickerInput,
  Popover,
  Paragraph,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ClaimsStore from "../../../Stores/ClaimsStore";
import {
  DiagnosticExaminationTypes,
  ExpenseType,
} from "../../../Consts/Claims";
import FilesUpload from "./FilesUpload";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import moment from "moment";

const DiagnosticExamination = (props) => {
  const { t } = useTranslation();

  const invalidValueText = t("claim.submission.invalid-value");
  const hasNoExaminationLocationOptionsText = t("claim.no-values-available");
  const [examinationLocation, setExaminationLocation] = useState("");
  const [examinationLocationError, setExaminationLocationError] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [prefectureError, setPrefectureError] = useState("");
  const [prefectureState, setPrefectureState] = useState("Disabled");
  const [center, setCenter] = useState("");
  const [centerError, setCenterError] = useState("");
  const [centerState, setCenterState] = useState("Disabled");
  const [receiptDate, setReceiptDate] = useState("");
  const [receiptDateError, setReceiptDateError] = useState("");
  const [receiptAmount, setReceiptAmount] = useState("");
  const [receiptAmountError, setReceiptAmountError] = useState("");
  const [otherInsurerContributionAmount, setOtherInsurerContributionAmount] =
    useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [receiptNumberError, setReceiptNumberError] = useState("");

  const [filesTypes, setFilesTypes] = useState([]);

  const [files, setFiles] = useState([]);
  const [showFileValidationError, setShowFileValidationError] = useState(false);

  const claimsStore = useContext(ClaimsStore);
  const {
    basicSubmissionInfoToSubmit,
    expenseTypes,
    prefectures,
    diagnosticCenters,
    documentTypes,
  } = claimsStore;

  const currentDateValue = useMemo(() => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
  }, []);
  const eventDateValue = useMemo(() => {
    let eventDate = new Date(basicSubmissionInfoToSubmit.eventDate);
    eventDate.setHours(0, 0, 0, 0);
    return eventDate;
  }, [basicSubmissionInfoToSubmit.eventDate]);

  const validateExaminationLocation = useCallback(
    (value) => {
      if (value !== "") {
        setExaminationLocationError("");
        return true;
      }
      setExaminationLocationError(invalidValueText);
      return false;
    },
    [setExaminationLocationError],
  );

  const validatePrefecture = useCallback(
    (value) => {
      if (value !== "" || prefectureState) {
        setPrefectureError("");
        return true;
      }
      setPrefectureError(invalidValueText);
      return false;
    },
    [prefectureState, setPrefectureError],
  );

  const validateCenter = useCallback(
    (value) => {
      if (value !== "" || (prefectureState && centerState)) {
        setCenterError("");
        return true;
      }
      setCenterError(invalidValueText);
      return false;
    },
    [prefectureState, centerState, setCenterError],
  );

  const validateReceiptDate = useCallback(
    (value) => {
      if (
        value &&
        value.toString() !== "" &&
        value.toString() !== "Invalid Date"
      ) {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);
        if (
          dateValue.getTime() - currentDateValue.getTime() > 0 ||
          dateValue.getTime() - eventDateValue.getTime() < 0
        ) {
          setReceiptDateError(invalidValueText);
          return false;
        }
        const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(dateValue);
        const eventDateRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (eventDateRegex.test(dateValueStringFormatted)) {
          setReceiptDateError("");
          return true;
        }
        setReceiptDateError(invalidValueText);
        return false;
      }
      setReceiptDateError(invalidValueText);
      return false;
    },
    [currentDateValue, eventDateValue, setReceiptDateError],
  );

  const handleEventDateChange = useCallback(
    (value) => {
      if (validateReceiptDate(value)) {
        setReceiptDate(value);
      } else {
        setReceiptDate("");
      }
    },
    [validateReceiptDate],
  );

  const validateReceiptAmount = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptAmountError("");
        return true;
      }
      setReceiptAmountError(invalidValueText);
      return false;
    },
    [setReceiptAmountError],
  );

  const validateReceiptNumber = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptNumberError("");
        return true;
      }
      setReceiptNumberError(invalidValueText);
      return false;
    },
    [setReceiptNumberError],
  );

  const validateFiles = useCallback(
    (filesToCheck) => {
      let isValid = true;
      filesTypes.forEach((fileType) => {
        if (
          fileType.isMandatory &&
          (filesToCheck.length === 0 ||
            !filesToCheck?.find((file) => file.fileType === fileType.value))
        ) {
          isValid = false;
        }
      });
      setShowFileValidationError(!isValid);
      return isValid;
    },
    [filesTypes, setShowFileValidationError],
  );

  const validateDiagnosticExaminationInfo = useCallback(() => {
    const isExaminationLocation =
      validateExaminationLocation(examinationLocation);
    const isPrefecture = validatePrefecture(prefecture);
    const isCenter = validateCenter(center);
    const isReceiptDate = validateReceiptDate(receiptDate);
    const isReceiptAmount = validateReceiptAmount(receiptAmount);
    const isReceiptNumber = validateReceiptNumber(receiptNumber);
    const isFilesValid = validateFiles(files);

    return (
      isExaminationLocation &&
      isPrefecture &&
      isCenter &&
      isReceiptDate &&
      isReceiptAmount &&
      isReceiptNumber &&
      isFilesValid
    );
  }, [
    validateExaminationLocation,
    validatePrefecture,
    validateCenter,
    validateReceiptDate,
    validateReceiptAmount,
    validateReceiptNumber,
    validateFiles,
    examinationLocation,
    prefecture,
    center,
    receiptDate,
    receiptAmount,
    receiptNumber,
    files,
  ]);

  const createNewDiagnosticExaminationClaim = useCallback(() => {
    claimsStore.addClaimToSubmitingList({
      ExpenseType: ExpenseType.DiagnosticExamination,
      ExaminationLocation: examinationLocation,
      Prefecture: prefecture,
      Center: center,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
      CollaboratingNetworkList: false,
    });
  }, [
    claimsStore,
    examinationLocation,
    prefecture,
    center,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const saveEditedDiagnosticExaminationClaim = useCallback(() => {
    claimsStore.saveEditedClaim({
      Id: props.selectedClaimToEdit?.Id,
      ExpenseType: ExpenseType.DiagnosticExamination,
      ExaminationLocation: examinationLocation,
      Prefecture: prefecture,
      Center: center,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
      CollaboratingNetworkList: false,
    });
  }, [
    claimsStore,
    props.selectedClaimToEdit?.Id,
    examinationLocation,
    prefecture,
    center,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const handleContinueBtn = useCallback(() => {
    if (
      examinationLocationError !== hasNoExaminationLocationOptionsText &&
      validateDiagnosticExaminationInfo()
    ) {
      if (props.selectedClaimToEdit !== null) {
        saveEditedDiagnosticExaminationClaim();
      } else {
        createNewDiagnosticExaminationClaim();
      }
      props.nextStep();
    }
  }, [
    examinationLocationError,
    hasNoExaminationLocationOptionsText,
    validateDiagnosticExaminationInfo,
    createNewDiagnosticExaminationClaim,
    saveEditedDiagnosticExaminationClaim,
    props,
  ]);

  const handleCancelBtn = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      claimsStore.clearSelectedClaimToEdit();
      props.nextStep();
    } else {
      props.previousStep();
    }
  }, [props, claimsStore]);

  const renderReceiptAmount = useCallback(() => {
    if (receiptAmountError === "") {
      return (
        <AmountInput
          labelText={t("claim.amount-payable")}
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
        />
      );
    } else {
      return (
        <AmountInput
          labelText={t("claim.amount-payable")}
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
          validationText={receiptAmountError}
        />
      );
    }
  }, [
    receiptAmount,
    receiptAmountError,
    setReceiptAmount,
    validateReceiptAmount,
  ]);

  const populateStateWithSelectedClaimToEdit = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      setExaminationLocation(props.selectedClaimToEdit.ExaminationLocation);
      setPrefecture(props.selectedClaimToEdit.Prefecture);
      setCenter(props.selectedClaimToEdit.Center);
      if (
        props.selectedClaimToEdit.ExaminationLocation ===
        DiagnosticExaminationTypes.InNetworkDiagnosticExamination
      ) {
        setPrefectureState("");
        setCenterState("");
      }
      setReceiptDate(props.selectedClaimToEdit.ReceiptDate);
      setReceiptAmount(props.selectedClaimToEdit.ReceiptAmount);
      setOtherInsurerContributionAmount(
        props.selectedClaimToEdit.OtherInsurerContributionAmount,
      );
      setReceiptNumber(props.selectedClaimToEdit.ReceiptNumber);
      setFiles(props.selectedClaimToEdit.Files);
    }
  }, [
    props.selectedClaimToEdit,
    setExaminationLocation,
    setPrefecture,
    setCenter,
    setReceiptDate,
    setReceiptAmount,
    setOtherInsurerContributionAmount,
    setReceiptNumber,
    setFiles,
  ]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const handleExemptionLocationChange = useCallback(
    (exemptionLocation) => {
      if (
        exemptionLocation ===
        DiagnosticExaminationTypes.InNetworkDiagnosticExamination
      ) {
        setPrefectureState("");
      } else {
        setPrefectureState("Disabled");
        setCenterState("Disabled");
        setPrefectureError("");
        setCenterError("");
      }

      setPrefecture("");
      setCenter("");
    },
    [setPrefectureState, setPrefectureError, setPrefecture],
  );

  const handlePrefectureChange = useCallback(
    (newPrefecture) => {
      if (newPrefecture) {
        setCenterState("");
      } else {
        setCenterState("Disabled");
        setCenterError("");
      }
      setCenter("");
    },
    [setCenterState, setCenterError, setCenter],
  );

  const examinationLocationOptions = useMemo(() => {
    const options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];
    expenseTypes
      .filter(
        (type) =>
          type.Name ===
            DiagnosticExaminationTypes.InNetworkDiagnosticExamination ||
          type.Name ===
            DiagnosticExaminationTypes.DoctorInNetworkDiagnosticExamination ||
          type.Name ===
            DiagnosticExaminationTypes.OutOfNetworkDiagnosticExamination,
      )
      .forEach((element) => {
        options.push({
          text: element.DisplayName,
          value: element.Name,
        });
      });
    if (options.length <= 1) {
      setExaminationLocationError(hasNoExaminationLocationOptionsText);
    }
    return options;
  }, [expenseTypes, setExaminationLocationError]);

  const prefecturesOptions = useMemo(() => {
    const options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];

    prefectures.forEach((p) => {
      options.push({
        text: p.RegionName,
        value: p.RegionCode,
      });
    });

    return options;
  }, [prefectures]);

  const diagnosticCentersOptions = useMemo(() => {
    const options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];

    diagnosticCenters
      .filter((c) => c.RegionCode === prefecture)
      .forEach((c) => {
        options.push({
          text: c.Name,
          value: c.Code,
        });
      });
    return options;
  }, [diagnosticCenters, prefecture]);

  useEffect(() => {
    populateStateWithSelectedClaimToEdit();
  }, [populateStateWithSelectedClaimToEdit]);

  useEffect(() => {
    const options = [];

    const expenseType = expenseTypes.find(
      (et) => et.Name === ExpenseType.DiagnosticExamination,
    );

    if (expenseType) {
      expenseType.DocumentTypes?.forEach((dt) => {
        const currentDocumentType = documentTypes?.find(
          (sdt) => sdt.Name === dt.Name,
        );
        if (currentDocumentType) {
          options.push({
            text: currentDocumentType.Title,
            value: dt.Name,
            isMandatory: dt.IsRequired,
          });
        }
      });
    }

    setFilesTypes(options);
  }, [documentTypes, expenseTypes, setFilesTypes]);

  //TODO preguntar literal linea 558
  return (
    <div>
      <div className={`${styles.note} ${styles.responsiveFontSize}`}>
        <Label text="Υπενθυμίζουμε ότι δεν καταχωρούνται έξοδα για check up και test pap." />
      </div>
      <div className={styles.formRowContainer} style={{ marginTop: "30px" }}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Select
            labelText={t("claim.exams-held")}
            name="ExaminationLocation"
            options={examinationLocationOptions}
            onChange={(event) => {
              setExaminationLocation(event.target.value);
              validateExaminationLocation(event.target.value);
              handleExemptionLocationChange(event.target.value);
            }}
            value={examinationLocation}
            validationText={examinationLocationError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`} />
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Select
            dataState={prefectureState}
            labelText={t("claim.law")}
            name="Prefecture"
            options={prefecturesOptions}
            onChange={(event) => {
              setPrefecture(event.target.value);
              validatePrefecture(event.target.value);
              handlePrefectureChange(event.target.value);
            }}
            value={prefecture}
            validationText={prefectureError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <Select
            dataState={centerState}
            labelText={t("claim.diagnostic-center")}
            name="Center"
            options={diagnosticCentersOptions}
            onChange={(event) => {
              setCenter(event.target.value);
              validateCenter(event.target.value);
            }}
            value={center}
            validationText={centerError}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <DatepickerInput
            defaultView="Day"
            labelText={t("claim.table.header.receipt-invoice-date")}
            name="ReceiptDate"
            placeholderText="ΗΗ/ΜΜ/ΕΕΕΕ"
            locale="elGR"
            inputFormat="dd/MM/yyyy"
            maxDate={currentDateValue}
            minDate={eventDateValue}
            views={[
              {
                name: "Year",
              },
              {
                name: "Month",
              },
              {
                name: "Day",
              },
            ]}
            onBlur={(event) => {
              let value = new Date(moment(event.target.value, "DD/MM/YYYY"));
              handleEventDateChange(value);
            }}
            onAccept={(value) => {
              handleEventDateChange(value);
            }}
            value={receiptDate}
            validationText={receiptDateError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          {renderReceiptAmount()}
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <AmountInput
            labelText={t("claim.amount-other-insurance")}
            name="otherInsurerContributionAmount"
            popoverElement={
              <Popover
                headingText=" "
                placement="bottom-start"
                testId="popover"
                variant="Icon"
              >
                <div>
                  <Paragraph>{t("claim.examination.other-company")}</Paragraph>
                </div>
              </Popover>
            }
            onChange={(event) => {
              setOtherInsurerContributionAmount(event.target.value);
            }}
            value={otherInsurerContributionAmount}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <TextInput
            labelText={t("claim.medical.number-receipt-invoice")}
            name="ReceiptNumber"
            onChange={(event) => {
              setReceiptNumber(event.target.value);
              validateReceiptNumber(event.target.value);
            }}
            value={receiptNumber}
            validationText={receiptNumberError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`} />
      </div>
      {filesTypes.length > 0 ? (
        <FilesUpload
          filesTypes={filesTypes}
          files={files}
          addFile={addFile}
          removeFile={removeFile}
          showFileValidationError={showFileValidationError}
        />
      ) : (
        <></>
      )}
      <div className={`${styles.buttonsRow}`} style={{ marginTop: "20px" }}>
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleCancelBtn();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div
          className={`${styles.nextStepBtn} ${
            examinationLocationError === hasNoExaminationLocationOptionsText
              ? styles.disabledBtn
              : ""
          }`}
        >
          <Button
            type="Button"
            onClick={() => {
              handleContinueBtn();
            }}
          >
            {t("claim.entry")}
          </Button>
        </div>
      </div>
      <div className={styles.compulsoryNote}>
        <Label text={t("claim.submission.required-fields")} />
      </div>
    </div>
  );
};

export default observer(DiagnosticExamination);
