import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "@nn-design-system/react-component-library";

export const AccountPaymentsOut = ({ data, element }) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "a",
      headerName: "IBAN",
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "b",
      headerName: t("account.account-number"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "c",
      headerName: t("account.bank"),
      headerAlign: "left",
      align: "left",
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
  ];

  const rows = data.map((e, i) => {
    return {
      id: i,
      a: e.Iban,
      b: e.AccountNumber,
      c: e.BankName,
    };
  });

  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
    />
  );
};
