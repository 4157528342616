import React, { useContext, Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Paragraph } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import AccountStore from "../../Stores/AccountStore";
import "../../Css/account-payments.css";
import { getMonthYear } from "../CommonComp/Functions";
import { AccountPaymentsOut } from "./AccountPaymentsOut";
import { AccountPaymentsIn } from "./AccountPaymentsIn";
import { CommonList } from "./CommonList";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";

const AccountPayments = () => {
  const { t } = useTranslation();
  const { accountData } = useContext(AccountStore);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 768) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  const paymentsElemsOut = [
    {
      name: "IBAN",
      value: "Iban",
    },
    {
      name: t("account.account-number"),
      value: "AccountNumber",
    },
    {
      name: t("account.bank"),
      value: "BankName",
    },
  ];
  const paymentsElemsIn = [
    {
      name: t("account.type"),
      value: "ProfileType",
      element: (params) => (
        <Paragraph hasMargin={false}>
          {params.ProfileType === "Direct Debit"
            ? t("account.standing-order")
            : t("account.credit-card")}
        </Paragraph>
      ),
    },
    {
      name: "IBAN",
      value: "AccountNumber",
      element: (element) => (
        <Paragraph hasMargin={false}>
          {element.ProfileType === "Direct Debit" ? element.Iban : ""}
        </Paragraph>
      ),
    },
    {
      name: t("account.account-number"),
      value: "AccountNumber",
    },
    {
      name: t("account.bank"),
      value: "BankName",
    },
    {
      name: t("account.beneficiary"),
      value: "AccountHolder",
    },
    {
      name: t("account.date-expiration"),
      value: "AccountNumber",
      element: (element) => (
        <span>
          {element.ExpirationDate ? getMonthYear(element.ExpirationDate) : "-"}
        </span>
      ),
    },
  ];

  return accountData.PaymentProfiles ? (
    <Fragment>
      <div className="account-table-container">
        <div className="compensation">
          <label>{t("account.compensation-payment-account")}</label>

          {isSmallSize ? (
            accountData.PaymentProfiles.filter(
              (element) => element.ProfileType === "Payment Out",
            ).map((elem, i) => {
              return (
                <CommonList key={i} data={paymentsElemsOut} element={elem} />
              );
            })
          ) : (
            <AccountPaymentsOut
              data={accountData.PaymentProfiles.filter(
                (element) => element.ProfileType === "Payment Out",
              )}
            />
          )}
        </div>
      </div>

      <div className="account-table-container">
        <div className="compensation">
          <label>{t("account.premium-account")}</label>

          {isSmallSize ? (
            accountData.PaymentProfiles.filter(
              (element) => element.ProfileType !== "Payment Out",
            ).map((elem, i) => {
              return (
                <CommonList key={i} data={paymentsElemsIn} element={elem} />
              );
            })
          ) : (
            <AccountPaymentsIn
              data={accountData.PaymentProfiles.filter(
                (element) => element.ProfileType !== "Payment Out",
              )}
            />
          )}
        </div>
      </div>
    </Fragment>
  ) : null;
};
export default observer(AccountPayments);
