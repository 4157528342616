import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";

const OnlinePaymentForm = () => {
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const { transactionResult } = onlinePaymentStore;

  useEffect(() => {
    if (transactionResult) {
      document.getElementById("myForm").submit();
    }

    return () => {
      onlinePaymentStore.clearTransaction();
    };
  }, [transactionResult]);

  return (
    <>
      {transactionResult ? (
        <form
          id="myForm"
          target="my_iframe"
          action="https://paycenter.piraeusbank.gr/redirection/pay.aspx"
          method="POST"
        >
          <input
            name="AcquirerId"
            type="hidden"
            value={transactionResult.AcquiredId}
          />
          <input
            name="MerchantId"
            type="hidden"
            value={transactionResult.MerchantId}
          />
          <input name="PosId" type="hidden" value={transactionResult.PosId} />
          <input name="User" type="hidden" value={transactionResult.User} />
          <input
            name="LanguageCode"
            type="hidden"
            value={transactionResult.LanguageCode}
          />
          <input
            name="MerchantReference"
            type="hidden"
            value={transactionResult.MerchantReference}
          />
          <input
            name="ParamBackLink"
            type="hidden"
            value={transactionResult.ParamBackLink}
          />
        </form>
      ) : null}
    </>
  );
};

export default observer(OnlinePaymentForm);
