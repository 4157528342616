import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Hero as NNHero } from "@nn-design-system/react-component-library";
import UrlStore from "../../Stores/UrlStore";
import ArticleStore from "../../Stores/ArticleStore";

const CarouselItem = (props) => {
  const urlStore = useContext(UrlStore);
  const { articleList } = useContext(ArticleStore);
  const { baseURL } = urlStore;

  const navigate = useNavigate();

  const checkUrl = useCallback((url) => {
    const clickToDetails = (detail, elem) => {
      navigate(`/knowledgepoint/${detail}`, { state: toJS(elem) });
    };

    const clickToMainComponents = (detail) => {
      navigate(`${detail}`);
    };

    const article = articleList.find((a) => a.NodeAliasPath === url);

    if (article) {
      clickToDetails("details", article);
    } else {
      clickToMainComponents(props.promo.Url);
    }
  }, [articleList, navigate, props.promo.Url]);

  const determineUrl = useCallback(() => {
    if (props.promo.LinkType === 1) { checkUrl(props.promo.Url) }
    else {
      window.open(props.promo.Url ?? "/home", props.promo.LinkTarget ?? '_blank', 'noreferrer');
    }
  }, [checkUrl, props.promo]);

  return (
    <>
      {articleList ? (
        <NNHero
          imageProps={{
            source: `${baseURL}${props.promo.Image.Path}`,
          }}
          primaryActionProps={{
            onClick: () => determineUrl(),
            text: props.promo.ButtonText,
          }}
          testId="heroTest"
          variant="Large"
        >
          <span dangerouslySetInnerHTML={{ __html: props.promo.Title }} />
          <span>{props.promo.Description}</span>
        </NNHero>
      ) : null}
    </>
  );
};

export default observer(CarouselItem);
