import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ContractSelectedDocumentTable from "./ContractSelectedDocumentTable";
import {
  ConvertDecimalToString,
  ConvertDecimalToStringWithCurrency,
} from "../CommonComp/Functions";
import { observer } from "mobx-react-lite";

const PensionAccountContent = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="selected-contract-account-pension">
        <div className="account-value">
          {props.contractDetails.AccountValue ? (
            <div>
              <p>{t("contract.value-freedom")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.AccountValue,
                  props.contractDetails.CurrencyCode,
                )}
              </span>
            </div>
          ) : null}
          <div>
            <p>{t("contract.takeover-value")}</p>
            <span>
              {ConvertDecimalToStringWithCurrency(
                props.contractDetails.SurrenderValue,
                props.contractDetails.CurrencyCode,
              )}
            </span>
            <span className="takeover-value">
              {t("contract.redemption-penalties-msg")}
            </span>
          </div>
          {props.contract.IsNonExpiredAmount ? null : (
            <div>
              {props.contract.IsGuaranteeExpiredAmount ? (
                <>
                  <p>{t("contract.guaranteed-maturity-capital")}</p>
                  <span>
                    {ConvertDecimalToStringWithCurrency(
                      props.contractDetails.ExpiredAmount,
                      props.contractDetails.CurrencyCode,
                    )}
                  </span>
                </>
              ) : (
                <p>
                  <p>{t("contract.closing-capital")}</p>
                  <span>
                    {ConvertDecimalToStringWithCurrency(
                      props.contractDetails.ExpiredAmount,
                      props.contractDetails.CurrencyCode,
                    )}
                  </span>
                </p>
              )}
            </div>
          )}
        </div>
        <div className="account-mortgage">
          <div>
            <p>{t("contract.loan-right")}</p>
            <span>
              {ConvertDecimalToStringWithCurrency(
                props.contractDetails.LoanRight,
                props.contractDetails.CurrencyCode,
              )}
            </span>
          </div>
          {props.contractDetails.LoanAmount ? (
            <div>
              <p>{t("contract.loan-amount")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.LoanAmount,
                  props.contractDetails.CurrencyCode,
                )}{" "}
              </span>
            </div>
          ) : null}
          {props.contractDetails.LoanToRepay ? (
            <div>
              <p>{t("contract.loan-repaid")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.LoanToRepay,
                  props.contractDetails.CurrencyCode,
                )}
              </span>
            </div>
          ) : null}
          <div>
            <p>{t("contract.dividend")}</p>
            <span>
              {ConvertDecimalToStringWithCurrency(
                props.contractDetails.Dividend,
                props.contractDetails.CurrencyCode,
              )}
            </span>
          </div>
        </div>
        <ContractSelectedDocumentTable
          Transactions={props.contractDetails.Transactions}
        />
      </div>
    </Fragment>
  );
};

export default observer(PensionAccountContent);
