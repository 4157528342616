import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  setDateFormatForDateTime,
  removeZeroFromString,
} from "../CommonComp/Functions";
import PersonDataCard from "./PersonDataCard";
import "../../Css/Contracts_Details/selected-contract-details.css";
import {
  Amount,
  Heading,
  Label,
  Paragraph,
} from "@nn-design-system/react-component-library";
import PaymentStore from "../../Stores/PaymentStore";
import { observer } from "mobx-react-lite";

const ContractSelectedDetails = (props) => {
  const { t } = useTranslation();
  const paymentStore = useContext(PaymentStore);
  const { paymentList } = paymentStore;
  const [paymentFrequency, setPaymentFrequency] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    if (
      props !== undefined &&
      props.contractDetails.PaymentFrequency !== "" &&
      props.contractDetails.PaymentMethod !== ""
    ) {
      setPaymentFrequency(props.contractDetails.PaymentFrequency);
      setPaymentMethod(props.contractDetails.PaymentMethod);
    } else if (paymentList) {
      getPaymentFromCon(paymentList);
    }
  }, [paymentList, props]);

  const getPaymentFromCon = (list) => {
    if (list) {
      list.map((contractElement) => {
        if (
          contractElement.DisplayNumber === props.contractDetails.DisplayNumber
        ) {
          setPaymentFrequency(contractElement.PaymentFrequency);
          setPaymentMethod(contractElement.PaymentMethod);
        }
      });
    }
  };

  return (
    <Fragment>
      <div className="selected-contract-details">
        <div className="insurance-details">
          <Label text="Στοιχεία Ασφαλιστηρίου" />
          <div className="insurance-details-column">
            <div>
              <p>{t("contract.insurance-policy-issue-date")}</p>
              <span>
                {setDateFormatForDateTime(props.contractDetails.IssueDate)}
              </span>
            </div>
            {props.contractDetails.ProductCategoryId !== "H" ? (
              <>
                <div>
                  <p>{t("contract.insurance-policy-expiry-date")}</p>
                  <span>
                    {setDateFormatForDateTime(
                      props.contractDetails.ExpiredDate,
                    )}
                  </span>
                </div>
              </>
            ) : null}
            {(!props.contractDetails.IsNn2 &&
              props.contractDetails.ProductCategoryId !== "H") ||
            (props.contractDetails.IsNn2 &&
              props.contractDetails.ProductCategoryId == "H") ? (
              <>
                <div>
                  <p>{t("contract.annual-adjustment")}</p>
                  <span>
                    {props.contractDetails.Indexation
                      ? removeZeroFromString(props.contractDetails.Indexation)
                      : "-"}
                  </span>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="payments-details">
          <Label text="Στοιχεία Πληρωμής" />
          <div>
            <p>{t("payments.frequency-payment")}</p>
            <span>{paymentFrequency ? paymentFrequency : "-"}</span>
          </div>
          <div>
            <p>{t("payments.means-payment")}</p>
            <span>{paymentMethod ? paymentMethod : "-"}</span>
          </div>
          <div>
            <p>{t("contract.annual-premium")}</p>
            <span>
              <Amount
                currencyCode="EUR"
                hasAlwaysDecimals
                value={props.contractDetails.AnnualAmount}
                variant="Small"
              />
              {/* {props.contractDetails.AnnualAmount ? ConvertDecimalToString(props.contractDetails.AnnualAmount) : "-"} */}
            </span>
          </div>
          <div>
            <div id="heading-nn">
              <Heading hasMargin={false} variant="Small">
                {t("payments.due-date")}
              </Heading>
              <Paragraph hasMargin={true}>
                {setDateFormatForDateTime(
                  props.contractDetails.NextBillingDate,
                )}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className="insured-beneficiaries">
          <div className="insured">
            <Label mt="20px" text="Ασφαλισμένοι" />
            {props.contractDetails.Insureds ? (
              props.contractDetails.Insureds.length ? (
                props.contractDetails.Insureds.map((element, i) => {
                  return (
                    <PersonDataCard
                      key={i}
                      element={element}
                      number={i}
                      insType={"insured-beneficiaries"}
                    />
                  );
                })
              ) : (
                <span>{"-"}</span>
              )
            ) : (
              <span>{"-"}</span>
            )}
          </div>
          <div className="beneficiaries">
            <Label text={t("contract.beneficiaries")} />
            {props.contractDetails.Beneficiaries ? (
              props.contractDetails.Beneficiaries.length ? (
                props.contractDetails.Beneficiaries.map((element, i) => {
                  return (
                    <PersonDataCard
                      key={i}
                      element={element}
                      number={i}
                      insType={"beneficiaries"}
                    />
                  );
                })
              ) : (
                <span>{"-"}</span>
              )
            ) : (
              <span>{"-"}</span>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default observer(ContractSelectedDetails);
