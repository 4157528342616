import React, { useContext, useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  DateRangePickerInput,
  Select,
  IconButton,
  Button,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import HRStore from "../../../Stores/HRStore";
import { usePaymentsHRFilter } from "./hooks/usePaymentsHRFilter";
import {
  allCompanyNameOptionSelect,
  allStatusOptionSelect,
} from "../Utils/Constants";
import { useWindowDimensions } from "../../../Hooks/useWindowDimensions";
const PaymentsTableFilterHR = ({ filterFn }) => {
  const { t } = useTranslation();
  const { allPaymentsHR, paymentsHR } = useContext(HRStore);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const {
    statusSelect,
    companySelect,
    datePickerRange,
    onInputChange,
    resetFilter,
  } = usePaymentsHRFilter();
  const { width } = useWindowDimensions();

  const isSmallView = useMemo(() => {
    return width <= 767.98;
  }, [width]);

  useEffect(() => {
    filterFn(statusSelect, companySelect, datePickerRange);
  }, [statusSelect, companySelect, datePickerRange]);

  useEffect(() => {
    if (allPaymentsHR) {
      let companyNameOptions = allPaymentsHR?.map(({ CompanyName }) => ({
        text: CompanyName,
        value: CompanyName,
      }));
      setCompanyOptions([...allCompanyNameOptionSelect, ...companyNameOptions]);
    }
  }, [allPaymentsHR]);

  useEffect(() => {
    if (paymentsHR) {
      let allStatusOptions = paymentsHR.flatMap((el) =>
        el.Invoices.map((invoice) => invoice.StatusDescription),
      );
      let uniqueStatusOptions = [...new Set(allStatusOptions)].map((el) => ({
        text: el,
        value: el,
      }));
      setStatusOptions([...allStatusOptionSelect, ...uniqueStatusOptions]);
    }
  }, [paymentsHR]);

  return (
    <section className="payments-hr-filter-container">
      <DateRangePickerInput
        canSelectFutureDates={false}
        endDateInputProps={{
          labelText: t("hr.period-to"),
          placeholderText: "HH/MM/EEEE",
        }}
        id="dateRangePicker"
        onChange={(ev) => onInputChange(ev, "datePickerRange")}
        startDateInputProps={{
          labelText: t("hr.period-from"),
          placeholderText: "HH/MM/EEEE",
        }}
        testId="date-picker"
        value={datePickerRange}
      />
      <div className="payments-hr-filter-container payments-select-hr">
        <Select
          labelText={t("payments.status-payment")}
          subtext=""
          name="statusSelect"
          inputProps={!isSmallView ? { width: "220px" } : {}}
          onChange={onInputChange}
          placeholderText={`- ${t("global.choose")}`}
          options={statusOptions}
          testId="select"
          value={statusSelect}
        />

        <Select
          labelText={t("hr.receiver")}
          name="companySelect"
          placeholderText={`- ${t("global.choose")}`}
          onChange={onInputChange}
          inputProps={!isSmallView ? { width: "220px" } : {}}
          options={companyOptions}
          testId="select"
          value={companySelect}
          mb="30px"
        />

        {isSmallView ? (
          <Button
            mb="10px"
            testId="button"
            variant={"PrimaryLarge"}
            onClick={resetFilter}
          >
            {t("hr.clear-filters")}
          </Button>
        ) : (
          <IconButton
            onClick={resetFilter}
            mt="25px"
            iconName="Cross"
            testId="icon-button-test-id"
            variant="PlainMedium"
          />
        )}
      </div>
    </section>
  );
};

export default observer(PaymentsTableFilterHR);
