import React, { Fragment, useContext, useEffect } from "react";
import PaymentsTable from "./PaymentsTable";
import PageTheme from "../PageTheme";
import LoaderStore from "../../Stores/LoaderStore";
import ContractStore from "../../Stores/ContractStore";
import PaymentStore from "../../Stores/PaymentStore";
import AccountStore from "../../Stores/AccountStore";
import CookieStore from "../../Stores/CookieStore";

const MyPayments = () => {
  const loaderStore = useContext(LoaderStore);
  const { contractsHasContent, hasOnlyGroupPolicies } = useContext(ContractStore);
  const { paymentHasContent } = useContext(PaymentStore);
  const { accountHasContent } = useContext(AccountStore);
  const { cookieHasContent } = useContext(CookieStore);

  useEffect(() => {
    if (contractsHasContent && paymentHasContent && accountHasContent && cookieHasContent) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
  }, [contractsHasContent, paymentHasContent, accountHasContent, cookieHasContent, loaderStore]);

  return (
    <Fragment>
      <PageTheme />
      {!hasOnlyGroupPolicies &&
        <PaymentsTable />
      }
    </Fragment>
  );
};
export default MyPayments;
