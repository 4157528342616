import { Logo } from "@nn-design-system/react-component-library";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";

const LogoLogin = () => {
  const { width } = useWindowDimensions();
  const [size, setSize] = useState();

  useEffect(() => {
    if (width > 580) return setSize("120px");
    setSize("70px");
  }, [width]);

  return (
    <>
      <Link to="/home" className="logo item">
        <Logo ml={"-30px"} customSize={size} name="NNGroup" />
      </Link>
      <span className="myNN item">myNN</span>{" "}
    </>
  );
};

export default LogoLogin;
