import React, { useMemo } from "react";

import Form from "./SurveyForm";
import { FormContextProvider } from "./context";
import "../../Css/Survey/survey.css";

const Survey = ({ surveyContents }) => {
  const {
    encUnregisteredDownloadModel: {
      ContractNumber,
      CustomerID,
      NotificationTypeDescription,
    } = {},
    survey,
  } = surveyContents ?? {};

  const formValidationRules = useMemo(
    () =>
      survey.Sections.reduce((fields, { Blocks }) => {
        const isRadiogroupGrid = Blocks.every(({ FormItems }) => {
          return FormItems.length === 1 && FormItems[0].Type === "radiogroup";
        });

        Blocks.forEach(({ QuestionId }) => {
          fields[QuestionId] = {
            isTextRequired: isRadiogroupGrid,
          };
        });

        return { ...fields };
      }, {}),
    [survey.Sections],
  );

  return (
    <FormContextProvider
      contractNumber={ContractNumber}
      customerID={CustomerID}
      validation={formValidationRules}
      notificationTypeDescription={NotificationTypeDescription}
    >
      <Form survey={survey} />
    </FormContextProvider>
  );
};

export default Survey;
