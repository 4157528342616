import React from "react";
import { toJS } from "mobx";
import { Table, Icon } from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setDateFormatForDateTime } from "../CommonComp/Functions";

export const ContractTableNN = ({ data, checkAccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clickToDetails = (detail) => {
    const isRestrictedAccess = checkAccess(detail.Number);

    if (isRestrictedAccess) {
      return;
    }
    navigate("/myContracts/details", { state: toJS(detail) });
  };
  const columns = [
    {
      field: "a",
      headerName: "",
      flex: 0.3,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <div onClick={() => clickToDetails(params.value)}>
            <Icon name="ChevronRight" variant="Medium" color="#EA650D" />
          </div>
        );
      },
    },
    {
      field: "b",
      headerName: t("contracts.product-name"),
      headerAlign: "left",
      align: "left",
      flex: 1.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <span onClick={() => clickToDetails(params.value.element)}>
            {" "}
            <b>{params.value.ProductName}</b>
            <br />
            {params.value.Number}
          </span>
        );
      },
    },
    {
      field: "c",
      headerName: t("payments.due-date"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span onClick={() => clickToDetails(params.value.element)}>
            {params.value.IsGroupPolicy || params.value.IsGroupPensionPolicy ? (
              "-"
            ) : (
              <>
                <b>{setDateFormatForDateTime(params.value.NextBillingDate)}</b>
                <br />
                {params.value.PaymentFrequency}
              </>
            )}
          </span>
        );
      },
    },
    {
      field: "d",
      headerName: t("contracts.expiration-date"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span onClick={() => clickToDetails(params.value.element)}>
            <b>
              {params.value.IsGroupPensionPolicy
                ? "-"
                : setDateFormatForDateTime(params.value.ExpiredDate)}
            </b>
          </span>
        );
      },
    },
    {
      field: "e",
      headerName: t("global.condition"),
      headerAlign: "left",
      align: "left",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.value.StatusId === "IF" ? (
              <span
                onClick={() => clickToDetails(params.value.element)}
                className="normal"
              >
                {params.value.Status}
              </span>
            ) : (
              <span
                onClick={() => clickToDetails(params.value.element)}
                className="light-gray"
              >
                {params.value.Status ?? "-"}
              </span>
            )}
          </>
        );
      },
    },
  ];

  const rows = data.map((element, i) => {
    return {
      id: i,
      a: element,
      b: {
        ProductName: element.ProductName,
        Number: element.DisplayNumber,
        element,
      },
      c: {
        NextBillingDate: element.NextBillingDate,
        PaymentFrequency: element.PaymentFrequency,
        IsGroupPolicy: element.IsGroupPolicy,
        IsGroupPensionPolicy: element.IsGroupPensionPolicy,
        element,
      },
      d: {
        ExpiredDate: element.ExpiredDate,
        IsGroupPensionPolicy: element.IsGroupPensionPolicy,
        element,
      },
      e: { StatusId: element.StatusId, Status: element.Status, element },
    };
  });

  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
    />
  );
};
