import React, { useState } from "react";
import {
  Label,
  Paragraph,
  Icon,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import {
  ConvertDecimalToString,
  ConvertDecimalToStringWithOutCurrency,
  stringToAmount,
} from "../CommonComp/Functions";
import GroupPensionPriceHistoryModal from "./GroupPensionPriceHistoryModal";
import styles from "../../Css/GroupPension/GroupPension.module.scss";

const GroupPensionAnalysisList = (props) => {
  const { t } = useTranslation();
  const [showPriceHistoryModal, setShowPriceHistoryModal] = useState(false);
  const [modalData, setModalData] = useState();

  const clickBarChartIcon = (value) => {
    setModalData(value);
    setShowPriceHistoryModal(true);
  };

  return props.tableData?.map((elem, i) => {
    const isTotals =
      !elem.InvestmentType && elem.TotalShares === -1 && elem.SharePrice === -1;

    return (
      <>
        <GroupPensionPriceHistoryModal
          isOpen={showPriceHistoryModal}
          data={modalData}
          onClose={() => {
            setShowPriceHistoryModal(false);
          }}
        />
        <div key={i}>
          <div className={styles.listContainer}>
            {!isTotals && (
              <>
                <div>
                  <Label
                    text={t("contract.investment-type")}
                    hasMargin={false}
                  />
                  <Paragraph hasMargin={false}>{elem.InvestmentType}</Paragraph>
                </div>

                <div>
                  <Label text={t("contract.total-shares")} hasMargin={false} />
                  <Paragraph hasMargin={false}>
                    {ConvertDecimalToStringWithOutCurrency(
                      elem.TotalShares,
                      "el-GR",
                      4,
                    )}
                  </Paragraph>
                </div>

                <div>
                  <Label text={t("contract.shares-price")} hasMargin={false} />
                  <Paragraph hasMargin={false}>
                    {elem.IsGroup ? "-" : stringToAmount(elem.SharePrice, 4)}
                  </Paragraph>
                </div>
              </>
            )}

            <div>
              <Label
                text={t("contract.total-account-value")}
                hasMargin={false}
              />
              <Paragraph hasMargin={false}>
                {ConvertDecimalToString(elem.TotalAmount)}
              </Paragraph>
            </div>
            <div>
              <Label
                text={t("contract.employer-account-department")}
                hasMargin={false}
              />
              <Paragraph hasMargin={false}>
                {ConvertDecimalToString(elem.EmployerContribution)}
              </Paragraph>
            </div>
            <div>
              <Label
                text={t("contract.voluntary-account-department")}
                hasMargin={false}
              />
              <Paragraph hasMargin={false}>
                {ConvertDecimalToString(elem.VoluntaryContribution)}
              </Paragraph>
            </div>
            {!elem.IsGroup && !isTotals && (
              <div>
                <Label text={t("contract.price-history")} />
                <button
                  className={styles.noOutline}
                  style={{
                    backgroundColor: "unset",
                    margin: "0",
                    border: "0",
                    padding: "0",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    clickBarChartIcon(elem.SharePriceHistory);
                  }}
                >
                  <Icon name="BarChart" color="#EE7F00" />
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  });
};

export default GroupPensionAnalysisList;
