import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class UserStore {
  isAuthorized = false;
  response = {};
  registrationStatus = 3;
  responseUserName = "";
  resendEmailLink = null;
  resendEmailLinkResponseMessage = null;
  impersonateUserResponse = null;
  registrationContractNumber = null;
  registrationApplicationNumber = null;

  constructor() {
    makeObservable(this, {
      isAuthorized: observable,
      registrationStatus: observable,
      responseUserName: observable,
      resendEmailLinkResponseMessage: observable,
      resendEmailLink: observable,
      impersonateUserResponse: observable,
      registrationContractNumber: observable,
      registrationApplicationNumber: observable,

      getResponseUserName: action,
      setUserAuthorization: action,
      registrationForAll: action,
      resetRegistrationStatus: action,
      resendRegistrationSuccessEmail: action,
      getImpersonateUser: action,
      deleteIpmeronateUser: action,
      registrationPartner: action,
      setRegistrationContractAndContractNumber: action,
    });
  }

  setUserAuthorization = (value) => {
    this.isAuthorized = value;
  };

  registrationForAll = async (regValues, regType) => {
    let urlType = "";
    if (regType === 1) {
      urlType = "individual";
    } else {
      urlType = "company";
    }
    try {
      let responseFromApi = await agent.user.registerForAll(urlType, regValues);
      this.response = { ...responseFromApi };
      if (responseFromApi.Status === 0) {
        this.registrationStatus = 0;
        this.resendEmailLink = responseFromApi.ResendEmailLink;
      }
      if (this.response.Status === 1) {
        this.registrationStatus = 1;
      }
      if (this.response.Status === 2) {
        this.registrationStatus = 2;
      }
    } catch (error) {
      console.log(error, "from registration api");
      this.registrationStatus = 1;
    }
  };

  registrationPartner = async (regValues) => {
    try {
      let responseFromApi = await agent.user.registerPartner(
        "outsourcedhr",
        regValues,
      );
      this.response = { ...responseFromApi };
      if (responseFromApi.Status === 0) {
        this.registrationStatus = 0;
        this.resendEmailLink = responseFromApi.ResendEmailLink;
      }
      if (this.response.Status === 1) {
        this.registrationStatus = 1;
      }
      if (this.response.Status === 2) {
        this.registrationStatus = 2;
      }
    } catch (error) {
      console.log(error, "from registration api");
      this.registrationStatus = 1;
    }
  };

  getResponseUserName = (value) => {
    this.responseUserName = value;
  };

  resetRegistrationStatus = () => {
    this.registrationStatus = 3;
  };

  resendRegistrationSuccessEmail = (url) => {
    let responseFromRecendEmail = agent.user.resendRegistrationEmail(url);
    responseFromRecendEmail.then((result) => {
      if (result.Status === 0) {
        this.resendEmailLinkResponseMessage = result.Message;
      }
    });
  };

  getImpersonateUser = (email) => {
    let impersonateUserResponse = agent.user.impersonateUserGuid(email);
    impersonateUserResponse
      .then((response) => {
        this.impersonateUserResponse = { ...response };
        if (response.Status === 0) {
          sessionStorage.setItem("client", response.UserGuid);
        } else {
          sessionStorage.removeItem("client");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteIpmeronateUser = () => {
    this.impersonateUserResponse = null;
  };

  setRegistrationContractAndContractNumber = (
    contractNumber,
    applicationNumber,
  ) => {
    this.registrationContractNumber = contractNumber;
    this.registrationApplicationNumber = applicationNumber;
  };
}
export default createContext(new UserStore());
