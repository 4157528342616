import React from "react";
import { observer } from "mobx-react-lite";
import ClaimsListToSubmit from "./ClaimsListToSubmit"


const Step3 = (props) => {
    return (
        <div className={props.isVisible ? "" : "doNotDisplay"}>
            {props.isVisible && (
                <ClaimsListToSubmit previousStep={props.previousStep} nextStep={props.nextStep} />
            )}
        </div>
    );
};

export default observer(Step3);