import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PaymentStore from "../../Stores/PaymentStore";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";
import "../../Css/Contracts_Details/selected-contract-payments.css";
import { PaymentsTableNN } from "../Payments/PaymentsTableNN";
import { PaymentsList } from "../Payments/PaymentsList";

const ContractSelectedPayment = (props) => {
  const { t } = useTranslation();
  const { paymentList } = useContext(PaymentStore);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const { width } = useWindowDimensions();

  const [filteredPaymentsList, setFilteredPaymentsList] = useState(null);
  const [paymentFrequency, setPaymentFrequency] = useState(
    props.PaymentFrequency,
  );

  useEffect(() => {
    if (paymentList) {
      getPaymentFromCon(paymentList);
    }
  }, [paymentList]);

  useEffect(() => {
    if (width < 770) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  const getPaymentFromCon = (list) => {
    let paymentArray = {};
    if (list) {
      list.map((contractElement) => {
        if (contractElement.Number === props.contractNumber) {
          paymentArray = contractElement.Payments;

          if (paymentFrequency === "" || paymentFrequency === null) {
            setPaymentFrequency(contractElement.PaymentFrequency);
          }
        }
      });
      setFilteredPaymentsList(paymentArray);
    }
  };

  return (
    <div className="payments-table">
      {filteredPaymentsList ? (
        filteredPaymentsList.length ? (
          <>
            <div className="selected-contract-payments">
              <div className="selected-contract-payments-title">
                <p>{t("contract.frequency")}:</p>
                <span className="payment-frequency">{paymentFrequency}</span>
              </div>

              {isSmallSize ? (
                filteredPaymentsList.map((elem, i) => {
                  return (
                    <PaymentsList
                      key={i}
                      paymentElem={elem}
                      documentDetails={props.DocumentDetails}
                    />
                  );
                })
              ) : (
                <PaymentsTableNN
                  data={filteredPaymentsList}
                  documentDetails={props.DocumentDetails}
                />
              )}
            </div>
          </>
        ) : null
      ) : null}
    </div>
  );
};

export default observer(ContractSelectedPayment);
