import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class SettingsStore {
  publicSettings = {};
  privateSettings = {};
  footer = null;
  privateFooter = null;
  authorities = null;
  authService = null;
  clientId = null;
  settingsHasContent = null;
  showErrorMessage = null;
  contentErrorMessage = null;
  resourceStrings = null;
  eclaimsResourceStrings = null;
  noRecordsFoundText = null;
  privateHRFooter = null;

  constructor() {
    makeObservable(this, {
      publicSettings: observable,
      privateSettings: observable,
      footer: observable,
      privateFooter: observable,
      authorities: observable,
      authService: observable,
      clientId: observable,
      settingsHasContent: observable,
      showErrorMessage: observable,
      contentErrorMessage: observable,
      noRecordsFoundText: observable,
      privateHRFooter: observable,

      loadPublicSettings: action,
      loadPrivateSettings: action,
      initiateAuthService: action,
      setShowErrorMessage: action,
      getResourceStringValue: action,
    });
  }

  loadPublicSettings = () => {
    const settings = agent.settings.getPublicSettings();
    settings
      .then((result) => {
        const publicSettings = { ...result };
        this.publicSettings = publicSettings;
        //set authorities
        if (publicSettings.AzureADUserFlowResetPassword && publicSettings.AzureADUserFlowSignIn && publicSettings.AzureADTenantName) {
          this.authorities = {
            signIn: {
              authority: `https://${publicSettings.AzureADTenantName}.b2clogin.com/${publicSettings.AzureADTenantName}.onmicrosoft.com/${publicSettings.AzureADUserFlowSignIn}`,
            },
            forgotPassword: {
              authority: `https://${publicSettings.AzureADTenantName}.b2clogin.com/${publicSettings.AzureADTenantName}.onmicrosoft.com/${publicSettings.AzureADUserFlowResetPassword}`,
            },
          };
          this.authorityForMsal = `https://${publicSettings.AzureADTenantName}.b2clogin.com/${publicSettings.AzureADTenantName}.onmicrosoft.com/${publicSettings.AzureADUserFlowSignIn}`;
          this.clientId = publicSettings.AzureADClientId;
        }
        //set footer
        if (publicSettings.Footer) {
          this.footer = publicSettings.Footer;
        }
      })
      .catch((error) => {});
  };

  loadPrivateSettings = () => {
    // this.settingsHasContent = false;
    const settings = agent.settings.getPrivateSettings();
    settings
      .then((result) => {
        const privateSettings = { ...result };
        this.privateSettings = privateSettings;
        if (privateSettings.Footer) {
          this.privateFooter = privateSettings.Footer;
        }
        if (privateSettings.FooterHR) {
          this.privateHRFooter = privateSettings.FooterHR;
        }
        if (privateSettings?.ResourceStrings) {
          this.resourceStrings = privateSettings?.ResourceStrings;
        }
        if (privateSettings?.eClaims?.ResourceStrings) {
          this.eclaimsResourceStrings = privateSettings.eClaims.ResourceStrings;
        }
        if (privateSettings?.eClaims?.NoRecordsFoundText) {
          this.noRecordsFoundText = privateSettings.eClaims.NoRecordsFoundText;
        }
        // this.settingsHasContent = true;
      })
      .catch((error) => {
        // this.settingsHasContent = true;
        console.log(error);
        throw error;
      });
  };

  getResourceStringValue = (key) => {
    let result = null;
    if (this.resourceStrings) {
      result = this.resourceStrings?.[key];
    } 
    
    if (!result && this.eclaimsResourceStrings) {
      result = this.eclaimsResourceStrings?.[key];
    }
    return result ?? this.noRecordsFoundText;
  };

  initiateAuthService = (newAuthServise) => {
    this.authService = newAuthServise;
  };

  setShowErrorMessage = (value, message) => {
    this.showErrorMessage = value;
    this.contentErrorMessage = message;
  };
}
export default createContext(new SettingsStore());
