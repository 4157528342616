import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import NavBarStore from "../Stores/NavBarStore";
import "../Css/Myheaders.css";
import { useTranslation } from "react-i18next";
const HeaderMessage = (props) => {
  const { t } = useTranslation();
  const { headerThemeByUrl } = useContext(NavBarStore);
  const [header, setHeader] = useState({});

  useEffect(() => {
    if (headerThemeByUrl) {
      setHeader(headerThemeByUrl);
    }
    if (props.data) {
      setHeader(props.data);
    }
  }, [headerThemeByUrl, props]);

  return (
    <Fragment>
      {Object.keys(header).length === 0 ? null : (
        <Fragment>
          <h1 className="my-label">
            {header.Title === t("header.messages")
              ? t("navigation.item-myInbox")
              : header.Title}
          </h1>
          {header.Title !== t("header.messages") ? (
            <p className="my-description" data-testid="page-description">
              {header.Summary}
            </p>
          ) : (
            ""
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default observer(HeaderMessage);
