import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuthRoute = ({ children, auth, path }) => {
  const location = useLocation();

  return <>{auth ? children : <Navigate to={path} state={{ from: location }} />}</>;
};

export default RequireAuthRoute;
