import React, { useState } from "react";
import pdf from "../../assets/index";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useTranslation } from "react-i18next";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
const RegisterExampleDoc = () => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  return (
    <>
      <h4 className="modal-title">{t("register.nn-process")}</h4>
      <center>
        <Document
          file={pdf.manualRegistration}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          onLoadError={console.error}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              scale={1.5}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </center>
    </>
  );
};
export default RegisterExampleDoc;
