import React from "react";
import { Table, Icon } from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";
import { toJS } from "mobx";
import { activeGroupContractStatus } from "../Utils/Constants";
import { RESTRICTED_ACCESS_CONTRACTS } from "../../Contracts/constants";

export const ContractTableHRNN = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clickToDetails = (detail) => {
    const isRestrictedAccess =
      sessionStorage.getItem("support") &&
      RESTRICTED_ACCESS_CONTRACTS.some((contract) =>
        detail.PolicyNumber.includes(contract),
      );
    if (isRestrictedAccess) return;
    navigate("/hr/contracts/details", { state: toJS(detail) });
  };
  const columns = [
    {
      field: "a",
      headerName: "",
      flex: 0.3,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            onClick={() =>
              activeGroupContractStatus.includes(params.row.ContractStatus) &&
              clickToDetails(params.value)
            }
          >
            <Icon name="ChevronRight" variant="Medium" color="#EA650D" />
          </div>
        );
      },
    },
    {
      field: "b",
      headerName: t("hr.contract"),
      headerAlign: "left",
      align: "left",
      flex: 1.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <span
            onClick={() =>
              activeGroupContractStatus.includes(params.row.ContractStatus) &&
              clickToDetails(params.value.element)
            }
          >
            {" "}
            <b>{params.value.CompanyName}</b>
            <br />
            {params.value.DisplayPolicyNumber}
          </span>
        );
      },
    },
    {
      field: "d",
      headerName: t("contracts.expiration-date"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span
            onClick={() =>
              activeGroupContractStatus.includes(params.row.ContractStatus) &&
              clickToDetails(params.value.element)
            }
          >
            <b>{setDateFormatForDateTime(params.value.CancellationDate)}</b>
          </span>
        );
      },
    },
    {
      field: "e",
      headerName: t("global.condition"),
      headerAlign: "left",
      align: "left",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <span
            onClick={() =>
              activeGroupContractStatus.includes(params.row.ContractStatus) &&
              clickToDetails(params.value.element)
            }
            className={`${activeGroupContractStatus.includes(params.row.ContractStatus) ? "normal" : "light-gray"}`}
          >
            {params.value.element.ContractStatusDescription}
          </span>
        );
      },
    },
  ];

  const rows = data.map((element, i) => {
    return {
      id: i,
      a: element,
      b: {
        CompanyName: element.CompanyName,
        PolicyNumber: element.PolicyNumber,
        DisplayPolicyNumber: element.DisplayPolicyNumber,
        element,
      },
      c: {
        NextBillingDate: element.NextBillingDate,
        PaymentFrequency: element.PaymentFrequency,
        IsGroupPolicy: element.IsGroupPolicy,
        element,
      },
      d: { CancellationDate: element.CancellationDate, element },
      e: { ContractStatus: element.ContractStatus, element },
      ContractStatus: element.ContractStatus,
    };
  });

  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
    />
  );
};
