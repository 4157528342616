import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import img from "../../assets/index";
import UrlStore from "../../Stores/UrlStore";

const PreviewPage = (props) => {
  const urlStore = useContext(UrlStore);
  const { baseURL } = urlStore;
  const [data, setData] = useState();

  useEffect(() => {
    if (baseURL.length === 0) {
      urlStore.loadURL(process.env.REACT_APP_ENVIRONMENT);
    }
  }, [baseURL]);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props]);

  return (
    <Fragment>
      {data ? (
        <>
          <hr className="line" />

          <div className="nn-container">
            <div className="noContentPreview">
              <img src={img.failure} alt="failure" />
              <span className="text"> {data}</span>
            </div>
          </div>
        </>
      ) : null}
    </Fragment>
  );
};

export default PreviewPage;
