import { useContext, useMemo } from "react";
import ClaimsStore from "../../../Stores/ClaimsStore";
import { ExpenseType } from "../../../Consts/Claims";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import img from "../../../assets/index";
import { Label } from "@nn-design-system/react-component-library";

const ExpenseTypeItem = ({
  currentExpenseTypeCode,
  currentExpenseType,
  selectedExpense,
  isExpenseElementDisabled,
  showExpenseComponent,
  setSelectedExpense,
}) => {
  const claimStore = useContext(ClaimsStore);
  const { claimsListToSubmit } = claimStore;

  const expenseTypeListCountNumber = useMemo(() => {
    if (claimsListToSubmit && claimsListToSubmit.length > 0) {
      return claimsListToSubmit?.filter(
        (claim) => claim.ExpenseType === currentExpenseTypeCode
      ).length;
    } else {
      return 0;
    }
  }, [claimsListToSubmit, currentExpenseTypeCode]);

  const expenseTypeImage = useMemo(() => {
    switch (currentExpenseTypeCode) {
      case ExpenseType.MedicalVisit:
        return img.medicalVisit;
      case ExpenseType.DiagnosticExamination:
        return img.diagnosticExamination;
      case ExpenseType.Pharmacies:
        return img.pharmacies;
      case ExpenseType.PhysicalTherapy:
        return img.physicalTherapy;
      case ExpenseType.HospitalAllowance:
        return img.hospitalAllowance;
      case ExpenseType.SurgicalAllowance:
        return img.surgicalAllowance;
      case ExpenseType.OutOfHospitalMedicalProcedures:
        return img.outOfHospitalMedicalProcedures;
      default:
        return null;
    }
  }, [currentExpenseTypeCode]);

  const expenseFlexBasis = useMemo(() => {
    switch (currentExpenseTypeCode) {
      case ExpenseType.MedicalVisit:
        return "150px";
      case ExpenseType.DiagnosticExamination:
        return "250px";
      case ExpenseType.Pharmacies:
        return "220px";
      case ExpenseType.PhysicalTherapy:
        return "250px";
      case ExpenseType.HospitalAllowance:
        return "256px";
      case ExpenseType.SurgicalAllowance:
        return "250px";
      case ExpenseType.OutOfHospitalMedicalProcedures:
        return "291px";
      default:
        return null;
    }
  }, [currentExpenseTypeCode]);

  return (
    <div
      className={`${styles.expenseImgElement} ${
        selectedExpense === currentExpenseTypeCode
          ? styles.expenseImgElementSelected
          : ""
      } ${
        isExpenseElementDisabled(currentExpenseTypeCode)
          ? styles.expenseImgElementDisabled
          : ""
      }`}
      style={{ flexBasis: expenseFlexBasis }}
      onClick={() => {
        if (!showExpenseComponent) {
          setSelectedExpense(currentExpenseTypeCode);
        }
      }}
    >
      <img src={expenseTypeImage} alt={currentExpenseType.DisplayName} />
      <Label text={currentExpenseType.DisplayName} />
      {Number.isInteger(expenseTypeListCountNumber) &&
        expenseTypeListCountNumber > 0 && (
          <span className={styles.claimCountingBadge}>
            {expenseTypeListCountNumber}
          </span>
        )}
    </div>
  );
};

export default ExpenseTypeItem;
