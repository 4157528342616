import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  RadioButton,
  RadioButtonGroup,
  SearchInput,
} from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import SpinnerSmall3 from "../../CommonComp/SpinnerSmall3";
import HRStore from "../../../Stores/HRStore";
import CoverageTableHR from "./CoverageTableHR";
import LoaderStore from "../../../Stores/LoaderStore";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";
import { ExcelExport } from "../Commons/ExcelExport";
import { useTranslation } from "react-i18next";
const CoveragesHR = ({ details }) => {
  const { t } = useTranslation();
  const hrStore = useContext(HRStore);
  const loaderStore = useContext(LoaderStore);
  const { hrCoveragesList, showCoveragesStore, coverageHasContent } = hrStore;
  const [list, setList] = useState([]);
  const [listToExcel, setlistToExcel] = useState([]);
  const [radioType, setRadioType] = useState("all");
  const [searchValue, setSearchValue] = useState("");

  const checkSearchValue = useCallback(
    (el) =>
      ["FullName", "EmployeeNumber"].some((prop) =>
        el[prop]?.toLowerCase().includes(searchValue),
      ),
    [searchValue],
  );
  const checkRadioValue = useCallback(
    (el) =>
      radioType === t("hr.registered") ? el.IsActive === radioType : true,
    [radioType],
  );

  useEffect(() => {
    setList(hrCoveragesList);
    loaderStore.setMiniLoaderForData3(false);
  }, [hrCoveragesList]);

  useEffect(() => {
    if (searchValue) {
      let filteredCoverageList = hrCoveragesList.filter(
        (el) => checkSearchValue(el) && checkRadioValue(el),
      );
      setList(filteredCoverageList);
    } else {
      let filteredCoverageList = hrCoveragesList.filter((el) =>
        checkRadioValue(el),
      );
      return setList(filteredCoverageList);
    }
  }, [searchValue, radioType]);

  const searchHandler = (e) => {
    if (e === undefined) {
      setSearchValue("");
    } else if (e.target.value === "" || e.target.value === " ") {
      e.target.value = e.target.value.replace(/\s/g, "");
      setSearchValue("");
    } else {
      setSearchValue(e.target.value);
    }
  };

  useEffect(() => {
    const listToExcelFormatted = list.flatMap((item) => {
      const { CoverageList } = item;

      let mainObj = {
        Ασφαλιζόμενος: `${item.FirstName} ${item.LastName}`,
        "Αρ. υπαλλήλου": item.EmployeeNumber,
        "Α.Φ.Μ.": item.TaxNumber,
        "Ημερομηνία γέννησης": setDateFormatForDateTime(item.BirthDate),
        "Τύπος ασφαλισμένου": item.InsuredType,
        "Εγγραφή στο myNN": item.IsActive,
      };

      if (CoverageList && CoverageList.length > 0) {
        return CoverageList.map((coverageItem) => ({
          ...mainObj,
          "Όνομα κάλυψης": coverageItem.CoverageDescription,
          "Ημερομηνία ένταξης": setDateFormatForDateTime(
            coverageItem.CoverageStartDate,
          ),
        }));
      } else {
        return {
          ...mainObj,
        };
      }
    });
    setlistToExcel(listToExcelFormatted);
  }, [list]);

  useEffect(() => {
    if (details.PolicyNumber && !coverageHasContent) {
      loaderStore.setMiniLoaderForData3(true);
      hrStore.loadHRCoverages(details.PolicyNumber);
    }
  }, [details]);

  return (
    <div className="contract-hr-tab-container">
      {showCoveragesStore && coverageHasContent ? (
        <>
          <div className="contracts">
            <div htmlFor="search-contracts" className="search-contracts-hr">
              <SearchInput
                labelText=""
                onChange={searchHandler}
                placeholderText={`${t("hr.number")} / ${t("hr.employee-name")}`}
                value={searchValue}
                onEmptySearchInput={searchHandler}
              />
            </div>
            <div className="filter-contracts">
              <RadioButtonGroup
                labelText=""
                value={radioType}
                onChange={(ev) => setRadioType(ev.target.value)}
                variant={"Default"}
              >
                <div className={"radiogroup"}>
                  <RadioButton
                    labelText={t("hr.registered-plural")}
                    value={"Εγγεγραμμένος"}
                  />
                  <RadioButton
                    ml={"45px"}
                    labelText={t("hr.all")}
                    value={"all"}
                  />
                </div>
              </RadioButtonGroup>
            </div>
          </div>
          <ExcelExport
            excelData={listToExcel}
            excelFileName={`${details.DisplayPolicyNumber}-Καλύψεις`}
          />
          <div className="selected-contract-coverages hr-coverage-selected">
            {hrCoveragesList ? (
              <>
                <CoverageTableHR element={list}></CoverageTableHR>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <SpinnerSmall3></SpinnerSmall3>
      )}
    </div>
  );
};

export default observer(CoveragesHR);
