import { createContext } from "react";
import { action, observable, makeObservable } from "mobx";
import agent from "../Api/agent";

class ArticleStore {
  articleDetails = {};
  articleCategories = [];
  articleList = [];
  articleHasContent = false;

  constructor() {
    makeObservable(this, {
      articleDetails: observable,
      articleCategories: observable,
      articleList: observable,
      articleHasContent: observable,

      loadArticleDetail: action,
      loadArticleList: action,
    });
  }

  loadArticleDetail = (articleId) => {
    const articleDetail = agent.article.details(articleId);
    articleDetail.then((result) => {
      this.articleDetails = { ...result };
    });
  };

  loadArticleList = () => {
    this.articleHasContent = false;
    const articleListWithCategories = agent.article.list();
    articleListWithCategories
      .then((result) => {
        const articleListwithCategories = { ...result };
        this.articleList = [...articleListwithCategories.Articles];
        this.articleCategories = [...articleListwithCategories.Categories];
        this.articleHasContent = true;
      })
      .catch((error) => {
        this.articleHasContent = true;
      });
  };
}

export default createContext(new ArticleStore());
