import { useContext, useMemo } from "react";
import {
  Link,
  Paragraph,
  PrefabModal,
  SpinnerLoader,
  Heading,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import ClaimsStore from "../../../Stores/ClaimsStore";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import { ClaimState } from "../../../Consts/Claims";
import SettingsStore from "../../../Stores/SettingsStore";
import "../../../Css/CommonCss/General.css";

const SubmissionModal = ({ showSubmissionModal, handleClose }) => {
  const { t } = useTranslation();
  const claimsStore = useContext(ClaimsStore);
  const { privateSettings } = useContext(SettingsStore);
  const { claimState } = claimsStore;
  const successHeading = t("claim.submission.successfully-cleared");
  const timedOutAndInProgressHeading = t(
    "claim.submission.successfully-processed",
  );
  const failedHeading = t("claim.submission.failure-msg");
  const loadingText = t("claim.processing-request");
  //TODO the text is the same for both constants??
  const successText =
    "Μπορείτε να δείτε τις λεπτομέρειες στη λίστα αποζημιώσεων.";
  const timedOutAndInProgressText =
    "Μπορείτε να δείτε τις λεπτομέρειες στη λίστα αποζημιώσεων.";

  const failedText = useMemo(() => {
    return DOMPurify.sanitize(
      privateSettings.eClaims.ResourceStrings[
        "NN.Claim.Submission.FailureContactInfo"
      ],
      { USE_PROFILES: { html: true } },
    );
  }, [privateSettings.eClaims.ResourceStrings]);

  const renderSubmissionResult = useMemo(() => {
    if (
      claimState === ClaimState.processing ||
      claimState === ClaimState.submitting
    ) {
      return (
        <div className={styles.submissionModalLoading}>
          <SpinnerLoader variant="Large" />
          <Paragraph>{loadingText}</Paragraph>
        </div>
      );
    } else if (claimState === ClaimState.successful) {
      return (
        <div className={styles.submissionModalResult}>
          <span className={styles.header}>
            <Heading isFirstSection>{successHeading}</Heading>
          </span>
          <div className={styles.submissionModalResultDiv}>
            <Paragraph>{successText}</Paragraph>
            <Link
              text={t("claim.compensation-list")}
              variant="SecondaryStandAlone"
              iconProps={{
                name: "ChevronRight",
                placement: "Right",
              }}
              onClick={handleClose}
            />
          </div>
        </div>
      );
    } else if (claimState === ClaimState.failed) {
      return (
        <div className={styles.submissionModalResult}>
          <span className={styles.header}>
            <Heading isFirstSection>{failedHeading}</Heading>
          </span>
          <div className={styles.submissionModalResultDiv}>
            <p
              className="htmlParagraph"
              dangerouslySetInnerHTML={{
                __html: failedText,
              }}
            ></p>
            <Link
              text={t("global.return-home")}
              variant="SecondaryStandAlone"
              iconProps={{
                name: "ChevronRight",
                placement: "Right",
              }}
              onClick={handleClose}
            />
          </div>
        </div>
      );
    } else if (
      claimState === ClaimState.timedOut ||
      claimState === ClaimState.inProgress
    ) {
      return (
        <div className={styles.submissionModalResult}>
          <span className={styles.header}>
            <Heading isFirstSection>{timedOutAndInProgressHeading}</Heading>
          </span>
          <div className={styles.submissionModalResultDiv}>
            <Paragraph>{timedOutAndInProgressText}</Paragraph>
            <Link
              text={t("claim.compensation-list")}
              variant="SecondaryStandAlone"
              iconProps={{
                name: "ChevronRight",
                placement: "Right",
              }}
              onClick={handleClose}
            />
          </div>
        </div>
      );
    } else {
      //TODO check this
      return <p>Unhandled State {claimState}</p>;
    }
  }, [claimState, failedText, handleClose]);

  return (
    <PrefabModal
      isOpen={showSubmissionModal}
      width={claimState === ClaimState.timedOut ? "700px" : "615px"}
      height="auto"
      headingText={" "}
      hasCloseButton={
        claimState !== ClaimState.processing &&
        claimState !== ClaimState.submitting
      }
      children={<>{renderSubmissionResult}</>}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose();
        }
      }}
    />
  );
};

export default SubmissionModal;
