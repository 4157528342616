import React, { useCallback, useContext } from "react";
import {
  Card,
  Heading,
  Paragraph,
  Button,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import ContractStore from "../../Stores/ContractStore";
import HRStore from "../../Stores/HRStore";
import "../../Css/HR/RoleSelection.css";
const RoleSelection = () => {
  const { t } = useTranslation();
  const { contractList } = useContext(ContractStore);
  const { setIsHrRole } = useContext(HRStore);
  const dispatchNavigation = useCallback((isHRSection) => {
    setIsHrRole(isHRSection);
    sessionStorage.setItem("isHrRole", isHRSection);
  }, []);

  return (
    <div className="role-selection-container">
      <Paragraph mb="20px">{t("hr.select-navigation")}</Paragraph>
      <div
        className={`roles-options-selection ${!contractList.length && "roles-options-selection-hr"}`}
      >
        {!!contractList.length && (
          <Card variant="Tinted" maxWidth="450px" minHeight="250px">
            <section className="card-role-content">
              <Heading
                mt="20px"
                hasMargin={false}
                variant="XL"
                testId="role-option-header"
              >
                {t("hr.secured")}
              </Heading>
              <Paragraph>{t("hr.information-insurance")}</Paragraph>
              <Paragraph hasMargin={false}>
                <Button
                  onClick={() => dispatchNavigation(false)}
                  mb="25px"
                  variant={"PrimaryLarge"}
                >
                  {t("global.continue")}
                </Button>
              </Paragraph>
            </section>
          </Card>
        )}

        <Card variant="Tinted" maxWidth="450px" minHeight="250px">
          <section className="card-role-content">
            <Heading hasMargin={false} variant="XL" testId="role-option-header">
              {t("hr.group-program-manager")}
            </Heading>
            <Paragraph>{t("hr.info-group-program-manager")}</Paragraph>
            <Paragraph hasMargin={false}>
              <Button
                mb="25px"
                variant={"PrimaryLarge"}
                onClick={() => dispatchNavigation(true)}
              >
                {t("global.continue")}
              </Button>
            </Paragraph>
          </section>
        </Card>
      </div>
    </div>
  );
};
export default observer(RoleSelection);
