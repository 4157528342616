import React, { useContext, useEffect, useState } from "react";

import img from "../../assets/index";
import CookieSetting from "./CookieSetting";
import { useTranslation } from "react-i18next";
import "../../Css/CommonCss/cookies.css";
import CookieStore from "../../Stores/CookieStore";
import dompurify from "dompurify";
import { observer } from "mobx-react-lite";
import {
  PrefabModal,
  Button,
  Switch,
} from "@nn-design-system/react-component-library";

const Cookies = () => {
  const { t } = useTranslation();
  const cookieStore = useContext(CookieStore);
  const [modalShow, setModalShow] = useState(false);
  const [consentShow, setConsentShow] = useState("");
  const [settingsButtonShow, setSettingsButtonShow] = useState("hidden");
  const { cookies, showCookies } = cookieStore;
  const [allState, setAllState] = useState(0);
  const [checkState, setCheckState] = useState(0);
  const [switchOnOff, setswitchOnOff] = useState("Checked");
  const [cookieTotal, setCookieTotal] = useState();

  const sanitizer = dompurify.sanitize;

  const closemodal = () => {
    setModalShow(false);
  };

  const openmodal = () => {
    setModalShow(true);
  };

  // krivoume to cookie details
  const hideConsent = (e) => {
    setConsentShow("hidden");
    setSettingsButtonShow("");
  };

  //kanoume submit ta cookies apo to modal
  const submitCookies = (cookies) => {
    closemodal();
    setConsentShow("hidden");
    cookieStore.upDateCookies(cookies.CookieSettings.CookieGroups);
  };

  //kanoume submit ta cookies apo to apodoxi i to koumpi mi apodoxi
  const submitAllCookies = (cookies, value) => {
    cookies.CookieSettings.CookieGroups.forEach((elem, i) => {
      if (elem.IsMandatory === false) {
        if (value) {
          elem.CookiePreference.State = 1;
        } else {
          elem.CookiePreference.State = 2;
        }
      }
    });
    cookieStore.upDateCookies(cookies.CookieSettings.CookieGroups);
    hideConsent();
  };

  useEffect(() => {
    var count = 0;
    if (cookies) {
      cookies.CookieSettings.CookieGroups.map((cookieElem, i) => {
        if (cookieElem.IsMandatory === false) {
          count++;
          if (cookieElem.CookiePreference.State != 1) {
            setswitchOnOff(undefined);
            count--;
          }
        }
      });
    }
    setCookieTotal(count);
  }, [cookies]);

  //edw fernei to state apo to cookie row otan ginete inactive
  useEffect(() => {
    if (checkState != 0) {
      if (checkState == 2) {
        setswitchOnOff(undefined);
      }
    }
  }, [checkState]);

  //kanoume apodoxi olwn tvn cookies vasi tou kentrikou koumpiou
  const acceptAllSettings = (value) => {
    if (value === "Checked") {
      setswitchOnOff(undefined);
      setAllState(2);
      setCookieTotal(0);
    } else {
      setswitchOnOff("Checked");
      setAllState(1);
      setCookieTotal(1);
    }
  };

  useEffect(() => {
    if (showCookies) {
      setConsentShow("hidden");
      setSettingsButtonShow("");
    } else {
      setConsentShow("");
      setSettingsButtonShow("");
    }
  }, [showCookies]);

  //edw akoume tin alagi tou koumpiou se active
  useEffect(() => {
    if (cookies) {
      let value;
      let array = cookies.CookieSettings.CookieGroups.find(
        (c) => c.IsMandatory === false,
      );

      if (typeof array === "object") {
        value = 1;
      } else {
        value = array.length;
      }
      if (cookieTotal == value) {
        setswitchOnOff("Checked");
        setAllState(1);
      }
      if (cookieTotal < value) {
        setswitchOnOff(undefined);
        setAllState(2);
      }
    }
  }, [cookieTotal, cookies]);

  return (
    <>
      {cookies ? (
        <>
          <div
            className={`cookie-consent extreme-light-gray-bg ${consentShow}`}
          >
            <div className="nn-container">
              <div className="close" onClick={hideConsent}></div>
              <div className="content">
                <div className="left">
                  <b className="light">{cookies.CookieSettings.Title}</b>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(cookies.CookieSettings.Description, {
                        ADD_ATTR: ["target"],
                      }),
                    }}
                  />
                </div>
                <div className="right">
                  <div>
                    <a
                      className="nn-button-normal accept-cookies"
                      onClick={() => submitAllCookies(cookies, true)}
                    >
                      {t("commons.accept")}
                    </a>
                    <a
                      className="nn-button-gray accept-cookies"
                      onClick={() => submitAllCookies(cookies, false)}
                    >
                      {t("commons.do-not-accept")}
                    </a>
                    <div className="chevron-link">
                      <a onClick={openmodal}>
                        <img src={img.chevron_right} />
                        <span>{t("commons.management")} Cookies</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`cookie-consent-minimized extra-light-gray-bg ${settingsButtonShow}`}
          >
            <div className="chevron-link">
              <a onClick={openmodal}>
                <img src={img.chevron_right} />
                <span>{t("commons.management")} Cookies</span>
              </a>
            </div>
          </div>

          <PrefabModal
            isOpen={modalShow}
            hasCloseButton
            width="970px"
            height="auto"
            children={
              <div className="cookie-settings">
                <h4 className="modal-title">{cookies.CookieSettings.Title}</h4>
                <div className="cookie-grid">
                  <h5 className="modal-description">
                    {cookies.CookieSettings.DetailedDescription}
                  </h5>
                  <Button
                    onClick={() => submitCookies(cookies)}
                    variant="PrimaryMedium"
                  >
                    {t("commons.acceptance")}
                  </Button>
                </div>
                <div>
                  <div className="setting-row normal">
                    <div className="setting-title">
                      <span className="normal">
                        {t("commons.accept-all")} cookies
                      </span>
                    </div>
                    <Switch
                      dataState={switchOnOff}
                      onChange={() => acceptAllSettings(switchOnOff)}
                    />
                  </div>
                  {cookies.CookieSettings.CookieGroups.map((cookieElem, i) => {
                    let mandatory = false;
                    if (cookieElem.IsMandatory === true) {
                      mandatory = true;
                    }
                    return (
                      <CookieSetting
                        key={i}
                        cookie={cookieElem}
                        allState={allState}
                        id={i}
                        state={setCheckState}
                        setCookieTotal={setCookieTotal}
                        cookieTotal={cookieTotal}
                        mandatory={mandatory}
                      />
                    );
                  })}
                </div>
              </div>
            }
            onClose={closemodal}
            headingText={" "}
          />
        </>
      ) : null}
    </>
  );
};

export default observer(Cookies);
