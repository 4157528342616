import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Table } from "@nn-design-system/react-component-library";
import ContractStore from "../../Stores/ContractStore";
import GroupPolicySelectedCoverageRow from "./GroupPolicySelectedCoverageRow";

const GroupPolicyCoverages = (props) => {
  const contractStore = useContext(ContractStore);
  const { t } = useTranslation();
  const columns = [
    {
      field: "CoverageDescription",
      headerName: t("contract.coverage-class"),
      flex: 1.7,
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      sortable: false,
    },
    {
      field: "PersonRole",
      headerName: t("contract.capacity"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "fullName",
      headerName: `${t("contract.name-primary-insured")} / ${t("contract.protected-member")}`,
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
    },
  ];
  useEffect(() => {
    if (props.contractId && props.getData) {
      contractStore.loadCoverages(
        props.contractId,
        props.ProductCode,
        props.productCategoryId,
        true,
      );
    }
  }, [props.contractId, props.getData]);

  const row = props.Coverages?.map((element, i) => {
    return {
      id: i,
      fullName: `${element.PersonName} ${element.PersonSurname}`,
      ...element,
    };
  });
  return (
    <>
      {props.showCoverages ? (
        <div className="selected-contract-coverages">
          <Table
            rows={row}
            density="comfortable"
            columns={columns}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            hasAutoHeight
            localeText={{ noRowsLabel: t("global.no-results-found") }}
            getRowHeight={() => "auto"}
            getDetailPanelContent={(rowData) => (
              <GroupPolicySelectedCoverageRow
                element={rowData.row}
              ></GroupPolicySelectedCoverageRow>
            )}
            getDetailPanelHeight={() => {
              return "auto";
            }}
            hideFooter
          ></Table>
        </div>
      ) : null}
    </>
  );
};

export default observer(GroupPolicyCoverages);
