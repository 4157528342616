import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Tabs } from "@nn-design-system/react-component-library";
import PortfolioStore from "../../Stores/PortfolioStore";
import { PortfolioPerFund } from "./PortfolioPerFund";
import { PortfolioPerProduct } from "./PortfolioPerProduct";
import { usePortfolio } from "./hooks/usePortfolio";
import PageTheme from "../PageTheme";

const Portfolio = () => {
  const { t } = useTranslation();
  const {
    userPorfolio: { FundAnalysisList, DisclaimerText },
    loadPorfolio,
  } = useContext(PortfolioStore);
  const [tabSelected, setTabSelected] = useState("FundName");
  const [data, setData] = useState(null);
  const { mergePortfolioDataFunds } = usePortfolio({
    FundAnalysisList,
    tabSelected,
  });

  useEffect(() => {
    loadPorfolio();
  }, []);

  useEffect(() => {
    let mergedData = [];
    FundAnalysisList?.forEach((current) => {
      const existingItem = mergedData.find(
        (item) =>
          item.FundCode === current.FundCode &&
          item.ContractNumber === current.ContractNumber,
      );
      if (existingItem) {
        existingItem.NumberOfUnits += current.NumberOfUnits;
        existingItem.FundPercentage += current.FundPercentage;
        existingItem.UnitPrice += current.UnitPrice;
        // existingItem.UnitSpecialPrice += current.UnitSpecialPrice;
        // Merge UnitAllocationPercentage in order to show the merged fund to the bar chart
        existingItem.UnitAllocationPercentage +=
          current.UnitAllocationPercentage;
      } else {
        mergedData.push({ ...current });
      }
    });
    setData(mergedData);
  }, [FundAnalysisList]);

  const getTabs = () => {
    let tabs = [
      {
        contentElement: (
          <PortfolioPerFund
            tabSelected={tabSelected}
            userPorfolio={mergePortfolioDataFunds}
            originalData={data}
          />
        ),
        tabText: t("portfolio.tab-by-investment"),
      },
      {
        contentElement: (
          <PortfolioPerProduct
            tabSelected={tabSelected}
            userPorfolio={mergePortfolioDataFunds}
            originalData={data}
            disclaimer={DisclaimerText}
          />
        ),
        tabText: t("portfolio.tab-by-program"),
      },
    ];
    return tabs;
  };

  return (
    <>
      <PageTheme />
      {FundAnalysisList && FundAnalysisList?.length > 0 ? (
        <div className="nn-container">
          <Tabs
            onChange={(index) =>
              setTabSelected(index === 0 ? "FundName" : "ContractNumber")
            }
            centered
            tabs={getTabs()}
          />
        </div>
      ) : (
        <div className="nn-container">
          <p>{DisclaimerText}</p>
        </div>
      )}
    </>
  );
};

export default observer(Portfolio);
