import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "./translations/en.json";
import translationsGR from "./translations/gr.json";

const resources = {
  en: {
    translation: translationsEN,
  },
  gr: {
    translation: translationsGR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "gr",
  fallbackLng: "gr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
