import React, { Fragment, useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import DocumentsStore from "../../Stores/DocumentsStore";
import UrlStore from "../../Stores/UrlStore";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";

import "../../Css/Contracts_Details/selected-contract-documents.css";
import { DocumentsTableNN } from "../Document/DocumentsTableNN";
import LoaderStore from "../../Stores/LoaderStore";
import { Select } from "@nn-design-system/react-component-library";
import { DocumentsList } from "../Document/DocumentsList";

const ContractSelectedDocument = (props) => {
  const { t } = useTranslation();
  const { documentList, documentsHasContent } = useContext(DocumentsStore);
  const { baseURL } = useContext(UrlStore);
  const { setMiniLoaderForData3, miniLoaderForData3 } = useContext(LoaderStore);
  const [statusValue, setStatusValue] = useState("");
  const [isSmallSize, setIsSmallSize] = useState(false);
  const { width } = useWindowDimensions();

  const [docList, setDocList] = useState(null);
  const [dropDownMenuValues, setDropDownMenuValues] = useState([]);
  const [newDocumentList, setNewDocumentList] = useState([]);

  useEffect(() => {
    newArray(props.contractDetails.Number);

    if (documentsHasContent) {
      setMiniLoaderForData3(false);
    } else {
      setMiniLoaderForData3(true);
    }
  }, [documentsHasContent, props.contractDetails.Number]);

  useEffect(() => {
    setDocList([...newDocumentList]);
  }, [newDocumentList]);

  useEffect(() => {
    if (docList) {
      dropDownMenuArray();
    }
  }, [docList]);

  useEffect(() => {
    if (width < 580) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  const dropDownMenuArray = () => {
    let unique_values = [
      ...new Set(newDocumentList.map((p) => p.DocumentType)),
    ];
    setDropDownMenuValues(unique_values);
  };

  const newArray = (contractNumber) => {
    let testedArray = documentList.filter(
      (x) => x.ContractNumber === contractNumber,
    );
    setNewDocumentList(testedArray);
  };

  const dropDownHandler = (e) => {
    if (e.target.value !== (null || "all") && newDocumentList.length != 0) {
      const newTestArray = newDocumentList.filter(
        (x) => x.DocumentType === e.target.value,
      );

      setDocList(newTestArray);
    } else {
      setStatusValue(e.target.value);
      setDocList(newDocumentList);
    }
  };

  return (
    <Fragment>
      {miniLoaderForData3 ? null : (
        <>
          {docList ? (
            <div className="selected-contract-documents">
              <div className="select-nn">
                <Select
                  mt="20px"
                  mb="20px"
                  labelText=""
                  placeholderText={t("document.select-document-type")}
                  name="select"
                  onChange={dropDownHandler}
                  options={[
                    { text: t("document.select-document-type"), value: "all" },
                    ...dropDownMenuValues.map((elementDrop) => ({
                      text: elementDrop,
                      value: elementDrop,
                    })),
                  ]}
                  testId="select"
                  value={statusValue}
                />
              </div>
              {isSmallSize ? (
                docList?.map((elemDoc) => <DocumentsList elemDoc={elemDoc} />)
              ) : (
                <DocumentsTableNN documentList={docList} />
              )}
            </div>
          ) : null}
        </>
      )}
    </Fragment>
  );
};

export default observer(ContractSelectedDocument);
