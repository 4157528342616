import React, { useState } from "react";
import {
  Paragraph,
  Table,
  Icon,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import {
  setDateFormatForDateTime,
  stringToAmount,
} from "../../CommonComp/Functions";
import agent from "../../../Api/agent";
import ElementDownload from "../../Document/ElementDownload";
export const PaymentsDetailPanelTableHR = ({ element, contract }) => {
  const { t } = useTranslation();
  const [documents, setDocument] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const columns = [
    {
      field: "InvoiceNumber",
      headerName: t("hr.document-number"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
      headerClassName: "textAlignLeft",
    },
    {
      field: "InstallmentFrom",
      headerName: t("hr.billing-period-date"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1.5,
      sortable: false,
      valueFormatter: (props) => {
        return setDateFormatForDateTime(props.value);
      },
      renderCell: (params) => {
        return (
          <div className="">
            <Paragraph hasMargin={false}>
              {setDateFormatForDateTime(params.row.InstallmentFrom)} -
            </Paragraph>
            <Paragraph hasMargin={false}>
              {setDateFormatForDateTime(params.row.InstallmentTo)}
            </Paragraph>
          </div>
        );
      },
    },
    {
      field: "InstallmentGrossPremium",
      headerName: t("hr.payment-billing-amount"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      headerClassName: "textAlignLeft",
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "PaidAmount",
      headerName: t("hr.payment-amount"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "PaymentDate",
      headerName: t("hr.payment-date-pension-table"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return setDateFormatForDateTime(props.value);
      },
    },
    {
      field: "StatusDescription",
      headerName: t("global.condition"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1.1,
      sortable: false,
    },
    {
      field: "a",
      headerName: t("hr.payment-download-document"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <a
          className="active"
          onClick={() => downloadPaymentDocument(params.row)}
        >
          <Icon name="ArrowDownLine" variant={"Medium"} color="#EE7F00" />
        </a>
      ),
    },
  ];
  const downloadPaymentDocument = (params) => {
    const policiesPayment = agent.hr.getPolicycPayemntsHR({
      contractNumber: contract,
      invoiceNumber: params.InvoiceNumber,
      invoiceType: "Billing",
    });

    policiesPayment
      .then((result) => {
        setDocument(result);
        setShowModal(true);
      })
      .catch((error) => {
        setDocument([]);
        console.log(error, "error payments response");
      });
  };
  const rows = element.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <div>
      <Table
        localeText={{ noRowsLabel: t("global.no-results-found") }}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
      ></Table>
      <PrefabModal
        isOpen={showModal}
        hasCloseButton
        children={
          <div className={""}>
            {documents && !!documents.length ? (
              documents.map((elem) => {
                return (
                  <div className="section-container">
                    <ElementDownload
                      fileName={elem.Description}
                      DocumentLink={elem.DocumentLink}
                      DocumentType={elem.Description}
                      IconVariant="Medium"
                    >
                      <Paragraph ml={"5px"} hasMargin={false}>{`Λήψη ${
                        elem.DocumentType === "83"
                          ? t("hr.premium-account")
                          : elem.DocumentType === "84"
                            ? t("hr.breakdown.insurance-premium")
                            : elem.Description
                      }`}</Paragraph>{" "}
                    </ElementDownload>
                  </div>
                );
              })
            ) : (
              <Paragraph hasMargin={false}>
                {t("hr.premium-documents-issued-from")}
              </Paragraph>
            )}
          </div>
        }
        onClose={() => {
          setShowModal(false);
        }}
        width="350px"
        height="auto"
        headingText={t("claims.download-document")}
      />
    </div>
  );
};
