import address from "../assets/address.png";
import archive from "../assets/archive.png";
import attention_icon from "../assets/attention-icon.png";
import big_arrow_left from "../assets/big-arrow-left.png";
import big_arrow_right from "../assets/big-arrow-right.png";
import calendar from "../assets/calendar.png";
import check from "../assets/check.png";
import checkbox from "../assets/checkbox.png";
import checkbox_selected from "../assets/checkbox-selected.png";
import chevron_down from "../assets/chevron_down.png";
import chevron_down_2 from "../assets/chevron_down_2.png";
import chevron_right from "../assets/chevron_right.png";
import chevron_up from "../assets/chevron_up.png";
import close_square_button from "../assets/close-square-button.png";
import dot from "../assets/dot.png";
import download from "../assets/download.png";
import download_small from "../assets/download_small.png";
import email from "../assets/email.png";
import filter_square_button from "../assets/filter-square-button.png";
import goback from "../assets/goback.png";
import group from "../assets/group.png";
import logo_NN from "../assets/logo_NN.png";
import logout from "../assets/logoutNew.png";
import pagination_left from "../assets/pagination-left.png";
import pagination_right from "../assets/pagination-right.png";
import phone from "../assets/phone.png";
import profile_icon from "../assets/profile_icon.png";
import search_icon from "../assets/search_icon.png";
import undo_icon from "../assets/undo-icon.png";
import hover_left from "../assets/hover_left.png";
import hover_right from "../assets/hover_right.png";
import hover_left_small from "../assets/hover_left_small.png";
import hover_right_small from "../assets/hover_right_small.png";
import money_pay from "../assets/money_pay.png";
import placeholder from "../assets/promo-placeholder-large.png";
import registration_image from "../assets/registration_image.png";
import failure from "../assets/failure.png";
import authorisation from "../assets/authorisation.png";
import signin_pic from "../assets/signin_pic.png";
import call_icon from "../assets/call_icon.png";
import callback_icon from "../assets/callback_icon.png";
import chat_icon from "../assets/chat_icon.png";
import fb_icon from "../assets/fb_icon.png";
import ln_icon from "../assets/ln_icon.png";
import youtube_icon from "../assets/youtube_icon.png";
import yrs_icon from "../assets/yrs_icon.png";
import comingsoon from "../assets/comingsoon.png";
import mail_icon_header from "../assets/mail_icon_header.png";
import unreg_mainphoto from "../assets/hero_banner_unregistered_download.png";
import download2 from "../assets/download2.png";
import ypodeigma_myNN from "../assets/Ypodeigma_myNN.jpg";
import search_vis from "../assets/search_vis.png";
import online_payments from "../assets/online_payments.png";
import alldone_icon_payments from "../assets/alldone_icon_payments.png";
import visa_icon from "../assets/PaymentIcons/visasecure_60.jpg";
import master_icon from "../assets/PaymentIcons/mastercard_60.jpg";
import myNN_login_photo from "../assets/myNN_login_photo.jpg";
import manualRegistration from "../assets/manual_registration.pdf";
import solid_external_link_alt from "../assets/solid_external_link_alt.png";
import medicalVisit from "../assets/Claims/medicalVisit.png";
import diagnosticExamination from "../assets/Claims/diagnosticExamination.png";
import pharmacies from "../assets/Claims/pharmacies.png";
import physicalTherapy from "../assets/Claims/physicalTherapy.png";
import hospitalAllowance from "../assets/Claims/hospitalAllowance.png";
import surgicalAllowance from "../assets/Claims/surgicalAllowance.png";
import maternityAllowance from "../assets/Claims/maternityAllowance.png";
import outOfHospitalMedicalProcedures from "../assets/Claims/outOfHospitalMedicalProcedures.png";
import male_gender from "../assets/male_gender.svg";
import female_gender from "../assets/female_gender.svg";
import excel_icon from "../assets/excel_icon.svg";
import document_icon from "../assets/document_icon.svg";

const images = {
  myNN_login_photo,
  // address,
  // archive,
  attention_icon,
  manualRegistration,
  big_arrow_left,
  big_arrow_right,
  // calendar,
  check,
  checkbox,
  checkbox_selected,
  // chevron_down,
  // chevron_down_2,
  // chevron_right,
  // chevron_up,
  close_square_button,
  dot,
  download,
  download_small,
  email,
  filter_square_button,
  goback,
  group,
  logo_NN,
  logout,
  pagination_left,
  pagination_right,
  phone,
  // profile_icon,
  // search_icon,
  undo_icon,
  hover_left,
  hover_right,
  hover_left_small,
  hover_right_small,
  money_pay,
  placeholder,
  registration_image,
  failure,
  authorisation,
  signin_pic,
  call_icon,
  callback_icon,
  chat_icon,
  fb_icon,
  ln_icon,
  youtube_icon,
  yrs_icon,
  comingsoon,
  mail_icon_header,
  unreg_mainphoto,
  download2,
  ypodeigma_myNN,
  search_vis,
  online_payments,
  alldone_icon_payments,
  visa_icon,
  master_icon,
  solid_external_link_alt,
  medicalVisit,
  diagnosticExamination,
  pharmacies,
  physicalTherapy,
  hospitalAllowance,
  surgicalAllowance,
  maternityAllowance,
  outOfHospitalMedicalProcedures,
  male_gender,
  female_gender,
  excel_icon,
  document_icon,
};
export default images;
