import React, { useEffect, useState } from "react";
import PortfolioPerFundChart from "./PortfolioPerFundChart";
import {
  Tabs,
  Paragraph,
  SpinnerLoader,
} from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import agent from "../../Api/agent";

const PortFolioPerFundDetailPanel = ({ fundName, data }) => {
  const [getFundsState, setGetFundsState] = useState("");
  const [fundPrices, setFundPrices] = useState(null);

  useEffect(() => {
    const isDSC = ["DSC1", "DSC2"].includes(data.FundCode);

    if (isDSC) {
      setFundPrices([]);
      return;
    }

    setGetFundsState("loading");

    const fundsInvestmentsPrices = agent.portfolio.getFundPrices(data.FundCode);
    fundsInvestmentsPrices
      .then((result) => {
        setGetFundsState("success");
        setFundPrices([...result.FundPriceList]);
      })
      .catch(() => {
        setGetFundsState("error");
        setFundPrices([]);
      });
  }, [fundName]);

  const getTabs = () => {
    let tabs = [
      {
        contentElement: (
          <PortfolioPerFundChart
            fundName={fundName}
            fundPrices={fundPrices}
            dateRange={"week"}
          />
        ),
        tabText: "1w",
      },
      {
        contentElement: (
          <PortfolioPerFundChart
            fundName={fundName}
            fundPrices={fundPrices}
            dateRange={"month"}
          />
        ),
        tabText: "1m",
      },
      {
        contentElement: (
          <PortfolioPerFundChart
            fundName={fundName}
            fundPrices={fundPrices}
            dateRange={"6months"}
          />
        ),
        tabText: "6m",
      },
      {
        contentElement: (
          <PortfolioPerFundChart
            fundName={fundName}
            fundPrices={fundPrices}
            dateRange={"year"}
          />
        ),
        tabText: "1y",
      },
      {
        contentElement: (
          <PortfolioPerFundChart
            fundName={fundName}
            fundPrices={fundPrices}
            dataRange={"all"}
          />
        ),
        tabText: "all",
      },
    ];
    return tabs;
  };

  return (
    <div style={{ display: "flex", marginLeft: 70 }}>
      {fundPrices?.length > 0 && <Tabs centered tabs={getTabs()} />}
      {getFundsState === "error" && (
        <Paragraph>Η υπηρεσία προσωρινά δεν είναι διαθέσιμη.</Paragraph>
      )}
      {getFundsState === "loading" && (
        <div style={{ display: "flex", marginTop: 40 }}>
          <SpinnerLoader />
        </div>
      )}
    </div>
  );
};

export default observer(PortFolioPerFundDetailPanel);
