import React, { useContext } from "react";
import NavBarStore from "../../Stores/NavBarStore";
import ContractStore from "../../Stores/ContractStore";
import LogoLogin from "./LogoLogin";
import LoginInfo from "./LoginInfo";
import "../../Css/dashboard-header.css";
import { Icon } from "@nn-design-system/react-component-library";

const HeaderComponents = (props) => {
  const navBarStore = useContext(NavBarStore);
  const { hasAtLeastOneGroupPolicy } = useContext(ContractStore);

  const openNav = () => {
    navBarStore.setShowSideBar(false);
  };
  return (
    <div className="logo-login">
      <div className="container-logo-title-icons">
        <LogoLogin />
        {props.showMobileMenu ? (
          <span className={`side-menu-icon normal item ${hasAtLeastOneGroupPolicy ? "group-policies-exist" : ""}`} onClick={openNav}>
            <Icon variant={"Large"} name={"Hamburger"} color="#EA650D" />
          </span>
        ) : (
          <LoginInfo />
        )}
      </div>
    </div>
  );
};
export default HeaderComponents;
