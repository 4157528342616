import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "@nn-design-system/react-component-library";

import img from "../../assets/index";

const OnlinePaymentPromo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="main1">
        <h1>
          <span className="light">{t("fragment.pay")} </span>
          <span className="normal">{t("fragment.your-insurance")} </span>
          <span className="dark">{t("fragment.online")}</span>
        </h1>
      </div>

      <div className="main">
        <div className="image-pay">
          <img src={img.money_pay} alt="money_pay" />
        </div>
        <div className="text-buttons">
          <p>
            <Trans i18nKey="payments.promo">
              Εξοφλήστε online το ασφαλιστήριό σας εύκολα, γρήγορα και με
              ασφάλεια!
              <br />
              Έχετε τη δυνατότητα να εξοφλήσετε online το ασφαλιστήριό σας με
              χρεωστική
              <br />ή πιστωτική κάρτα ή μέσω e-banking.
            </Trans>
          </p>

          <div className="main2">
            <div className="master-visa">
              <img src={img.visa_icon} alt="" />
              <img src={img.master_icon} alt="" />
            </div>
            <Button
              mt={"20px"}
              variant="PrimarySmall"
              onClick={() => navigate("/online-payment")}
            >
              {t("global.online-payment")}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OnlinePaymentPromo;
