import React, { useContext, useEffect, Fragment, useState } from "react";
import { observer } from "mobx-react-lite";
import LoaderStore from "../Stores/LoaderStore";
import UrlStore from "../Stores/UrlStore";
import NavBarStore from "../Stores/NavBarStore";
import { useLocation } from "react-router-dom";

const HeaderImage = (props) => {
  const loaderStore = useContext(LoaderStore);
  const layoutStore = useContext(NavBarStore);
  const urlStore = useContext(UrlStore);
  const { headerThemeByUrl } = layoutStore;
  const { baseURL } = urlStore;
  const [obj, setObj] = useState({});
  const [showImage, setShowImage] = useState(true);
  const path = useLocation();

  useEffect(() => {
    if (baseURL.length === 0) {
      urlStore.loadURL(process.env.REACT_APP_ENVIRONMENT);
    }
  }, [baseURL]);

  useEffect(() => {
    if (headerThemeByUrl) {
      setObj(headerThemeByUrl);
    }
  }, [headerThemeByUrl]);

  useEffect(() => {
    if (path.pathname.includes("/preview/") && props.data) {
      setObj(props.data);
    }
  }, [path, props]);

  useEffect(() => {
    if (
      !path.pathname.includes("/preview/") &&
      !path.pathname.includes("/myClaims/submission")
    ) {
      if (path.pathname.includes("details")) {
        var ret = path.pathname.replace("/details", "");
        layoutStore.loadHeaderByUrl(ret);
      } else {
        layoutStore.loadHeaderByUrl(path.pathname);
      }
      if (
        path.pathname.includes("knowledgepoint/") ||
        (path.pathname.includes("/details") &&
          !path.pathname.includes("/myInbox"))
      ) {
        setShowImage(false);
      } else {
        setShowImage(true);
      }
    }
  }, [layoutStore, path]);

  let img = document.createElement("img");

  useEffect(() => {
    if (Object.keys(obj).length === 0 && obj?.HeaderImage?.Path) {
      if (!path.pathname.includes("myDocuments")) {
        loaderStore.loaderAction(true);
      }
    }
    if (Object.keys(obj).length !== 0 && obj?.HeaderImage?.Path) {
      img.src = `${baseURL}${obj.HeaderImage.Path}`;
      img.onload = () => {
        loaderStore.loaderAction(false);
      };
    }
  }, [img, path, obj]);

  return (
    <Fragment>
      {Object.keys(obj).length === 0 ? null : (
        <Fragment>
          <hr className="line" />
          {showImage && obj.HeaderImage.Path ? (
            <div className="hero-banner">
              <img
                width={1280}
                height={360}
                src={`${baseURL}${obj.HeaderImage.Path}`}
                alt="hero-banner"
              />
            </div>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
};

export default observer(HeaderImage);
