import React from "react";
import { observer } from "mobx-react-lite";
import BasicSubmissionInfo from "./BasicSubmissionInfo";

const Step1 = (props) => {
  return (
    <div className={props.isVisible ? "" : "doNotDisplay"}>
      <BasicSubmissionInfo
        previousStep={props.previousStep}
        nextStep={props.nextStep}
      />
    </div>
  );
};

export default observer(Step1);
