import React, { Fragment, useContext, useEffect } from "react";
import ContractsToggleComp from "./ContractsToggleComp";
import PageTheme from "../PageTheme";
import LoaderStore from "../../Stores/LoaderStore";
import ContractStore from "../../Stores/ContractStore";
import AccountStore from "../../Stores/AccountStore";
import CookieStore from "../../Stores/CookieStore";

const MyContracts = () => {
  const loaderStore = useContext(LoaderStore);
  const { contractsHasContent } = useContext(ContractStore);
  const { accountHasContent } = useContext(AccountStore);
  const { cookieHasContent } = useContext(CookieStore);

  useEffect(() => {
    if (contractsHasContent && accountHasContent && cookieHasContent) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
  }, [contractsHasContent, accountHasContent, cookieHasContent]);

  return (
    <Fragment>
      <PageTheme />
      <ContractsToggleComp />
    </Fragment>
  );
};
export default MyContracts;
