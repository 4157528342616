import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class PorfolioStore {
  showPorfolio = false;
  fundPrices = undefined;
  userPorfolio = {};

  constructor() {
    makeObservable(this, {
      showPorfolio: observable,
      userPorfolio: observable,
      fundPrices: observable,

      loadPorfolio: action,
      loadFundPrices: action,
    });
  }
  loadPorfolio = () => {
    const porfolio = agent.portfolio.getUserPortfolio();
    porfolio
      .then((result) => {
        this.userPorfolio = { ...result };
        this.showPorfolio = true;
      })
      .catch((error) => {
        this.userPorfolio = [];
        this.showPorfolio = true;
        console.log(error, "error in documentsHR");
      });
  };

  loadFundPrices = (fundCode) => {
    const prices = agent.portfolio.getFundPrices(fundCode);
    prices
      .then((result) => {
        this.fundPrices = [...result.FundPriceList];
      })
      .catch((error) => {});
  };
}
export default createContext(new PorfolioStore());
