import React, { useState } from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { PaymentsDetailPanelTableHR } from "./PaymentsDetailPanelTableHR";
export const PaymentsTableHR = ({ element = [] }) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const columns = [
    {
      field: "ContractNumber",
      headerName: t("hr.contract"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
    },
    {
      field: "CompanyName",
      headerName: t("hr.recipient-company-name"),
      headerAlign: "left",
      align: "left",
      flex: 2,
      sortable: false,
    },
  ];
  const rows = element?.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      testId="payments-hr-table"
      mt="30px"
      hasPagination
      hasFooterPagination
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hasAutoHeight
      itemsPerPageProps={{
        itemsPerPage: [
          {
            text: "5",
            value: 5,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "25",
            value: 25,
          },
          {
            text: "50",
            value: 50,
          },
        ],
        name: "itemsPerPage",
        onChange: (test) => {
          setItemsPerPage(test);
        },
        value: itemsPerPage,
      }}
      page={pageNumber}
      pageNavigationProps={{
        name: "pageNavigation",
        onChange: (value) => {
          setPageNumber(value);
        },
        value: pageNumber,
      }}
      pageSize={itemsPerPage}
      getDetailPanelContent={(rowData) => (
        <div className="payments-hr-table">
          <PaymentsDetailPanelTableHR
            contract={rowData.row.ContractNumber}
            element={rowData.row.Invoices}
          />
        </div>
      )}
      getDetailPanelHeight={() => {
        return "auto";
      }}
    ></Table>
  );
};
