import { Checkbox as NNCheckbox } from "@nn-design-system/react-component-library";
import React, {  useState,useContext } from "react";
import "../../Css/inbox-filter.css";
import CookieStore from "../../Stores/CookieStore";

const CheckBoxActive = (props) => {
  const [checkBox, setcheckBox] = useState("Checked");
  const [check, setCheck] = useState(true);
  
  const cookieStore = useContext(CookieStore);

  const listen = (value, checkboxId) => {
    if (props.data[1] == "normal" && props.data[0]["isChecked"] != value) {
      props.data[0].setIsChecked(value);
    }

    if (props.data[1] == "global" && props.data[0]["consentisChecked"] != value) {
      props.data[0].setconsentIsChecked(value);
    }

    if (checkboxId === "CheckBoxSMS" ) {
        cookieStore.setCheckboxStateSMS(value);
     
    }

    if (checkboxId  === "CheckBoxCall" ) {
      cookieStore.setCheckboxStateCall(value);
    }

    if (checkboxId === "CheckBoxEmail") {
        cookieStore.setCheckboxStateEmail(value);
    }

    if (props.data[1] == "CheckBoxNone" && props.data[0]["checkBoxStateNone"] != value) {
      props.data[0].setCheckboxStateNone(value);
    }

    //checkbox for somethg
    if (checkboxId === "Documents") {
      props.type.setDocumentsFilter(value);
    }
    if (checkboxId === "Payments") {
      props.type.setPaymentsFilter(value);
    }
    if (checkboxId === "ImportantUpdates") {
      props.type.setImportantUpdatesFilter(value);
    }
    if (checkboxId === "eServices") {
      props.type.setEServicesFilter(value);
    }
    if (checkboxId === "OtherUpdates") {
      props.type.setOtherUpdatesFilter(value);
    }
    if (checkboxId === "Personalized") {
      props.type.setPersonalizedFilter(value);
    }

    value ? setcheckBox("Checked") : setcheckBox("");
    setCheck(value);
  };

  return (
    <NNCheckbox
      dataState={checkBox}
      variant={props.variant ? props.variant : "Small"}
      labelText={props.innerText}
      name="name"
      onChange={() => listen(!check, props.data)}
    />
  );
};

export default CheckBoxActive;
