import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class ChatStore {
  chatData = false;
  callMeBackResponse = null;

  constructor() {
    makeObservable(this, {
      chatData: observable,
      callMeBackResponse: observable,

      callMeBack: action,
    });
  }

  callMeBack = async (val) => {
    this.callMeBackResponse = null;
    let response = await agent.chat.request(val);
    this.callMeBackResponse = { ...response };
  };
}
export default createContext(new ChatStore());
