import React, {
  Fragment,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTheme from "../PageTheme";
import { observer } from "mobx-react-lite";
import ClaimsTableNN from "./ClaimsTableNN";
import SpinnerSmall2 from "../CommonComp/SpinnerSmall2";
import ClaimsListMobileView from "./ClaimsListMobileView";
import ContractStore from "../../Stores/ContractStore";
import ClaimsStore from "../../Stores/ClaimsStore";
import ClaimSubmissionWizard from "./ClaimSubmissionWizard/ClaimSubmissionWizard";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";
import SettingsStore from "../../Stores/SettingsStore";
import { Label } from "@nn-design-system/react-component-library";
import LoaderStore from "../../Stores/LoaderStore";

const MyClaims = () => {
  const contractStore = useContext(ContractStore);
  const claimsStore = useContext(ClaimsStore);
  const loaderStore = useContext(LoaderStore);
  const { getResourceStringValue } = useContext(SettingsStore);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [showClaimsSumbission, setShowClaimsSumbission] = useState(null);
  const [claimListSorted, setClaimListSorted] = useState(null);
  const path = useLocation();

  const { hasAtLeastOneGroupPolicy } = contractStore;
  const { claimsHasContent, claims } = claimsStore;

  useEffect(() => {
    if (claimsHasContent) {
      loaderStore.setMiniLoaderForData2(false);
    } else {
      loaderStore.setMiniLoaderForData2(true);
    }
  }, [claimsHasContent, loaderStore]);

  useEffect(() => {
    if (Object.keys(claims).length) {
      let sortedData = claims?.ClaimList.sort((a, b) =>
        b.AnnouncementDate > a.AnnouncementDate ? 1 : -1,
      );
      setClaimListSorted([...sortedData]);
    }
  }, [claims]);

  useEffect(() => {
    if (path.pathname.includes("submission")) {
      setShowClaimsSumbission(true);
    } else {
      setShowClaimsSumbission(false);
    }
  }, [path.pathname]);

  useEffect(() => {
    if (!hasAtLeastOneGroupPolicy) {
      navigate(`/home`);
    }
  }, [hasAtLeastOneGroupPolicy, navigate]);

  const isMobileView = useMemo(() => {
    return width <= 393;
  }, [width]);

  const renderClaimsListComponent = useCallback(() => {
    if (isMobileView) {
      return <ClaimsListMobileView claimsList={claimListSorted} />;
    }
    return <ClaimsTableNN claimsList={claimListSorted} />;
  }, [isMobileView, claimListSorted]);

  return (
    <Fragment>
      {showClaimsSumbission === true ? (
        <ClaimSubmissionWizard />
      ) : (
        <>
          <PageTheme />
          {!claimsHasContent ? (
            <div
              style={{ height: 65 }}
              className="container miniLoaderParentRelative"
            >
              <SpinnerSmall2 />
            </div>
          ) : claimListSorted?.length > 0 ? (
            renderClaimsListComponent()
          ) : (
            <div className="nn-container">
              <Label
                variant="SubLabel"
                text={getResourceStringValue(
                  "NN.Claim.MyClaimsListNoRecordsFound",
                )}
              />
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};

export default observer(MyClaims);
