import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { StepIndicator } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import SubmissionModal from "./SubmissionModal";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import "../../../Css/Claims/Claims.css";
import ClaimsStore from "../../../Stores/ClaimsStore";
import UrlStore from "../../../Stores/UrlStore";
import SignalRStore from "../../../Stores/SignalRStore";
import { ClaimState } from "../../../Consts/Claims";

const ClaimSubmissionWizard = () => {
  const { t } = useTranslation();
  const claimsStore = useContext(ClaimsStore);
  const {
    submitClaim,
    claimState,
    isConnectingToSignalR,
    setIsConnectingToSignalR,
    handleNewStatus,
    handleClaimSubmissionTimeout,
  } = claimsStore;
  const signalRStore = useContext(SignalRStore);
  const { baseURL } = useContext(UrlStore);
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const navigate = useNavigate();
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);

  const claimFormSubmission = useCallback(() => {
    if (claimState === ClaimState.notSubmitted && !isConnectingToSignalR) {
      setIsConnectingToSignalR(true);

      const claimSubmissionStateHub = signalRStore.createHubProxy("claimHub");

      claimSubmissionStateHub.on("handleSubmissionResponse", function (status) {
        handleNewStatus(status);
      });

      // atempt connection, and handle errors
      signalRStore
        .startHubConnection()
        .done(() => {
          const hubConnectionId = signalRStore.getHubConnectionId();
          submitClaim(hubConnectionId);
        })
        .fail(() => {
          submitClaim(null);
        })
        .always(() => {
          setIsConnectingToSignalR(false);
        });
    }
  }, [
    claimState,
    isConnectingToSignalR,
    setIsConnectingToSignalR,
    signalRStore,
    handleNewStatus,
    submitClaim,
  ]);

  const nextStep = useCallback(() => {
    setCurrentStepNumber(currentStepNumber + 1);
  }, [currentStepNumber, setCurrentStepNumber]);

  const previousStep = useCallback(() => {
    if (currentStepNumber === 1) {
      navigate("/myClaims");
    } else {
      setCurrentStepNumber(currentStepNumber - 1);
    }
  }, [currentStepNumber, navigate, setCurrentStepNumber]);

  useEffect(() => {
    if (claimState !== ClaimState.notSubmitted) {
      setShowSubmissionModal(true);
      if (claimState === ClaimState.processing) {
        setTimeout(() => {
          handleClaimSubmissionTimeout();
        }, 60000);
      } else if (
        claimState === ClaimState.failed ||
        claimState === ClaimState.successful ||
        claimState === ClaimState.inProgress ||
        claimState === ClaimState.timedOut
      ) {
        signalRStore.stopHubConnection();
        claimsStore.getMyClaims();
      }
    }
  }, [claimState, claimsStore, handleClaimSubmissionTimeout, signalRStore]);

  useEffect(() => {
    signalRStore.createHubConnection(baseURL);

    return () => {
      claimsStore.clearBasicSubmissionInfoToSubmit();
      claimsStore.clearClaimsListToSubmit();
      claimsStore.clearSelectedClaimToEdit();
      signalRStore.stopHubConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`nn-container ${styles.claimSubmissionWizardContainer}`}>
      <SubmissionModal
        handleClose={() => {
          setShowSubmissionModal(false);
          navigate("/myClaims");
        }}
        showSubmissionModal={showSubmissionModal}
      />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          claimFormSubmission(event);
        }}
      >
        <div className={styles.stepIndicatorAlignWrapper}>
          <div className={styles.stepIndicatorContainer}>
            <StepIndicator
              currentStepNumber={currentStepNumber}
              variant="WithLabels"
              mb="30px"
              withLabelsVariantProps={{
                steps: [
                  {
                    labelText: t("claim.submission.stepIndicator-registration"),
                  },
                  {
                    labelText: t("claim.submission.expense-registration"),
                  },
                  {
                    labelText: t("claim.submission.expense-clearance"),
                  },
                ],
              }}
            />
          </div>
        </div>
        <Step1
          isVisible={currentStepNumber === 1 ? true : false}
          previousStep={() => {
            previousStep();
          }}
          nextStep={() => {
            nextStep();
          }}
        />
        <Step2
          isVisible={currentStepNumber === 2 ? true : false}
          previousStep={() => {
            previousStep();
          }}
          nextStep={() => {
            nextStep();
          }}
        />
        <Step3
          isVisible={currentStepNumber === 3 ? true : false}
          previousStep={() => {
            previousStep();
          }}
          nextStep={() => {
            nextStep();
          }}
        />
      </form>
    </div>
  );
};

export default observer(ClaimSubmissionWizard);
