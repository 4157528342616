import React from "react";
import ReactDOM from "react-dom/client";
import "mobx-react-lite/batchingForReactDom";
import "./i18n";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

async function enableMocking() {
  const mocksEnabled = process.env.REACT_APP_MOCKS_ENABLED === "1";
  const isLocalEnv = process.env.REACT_APP_ENVIRONMENT === "local";

  if (!mocksEnabled || !isLocalEnv) {
    return;
  }

  const { worker } = await import("./mocks/browser");

  return worker.start();
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
