import React, { Fragment, useContext, useEffect } from "react";
import ArticleToggleComp from "./ArticleToggleComp";
import PageTheme from "../PageTheme";
import ArticleStore from "../../Stores/ArticleStore";
import LoaderStore from "../../Stores/LoaderStore";
import AccountStore from "../../Stores/AccountStore";
import CookieStore from "../../Stores/CookieStore";

const Articles = () => {
  const loaderStore = useContext(LoaderStore);
  const { articleHasContent } = useContext(ArticleStore);
  const { accountHasContent } = useContext(AccountStore);
  const { cookieHasContent } = useContext(CookieStore);

  useEffect(() => {
    if (articleHasContent && cookieHasContent && accountHasContent) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
  }, [articleHasContent, cookieHasContent, accountHasContent]);

  return (
    <Fragment>
      <PageTheme />
      <ArticleToggleComp />
    </Fragment>
  );
};
export default Articles;
