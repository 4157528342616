import React from "react";
import { ConvertDecimalToStringWithCurrencyMaxFractionDigits } from "../CommonComp/Functions";

export const PortfolioPerFundDetailPanelRows = ({ portfolio }) => {
  return (
    <>
      {portfolio?.map((e, i) => (
        <div
          key={i}
          role="row"
          className="MuiDataGrid-row"
          style={{ display: "flex" }}
        >
          <div
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
            style={{ minWidth: 60, maxWidth: 60 }}
            role="cell"
          ></div>
          <div
            role="cell"
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
            style={{
              minWidth: 217,
              maxWidth: 217,

              whiteSpace: "normal",
            }}
          >
            {e.FundName}
          </div>
          <div
            role="cell"
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
            style={{
              minWidth: 110,
              maxWidth: 110,
            }}
          >
            {e.ContractNumber}
          </div>
          <div
            role="cell"
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
            style={{
              minWidth: 100,
              maxWidth: 100,
            }}
          >
            {ConvertDecimalToStringWithCurrencyMaxFractionDigits(
              Number(e.UnitSpecialPrice),
            )}
          </div>
          <div
            role="cell"
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
            style={{
              minWidth: 100,
              maxWidth: 100,
            }}
          >
            {e.NumberOfUnits?.toLocaleString("el-GR", {
              maximumFractionDigits: 6,
            })}
          </div>
          <div
            role="cell"
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
            style={{
              minWidth: 156,
              maxWidth: 156,
            }}
          >
            {ConvertDecimalToStringWithCurrencyMaxFractionDigits(
              Number(e.UnitPrice),
            )}
          </div>
          <div
            role="cell"
            className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
            style={{
              minWidth: 151,
              maxWidth: 151,
            }}
          >
            {e.FundPercentage.toLocaleString("el-GR", {
              maximumFractionDigits: 2,
            })}{" "}
            %
          </div>
        </div>
      ))}
    </>
  );
};
