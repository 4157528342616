import React, { useEffect, useState } from "react";
import {
  Paragraph,
  SpinnerLoader,
  Heading,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import agent from "../../../Api/agent";
import CopyText from "../../CommonComp/CopyText";
import ElementDownload from "../../Document/ElementDownload";

export const PaymentsSelectedDetailPanelHR = ({
  detail,
  contract,
  id,
  isGroupPensionPolicy,
  GroupPensionData,
  downloadData,
}) => {
  const { t } = useTranslation();
  const [policiesPayment, setPoliciesPayments] = useState(undefined);
  const [policiesPaymentHasResponse, setPoliciesPaymentHasResponse] =
    useState(false);

  useEffect(() => {
    const contractNumberToUse = !isGroupPensionPolicy
      ? contract
      : GroupPensionData.contract;
    const invoiceNumberToUse = !isGroupPensionPolicy
      ? contract
      : GroupPensionData.InvoiceNumber;

    if (!isGroupPensionPolicy) {
      const policiesPayment = agent.hr.getPolicycPayemntsHR({
        contractNumber: contractNumberToUse,
        invoiceNumber: invoiceNumberToUse,
        invoiceType: "Billing",
      });

      policiesPayment
        .then((result) => {
          setPoliciesPayments(result);
          setPoliciesPaymentHasResponse(true);
        })
        .catch((error) => {
          setPoliciesPayments([]);
          setPoliciesPaymentHasResponse(true);
          console.log(error, "error payments response");
        });
    }
  }, []);
  return (
    <>
      <article className="detail-panel-payments-selected-container">
        {policiesPaymentHasResponse ? (
          !!policiesPayment.length ? (
            <section>
              {policiesPayment.map((policie) => (
                <div className="section-container">
                  <ElementDownload
                    DocumentLink={policie.DocumentLink}
                    DocumentType={policie.Description}
                    IconVariant="Medium"
                  >
                    <Paragraph
                      ml={"5px"}
                      hasMargin={false}
                    >{`${t("document.download")} ${
                      policie.DocumentType === "83"
                        ? t("hr.premium-account")
                        : policie.DocumentType === "84"
                          ? t("hr.breakdown.insurance-premium")
                          : policie.Description
                    }`}</Paragraph>{" "}
                  </ElementDownload>
                </div>
              ))}
            </section>
          ) : (
            <div className="section-container">
              <Paragraph mt="20px" hasMargin={false}>
                {isGroupPensionPolicy
                  ? t("hr.final-payment-from")
                  : t("hr.premium-documents-issued-from")}
              </Paragraph>
            </div>
          )
        ) : isGroupPensionPolicy ? (
          <></>
        ) : (
          <SpinnerLoader variant="Medium" />
        )}
        {isGroupPensionPolicy && (
          <section style={{ display: "flex", alignItems: "center" }}>
            <Paragraph hasMargin={false}>{t("hr.payment-receipt")}</Paragraph>
            <ElementDownload
              fileName={downloadData.InvoiceNumber}
              DocumentLink={downloadData.DownloadUrl}
              method="POST"
              postData={downloadData.CertificateData}
            />
          </section>
        )}
        {!isGroupPensionPolicy && (
          <section>
            <Heading hasMargin={false} variant="Small">
              {t("hr.payment-codes")}
            </Heading>
            {detail.PaymentCodeA ? (
              <CopyText
                text={`A: ${detail.PaymentCodeA}`}
                selector={`PaymentCodeA${id}`}
              />
            ) : (
              <span>A: -</span>
            )}
            {detail.PaymentCodeB ? (
              <CopyText
                text={`B: ${detail.PaymentCodeB}`}
                selector={`PaymentCodeB${id}`}
              />
            ) : (
              <span>B: -</span>
            )}
          </section>
        )}
      </article>
    </>
  );
};
