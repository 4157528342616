import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import stylesClaims from "../../../Css/Claims/Claims.module.scss";
import {
  Button,
  Table,
  Icon,
  Paragraph,
  Heading,
} from "@nn-design-system/react-component-library";
import { ClaimState, ExpenseType } from "../../../Consts/Claims";
import ClaimsStore from "../../../Stores/ClaimsStore";
import { ConvertDecimalToString } from "../../CommonComp/Functions";
import img from "../../../assets/index";

const ClaimsListToSubmit = (props) => {
  const { t } = useTranslation();
  const claimsStore = useContext(ClaimsStore);
  const { claimsListToSubmit, claimState, isConnectingToSignalR } = claimsStore;

  const mountAndUnMountOfComponent = useCallback(() => {
    claimsStore.clearSelectedClaimToEdit();
    return () => {};
    // No dependency for the useEffect to act as mount and unmount event of component
    // suppress any linting warnings
    // eslint-disable-next-line
  }, []);

  const renderExpenseTypeImg = useCallback((expenseTypeValue) => {
    switch (expenseTypeValue) {
      case ExpenseType.MedicalVisit:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img
              src={img.medicalVisit}
              alt="Ιατρική επίσκεψη"
              style={{ width: "30px" }}
            />
          </div>
        );
      case ExpenseType.DiagnosticExamination:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img
              src={img.diagnosticExamination}
              alt="Διαγνωστική εξέταση"
              style={{ width: "69px" }}
            />
          </div>
        );
      case ExpenseType.Pharmacies:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img src={img.pharmacies} alt="Φάρμακα" style={{ width: "69px" }} />
          </div>
        );
      case ExpenseType.PhysicalTherapy:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img
              src={img.physicalTherapy}
              alt="Φυσικοθεραπεία"
              style={{ width: "69px" }}
            />
          </div>
        );
      case ExpenseType.HospitalAllowance:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img
              src={img.hospitalAllowance}
              alt="Νοσοκομειακό Επίδομα"
              style={{ width: "69px" }}
            />
          </div>
        );
      case ExpenseType.SurgicalAllowance:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img
              src={img.surgicalAllowance}
              alt="Χειρουργικό Επίδομα"
              style={{ width: "69px" }}
            />
          </div>
        );
      case ExpenseType.NormalMaternityAllowance:
      case ExpenseType.CaesareanMaternityAllowance:
      case ExpenseType.MiscarriageMaternityAllowance:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img
              src={img.maternityAllowance}
              alt="Επίδομα Μητρότητας"
              style={{ width: "69px" }}
            />
          </div>
        );
      case ExpenseType.OutOfHospitalMedicalProcedures:
        return (
          <div className={styles.expenseTypeImgInList}>
            <img
              src={img.outOfHospitalMedicalProcedures}
              alt="Ιατρικές πράξεις εκτός νοσοκομείου"
              style={{ width: "69px" }}
            />
          </div>
        );
      default:
        return null;
    }
  }, []);

  const handleSelectClaimToEdit = useCallback(
    (selectedClaim) => {
      claimsStore.setSelectedClaimToEdit(selectedClaim);
      props.previousStep();
    },
    [claimsStore, props],
  );

  const claimsTotalAmount = useMemo(() => {
    return claimsListToSubmit.reduce(
      (sum, claim) => sum + +claim.AmountList,
      0,
    );
  }, [claimsListToSubmit]);

  const claimsDocumentsCount = useMemo(() => {
    return claimsListToSubmit.reduce(
      (sum, claim) => sum + claim.Files.length,
      0,
    );
  }, [claimsListToSubmit]);

  useEffect(() => {
    mountAndUnMountOfComponent();
  }, [mountAndUnMountOfComponent]);

  return (
    <div>
      <div>
        <Heading mb="20px" isFirstSection>
          {t("claim.clearing-request")}
        </Heading>
        {claimsListToSubmit?.length > 0 && (
          <div className={`${styles.claimsTotal}`}>
            <div className={`${styles.claimsTotalAmount}`}>
              <Heading variant="Small" mr="5px" isFirstSection>
                {t("global.total-amount")}
              </Heading>
              <Heading variant="Small" isFirstSection>
                {ConvertDecimalToString(claimsTotalAmount)}
              </Heading>
            </div>
            <div className={`${styles.claimsDocumentsCount}`}>
              <Heading variant="Small" mr="5px" isFirstSection>
                {t("claim.set-documents")}
              </Heading>
              <Heading variant="Small" isFirstSection>
                {claimsDocumentsCount}
              </Heading>
            </div>
          </div>
        )}
      </div>
      {claimsListToSubmit?.length > 0 ? (
        <Table
          localeText={{ noRowsLabel: t("global.no-results-found") }}
          density="comfortable"
          columns={[
            {
              align: "left",
              field: "ExpenseType",
              flex: 1,
              headerAlign: "left",
              headerClassName: "textAlignLeft",
              headerName: t("claim.table.header.output-type"),
              sortable: false,
              minWidth: 100,
              renderCell: (props) => {
                return renderExpenseTypeImg(props.value);
              },
            },
            {
              align: "left",
              field: "MainInsuredMemberList",
              flex: 1,
              headerAlign: "left",
              headerClassName: "textAlignLeft",
              cellClassName: () => {
                return stylesClaims.insuredName;
              },
              headerName: t("claim.table.header.insured-member"),
              minWidth: 130,
              sortable: false,
            },
            {
              align: "left",
              field: "ReceiptDateList",
              flex: 1,
              headerAlign: "left",
              headerClassName: "textAlignLeft",
              headerName: t("claim.table.header.receipt-invoice-date"),
              minWidth: 130,
              sortable: false,
            },
            {
              align: "left",
              field: "CollaboratingNetworkList",
              flex: 1,
              headerAlign: "left",
              headerClassName: "textAlignLeft",
              headerName: t("claim.table.header.affiliate-network"),
              sortable: false,
              minWidth: 130,
              valueFormatter: (props) => {
                return props.value ? "Ναι" : "-";
              },
            },
            {
              align: "left",
              field: "AmountList",
              flex: 1,
              headerAlign: "left",
              headerClassName: "textAlignLeft",
              headerName: t("claim.table.header.amount-requested"),
              sortable: false,
              minWidth: 130,
              valueFormatter: (props) => {
                return ConvertDecimalToString(parseFloat(props.value));
              },
            },
            {
              align: "left",
              field: "OtherEntityContributedList",
              flex: 1,
              headerAlign: "left",
              headerClassName: "textAlignLeft",
              headerName: t("claim.table.header.other-participation"),
              sortable: false,
              minWidth: 130,
              valueFormatter: (props) => {
                return props.value ? "Ναι" : "Όχι";
              },
            },
            {
              align: "left",
              field: "ActionsList",
              flex: 1,
              headerAlign: "left",
              headerClassName: "textAlignLeft",
              headerName: t("global.total-actions"),
              sortable: false,
              minWidth: 130,
              renderCell: (props) => {
                return (
                  <div className={styles.actionsCell}>
                    <div
                      onClick={() => {
                        handleSelectClaimToEdit(props.row);
                      }}
                    >
                      <Icon
                        mr="20px"
                        name="Pencil"
                        variant="Small"
                        color="#EA650D"
                      />
                    </div>
                    <div
                      onClick={() => {
                        claimsStore.removeClaimFroSubmitingList(props.row.Id);
                      }}
                    >
                      <Icon name="Trashcan" variant="Small" color="#EA650D" />
                    </div>
                  </div>
                );
              },
            },
          ]}
          locale="elGR"
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          getRowId={(row) => row.Id}
          hasAutoHeight
          rows={claimsListToSubmit}
        />
      ) : (
        <Paragraph mb="20px">{t("claim.no-claims-file")}</Paragraph>
      )}
      <div className={styles.step3BtnsRow}>
        <div className={styles.previousStepBtn}>
          <div className={styles.newClaimBtnContainer}>
            <button
              onClick={() => {
                props.previousStep();
              }}
            >
              <Icon name="Plus" mr="5px" customSize="17px" />
              <span>{t("claim.register-new-output")}</span>
            </button>
          </div>
        </div>
        <div
          className={`${styles.nextStepBtn} ${styles.submitBtnContainer} ${
            claimState === ClaimState.notSubmitted && !isConnectingToSignalR
              ? ""
              : styles.disabledBtn
          }`}
        >
          <Button type="Submit">{t("global.submission")}</Button>
        </div>
      </div>
    </div>
  );
};

export default observer(ClaimsListToSubmit);
