import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";

class UrlStore {
  baseURL = [];

  constructor() {
    makeObservable(this, {
      baseURL: observable,

      loadURL: action,
    });
  }

  loadURL = (enviroment) => {
    let newURL = "NO URL";
    if (enviroment === "local") {
      newURL = process.env.REACT_APP_BASE_URL_LOCAL;
    }
    if (process.env.REACT_APP_ENVIRONMENT === "debug") {
      newURL = process.env.REACT_APP_BASE_URL_LOCAL_DEBUG;
    }
    if (enviroment === "dev") {
      newURL = process.env.REACT_APP_BASE_URL_DEV;
    }
    if (enviroment === "test") {
      newURL = process.env.REACT_APP_BASE_URL_TEST;
    }
    if (enviroment === "assesment") {
      newURL = process.env.REACT_APP_BASE_URL_ASSESMENT;
    }
    if (enviroment === "live") {
      newURL = process.env.REACT_APP_BASE_URL_LIVE;
    }
    if (enviroment === "uat") {
      newURL = process.env.REACT_APP_BASE_URL_UAT;
    }
    this.baseURL = newURL;
  };
}
export default createContext(new UrlStore());
