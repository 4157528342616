import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import ArticleCarousel from "../Articles/ArticleCarousel";
import Promos from "../Home/Promos";
import ContractStore from "../../Stores/ContractStore";
import "../../Css/Home/dashboard-body.css";
import LoaderStore from "../../Stores/LoaderStore";
import ArticleStore from "../../Stores/ArticleStore";
import AccountStore from "../../Stores/AccountStore";
import PaymentStore from "../../Stores/PaymentStore";
import CookieStore from "../../Stores/CookieStore";
import ContractListHR from "./Contracts/ContractListHR";
import HRStore from "../../Stores/HRStore";

const HomeHR = () => {
  const contractStore = useContext(ContractStore);
  const loaderStore = useContext(LoaderStore);
  const { promosList } = contractStore;
  const articleStore = useContext(ArticleStore);
  const { articleHasContent } = articleStore;
  const { accountHasContent } = useContext(AccountStore);
  const { cookieHasContent, isCustomerOfNNHellas } = useContext(CookieStore);
  const { contractsHasContent } = contractStore;
  const paymentStore = useContext(PaymentStore);
  const { paymentHasContent } = paymentStore;
  const { hrContractsList, hrContractsListHasResponse } = useContext(HRStore);
  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";

  useEffect(() => {
    if (
      ((contractsHasContent && paymentHasContent) ||
        (checkNN2Users && !isCustomerOfNNHellas)) &&
      articleHasContent &&
      accountHasContent &&
      cookieHasContent &&
      hrContractsListHasResponse
    ) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
  }, [
    contractsHasContent,
    paymentHasContent,
    articleHasContent,
    accountHasContent,
    cookieHasContent,
    loaderStore,
    isCustomerOfNNHellas,
    hrContractsListHasResponse,
  ]);

  return (
    <Fragment>
      <div id="dashboard-body">
        {!checkNN2Users ||
        (isCustomerOfNNHellas && hrContractsList.length > 0) ? (
          <Promos />
        ) : null}
        <div
          className={promosList.length > 1 ? "clear" : "clear single-slide"}
        ></div>
        <ContractListHR />
      </div>
      <div className="nn-container">
        <ArticleCarousel />
      </div>
    </Fragment>
  );
};
export default observer(HomeHR);
