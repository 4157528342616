import React, { useEffect, useState } from "react";
import { Switch } from "@nn-design-system/react-component-library";

const CookieSetting = ({ cookie, allState, id, state, setCookieTotal, cookieTotal, mandatory }) => {
  const [classState, setClassState] = useState(cookie.CookiePreference.State != 1 ? undefined : "Checked");

  const toggleSetting = (value) => {
    if (value != 1) {
      setClassState("Checked");
      cookie.CookiePreference.State = 1;
      setCookieTotal(cookieTotal + 1);
      state(1);
    } else {
      setClassState(undefined);
      cookie.CookiePreference.State = 2;

      setCookieTotal(cookieTotal - 1);
      state(2);
    }
  };

  useEffect(() => {
    if (allState === 2) {
      cookie.CookiePreference.State = 2;
      setClassState(undefined);
      setCookieTotal(0);
    }
    if (allState === 1) {
      cookie.CookiePreference.State = 1;
      setClassState("Checked");
      setCookieTotal(1);
    }
  }, [allState]);

  return (
    <>
      <div className="setting-row">
        <div
          className="setting-title"
          data-toggle="collapse"
          href={`#collapseExample-${id}`}
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          {cookie.Title}
        </div>
        <Switch
          dataState={mandatory ? "Checked" : classState}
          onChange={() => (mandatory ? null : toggleSetting(cookie.CookiePreference.State))}
        />
      </div>

      <div className="collapse" id={`collapseExample-${id}`}>
        <div className="card card-body">{cookie.Description}</div>
      </div>
    </>
  );
};

export default CookieSetting;
