import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import ArticleCarousel from "../Articles/ArticleCarousel";
import Promos from "./Promos";
import DashboardAlerts from "./DashboardAlerts";
import ContractStore from "../../Stores/ContractStore";
import "../../Css/Home/dashboard-body.css";
import WelcomePopUp from "../SpecialPages/WelcomePopUp";
import LoaderStore from "../../Stores/LoaderStore";
import ArticleStore from "../../Stores/ArticleStore";
import AccountStore from "../../Stores/AccountStore";
import PaymentStore from "../../Stores/PaymentStore";
import CookieStore from "../../Stores/CookieStore";
import ContractList from "./ContractList";
import OnlinePaymentPromo from "./OnlinePaymentPromo";
import EServices from "./EServices";
import TermPopup from "../SpecialPages/TermPopup";

import RoleSelection from "../HR/RoleSelection";
import HRStore from "../../Stores/HRStore";

const MyHome = () => {
  const contractStore = useContext(ContractStore);
  const loaderStore = useContext(LoaderStore);
  const { showOnlinePayment, promosList } = contractStore;
  const articleStore = useContext(ArticleStore);
  const { articleHasContent, articleList } = articleStore;
  const { accountHasContent, hasMultipleRole } = useContext(AccountStore);
  const { cookieHasContent, isCustomerOfNNHellas, isCustomerOfNNHellas2 } = useContext(CookieStore);
  const { contractList, contractsHasContent } = contractStore;
  const paymentStore = useContext(PaymentStore);
  const { paymentList, paymentHasContent } = paymentStore;
  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";
  const { isHrRole } = useContext(HRStore);

  useEffect(() => {
    if (
      ((contractsHasContent && paymentHasContent) || (checkNN2Users && !isCustomerOfNNHellas)) &&
      articleHasContent &&
      accountHasContent &&
      cookieHasContent
    ) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
  }, [contractsHasContent, paymentHasContent, articleHasContent, accountHasContent, cookieHasContent, loaderStore, isCustomerOfNNHellas]);

  return (
    <Fragment>
      {hasMultipleRole && isHrRole == null ? (
        <section className="nn-container">
          <RoleSelection />
        </section>
      ) : (
        <>
          <div id="dashboard-body">
            {loaderStore.finishedLoading ? (
              <>
                <WelcomePopUp />
                <TermPopup />
              </>
            ) : null}
            {!checkNN2Users || (isCustomerOfNNHellas && contractList.length > 0) ? <Promos /> : null}
            <div className={promosList.length > 1 ? "clear" : "clear single-slide"}></div>
            {isCustomerOfNNHellas2 ? <EServices /> : null}

            {!checkNN2Users || (isCustomerOfNNHellas && contractList.length > 0) ? (
              <>
                <div className="nn-container main">
                  <DashboardAlerts />
                </div>
                <ContractList />
                <div className="nn-container main">
                  <div className="new">
                    <OnlinePaymentPromo />
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="nn-container">
            <ArticleCarousel />
          </div>
        </>
      )}
    </Fragment>
  );
};
export default observer(MyHome);
