import React from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ElementDownload from "../../Document/ElementDownload";
export const DocumentsSelecetedTable = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "Description",
      headerName: t("file-upload.document-type"),
      flex: 1.5,
      headerAlign: "left",
      align: "left",
      sortable: true,
    },
    {
      field: "IssueDate",
      headerName: t("document.date-issue"),
      headerAlign: "center",
      align: "left",
      flex: 0.8,
      sortable: true,
    },
    {
      field: "c",
      headerName: t("document.download"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ElementDownload
            fileName={`${params.row.Description}`}
            DocumentLink={params.row.DocumentLink}
            DocumentType={params.row.DocumentType}
            MimeType={null}
          />
        </div>
      ),
    },
  ];

  const rows = data.map((element, i) => {
    return {
      id: i,
      ...element,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
    />
  );
};
