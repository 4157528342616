import React, { Fragment, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";
import BreadCrumb from "../../BreadCrumb";
import ContractStore from "../../../Stores/ContractStore";
import LoaderStore from "../../../Stores/LoaderStore";
import "../../../Css/Contracts_Details/selected-contract-account.css";
import "../../../Css/selected-contract-overview.css";
import "../../../Css/CommonCss/General.css";
import { Tabs } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import HRStore from "../../../Stores/HRStore";
import CoveragesHR from "../Coverages/CoveragesHR";
import DocumentsHR from "../Documents/DocumentsHR";
import PaymentsSelectedHR from "../Payments/PaymentsSelectedHR";
import ClaimsHR from "../Claims/ClaimsHR";
import GroupPensionInsuredMembersHR from "../Coverages/GroupPensionInsuredMembersHR";
import GroupPensionAccountBalance from "./GroupPensionAccountBalance";
import GroupPensionPayments from "../Payments/GroupPensionPayments";
const ContractSelectedHR = () => {
  const { t } = useTranslation();
  const contractStore = useContext(ContractStore);
  const hrStore = useContext(HRStore);
  const loaderStore = useContext(LoaderStore);
  const { selectedContractDetails, showCoveragesStore, showBillStore } =
    contractStore;
  const [contractDetails, setContractDetails] = useState({});

  let location = useLocation();

  useEffect(() => {
    if (location.state) {
      setContractDetails(location.state);
    }
    return () => {
      hrStore.setShowHRCoverageStore();
      hrStore.setShowHRClaimsStore();
      hrStore.setShowHRDocumentsStore();
      hrStore.setShowHRBillStore();
      hrStore.setShowHRAgentStore();
      hrStore.setClaimsSelectedFilterValue();
      hrStore.setShowHRInsuredMembers();
      hrStore.setShowHRGroupPensionHRPayments();
      hrStore.setShowHRGroupPensionHRAccountBalance();
    };
  }, [location]);

  useEffect(() => {
    loaderStore.setMiniLoaderForData2(showCoveragesStore ? false : true);
  }, [showCoveragesStore]);

  useEffect(() => {
    loaderStore.setMiniLoaderForData(showBillStore ? false : true);
  }, [showBillStore]);

  const getTabs = () => {
    let tabs = [];

    tabs.push({
      contentElement: contractDetails.IsGroupPensionPolicy ? (
        <GroupPensionInsuredMembersHR details={contractDetails} />
      ) : (
        <CoveragesHR details={contractDetails} />
      ),
      tabText: contractDetails.IsGroupPensionPolicy
        ? t("hr.insured")
        : t("contract.covers"),
    });

    if (contractDetails.DocumentsTabVisible) {
      tabs.push({
        contentElement: <DocumentsHR contract={contractDetails} />,
        tabText: t("contract.documents"),
      });
    }

    tabs.push({
      contentElement: contractDetails.IsGroupPensionPolicy ? (
        <GroupPensionPayments contract={contractDetails.PolicyNumber} />
      ) : (
        <PaymentsSelectedHR contract={contractDetails.PolicyNumber} />
      ),
      tabText: t("navigation.item-payments"),
    });

    tabs.push({
      contentElement: contractDetails.IsGroupPensionPolicy ? (
        <GroupPensionAccountBalance contract={contractDetails.PolicyNumber} />
      ) : (
        <ClaimsHR contract={contractDetails.PolicyNumber} />
      ),
      tabText: contractDetails.IsGroupPensionPolicy
        ? t("contract.total-account-value")
        : t("hr.compensation"),
    });
    return tabs;
  };

  return (
    <Fragment>
      {selectedContractDetails && contractDetails ? (
        <Fragment>
          <div className="nn-container">
            <div className="selected-contract-overview">
              <BreadCrumb Title={contractDetails.InsuranceProgram} />
              <div className="overview">
                <h1 className="selected-contract-label">{`${contractDetails.CompanyName} (${contractDetails.DisplayPolicyNumber})`}</h1>
                <div className="hr-info-label-container">
                  <div className="insurance-number">
                    <p>{t("document.insurance-policy-number")}:</p>
                    <span>{contractDetails.DisplayPolicyNumber}</span>
                  </div>
                  <div className="contracting-hr">
                    <p>{t("contract.contracting")}:</p>
                    <span>{contractDetails.CompanyName}</span>
                  </div>
                  <div
                    className={`payment-amount ${
                      contractDetails.IsGroupPolicy ? "doNotDisplay" : ""
                    }`}
                  >
                    <p>{t("document.date-issue")}:</p>
                    <span>
                      {setDateFormatForDateTime(contractDetails.IssueDate)}
                    </span>
                  </div>
                  <div
                    className={`insurance-status ${
                      contractDetails.IsGroupPolicy ? "doNotDisplay" : ""
                    }`}
                  >
                    <p>{t("contracts.expiration-date")}:</p>
                    <span>
                      {setDateFormatForDateTime(
                        contractDetails.CancellationDate,
                      )}
                    </span>
                  </div>
                </div>
                <hr className="overview-line" />
              </div>
            </div>
          </div>

          <div className="nn-container">
            <div className={"selected-contract-hr-tabs"}>
              <div className="panel">
                <Tabs centered tabs={getTabs()} />
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default observer(ContractSelectedHR);
