import React, { Fragment, useState, useEffect, useContext } from "react";
import ContractsTable from "./ContractsTable";
import { useLocation } from "react-router-dom";
import ContractSelected from "./ContractSelected";
import HRStore from "../../Stores/HRStore";
import ContractsTableHR from "../HR/Contracts/ContractsTableHR";
import ContractSelectedHR from "../HR/Contracts/ContractSelectedHR";

const ContractsToggleComp = () => {
  const { isHrRole } = useContext(HRStore);

  const [showContractsTable, setShowContractsTable] = useState(null);
  const path = useLocation();

  useEffect(() => {
    if (path.pathname.includes("details")) {
      setShowContractsTable(false);
    } else {
      setShowContractsTable(true);
    }
  }, [path.pathname]);
  return (
    <Fragment>
      {showContractsTable ? isHrRole ? <ContractsTableHR /> : <ContractsTable /> : isHrRole ? <ContractSelectedHR /> : <ContractSelected />}
    </Fragment>
  );
};
export default ContractsToggleComp;
