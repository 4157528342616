import { PrefabModal } from "@nn-design-system/react-component-library";
import React from "react";

const ContentModal = (props) => {
  return (
    <>
      {props.content ? (
        <PrefabModal
          isOpen={props.showModal}
          hasCloseButton
          width="970px"
          height="auto"
          children={<div className="nn-modal-content"> {props.content} </div>}
          onClose={() => props.toggleShowModal()}
          headingText={" "}
        />
      ) : null}
    </>
  );
};

export default ContentModal;
