import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import RegisterFormStep1 from "./RegisterFormStep1";
import RegisterFormStep2 from "./RegisterFormStep2";
import RegisterFormStep3 from "./RegisterFormStep3";
import RooteStore from "../../Stores/RooteStore";
import RegFailNotValidData from "./RegFailNotValidData";
import RegFailAccExists from "./RegFailAccExists";
import RegisterSuccess from "./RegisterSuccess";
import RegisterPromo from "./RegisterPromo";
import RegisterExampleDoc from "./RegisterExampleDoc";
import AccountRegLoginHeader from "../Header/AccountRegLoginHeader";
import "../../Css/Register/registration.css";
import "../../Css/CommonCss/RadioButton-register.css";
import ContentModal from "../CommonComp/ContentModal";
import TermsAndConditions from "../TextDocuments/TermsAndConditions";
import GlobalConsent from "../TextDocuments/GlobalConsent";
import ModalStore from "../../Stores/ModalStore";
import queryString from "query-string";
import { LanguageSwitch } from "../CommonComp/LanguageSwitch";
import { useTranslation } from "react-i18next";
const Register = (props) => {
  const { t } = useTranslation();
  const translationsEnabled =
    process.env.REACT_APP_TRANSLATIONS_ENABLED === "1";
  const userStore = useContext(RooteStore.userStore);
  const {
    toggleTerms,
    showTerms,
    toggleRegExample,
    showRegExample,
    toggleGlobalCon,
    showGlobalCon,
  } = useContext(ModalStore);
  const {
    registrationStatus,
    setRegistrationContractAndContractNumber,
    registrationContractNumber,
    registrationApplicationNumber,
  } = userStore;
  //registration
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState();
  const [contractNumber, setContractNumber] = useState("");
  const [isContractNumberPrefilled, setIsContractNumberPrefilled] =
    useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [consentisChecked, setconsentIsChecked] = useState(false);
  const [brandName, setBrandName] = useState();
  const [validate, setValidate] = useState(false);
  const [regType, setRegType] = useState(1);
  const [captchaValue, setCaptchaValue] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();
  const path = useLocation();

  const registrationValues = {
    firstName,
    setFirstName,
    surname,
    setSurname,
    middleName,
    setMiddleName,
    vatNumber,
    setVatNumber,
    dateOfBirth,
    setDateOfBirth,
    contractNumber,
    setContractNumber,
    brandName,
    setBrandName,
    regType,
    setRegType,
    isChecked,
    setIsChecked,
    consentisChecked,
    setconsentIsChecked,
    validate,
    captchaValue,
    setCaptchaValue,
    isContractNumberPrefilled,
    setIsContractNumberPrefilled,
  };

  const signInValues = {
    username,
    setUsername,
    password,
    setPassword,
    isChecked,
    setIsChecked,
    consentisChecked,
    setconsentIsChecked,
    validate,
  };

  useEffect(() => {
    if (path.pathname.includes("/register")) {
      const queryData = queryString.parse(path.search);
      if (queryData.contractnumber) {
        let { contractnumber, applicationnumber } = queryData;
        setRegistrationContractAndContractNumber(
          contractnumber,
          applicationnumber,
        );
        navigate("/register");
      }
    }
  }, [path]);

  useEffect(() => {
    if (username && password) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [signInValues]);

  const toRegFail = (regFail) => {
    navigate(
      props.partnerRegistration
        ? `/partner/register/${regFail}`
        : `/register/${regFail}`,
    );
  };

  useEffect(() => {
    if (registrationStatus === 0) {
      toRegFail("success");
    }
    if (registrationStatus === 1) {
      toRegFail("fail/wrongdata");
    }
    if (registrationStatus === 2) {
      toRegFail("fail/accounExists");
    }
  }, [registrationStatus]);

  const submitReg = () => {
    if (regType === 1) {
      const individualValues = {
        FirstName: firstName,
        Surname: surname,
        MiddleName: middleName,
        VatNumber: vatNumber,
        DateOfBirth: dateOfBirth,
        ContractNumber: registrationContractNumber || contractNumber,
        CaptchaResponse: captchaValue,
      };
      props.partnerRegistration
        ? userStore.registrationPartner(individualValues, regType)
        : userStore.registrationForAll(individualValues, regType);
    }

    if (regType === 2) {
      const companylValues = {
        VatNumber: vatNumber,
        LegalName: brandName,
        ContractNumber: registrationContractNumber || contractNumber,
        CaptchaResponse: captchaValue,
      };
      userStore.registrationForAll(companylValues, regType);
    }
  };

  return (
    <>
      <div className="nn-container">
        <header>
          <AccountRegLoginHeader />
        </header>
      </div>
      <div id="registration-body">
        <div
          className={`nn-container `}
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 18 }}
        >
          {translationsEnabled && <LanguageSwitch />}
        </div>
        <div className={`nn-container registration`}>
          <div className="left">{<RegisterPromo />}</div>
          <div className="right">
            <div className="login">
              <span>{t("register.already-an-account")} </span>
              <Link to="/azureb2cendpoint">{t("register.sign-in")}</Link>
            </div>
            <div className="top">
              <Routes>
                <Route
                  path=""
                  element={
                    <RegisterFormStep1
                      regValues={registrationValues}
                      partnerRegistration={props.partnerRegistration}
                    />
                  }
                />
                <Route
                  path="2"
                  element={
                    <RegisterFormStep2
                      regValues={registrationValues}
                      partnerRegistration={props.partnerRegistration}
                      registrationApplicationNumber={
                        registrationApplicationNumber
                      }
                    />
                  }
                />
                <Route
                  path="3"
                  element={
                    <RegisterFormStep3
                      regValues={registrationValues}
                      submitReg={submitReg}
                      partnerRegistration={props.partnerRegistration}
                    />
                  }
                />
                <Route
                  path="fail/wrongdata"
                  element={
                    <RegFailNotValidData
                      partnerRegistration={props.partnerRegistration}
                    />
                  }
                />
                <Route
                  path="fail/accounExists"
                  element={
                    <RegFailAccExists
                      partnerRegistration={props.partnerRegistration}
                    />
                  }
                />
                <Route
                  path="partner/fail/wrongdata"
                  element={
                    <RegFailNotValidData
                      partnerRegistration={props.partnerRegistration}
                    />
                  }
                />
                <Route
                  path="partner/fail/accounExists"
                  element={
                    <RegFailAccExists
                      partnerRegistration={props.partnerRegistration}
                    />
                  }
                />
                <Route path="success" element={<RegisterSuccess />} />
                <Route path="partner/success" element={<RegisterSuccess />} />
              </Routes>
            </div>
            <div className="bottom">
              <div>
                <span>{t("register.need-help")} </span>
                <span
                  onClick={() => toggleRegExample()}
                  className="example"
                  data-toggle="modal"
                  data-target="#example-modal"
                >
                  {t("register.registration-instructions")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContentModal
        content={<RegisterExampleDoc />}
        showModal={showRegExample}
        toggleShowModal={toggleRegExample}
      />
      <ContentModal
        content={<TermsAndConditions />}
        showModal={showTerms}
        toggleShowModal={toggleTerms}
      />
      <ContentModal
        content={<GlobalConsent />}
        showModal={showGlobalCon}
        toggleShowModal={toggleGlobalCon}
      />
    </>
  );
};

export default observer(Register);
