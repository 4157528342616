import React, { useContext, useEffect, useState } from "react";
import { Button } from "@nn-design-system/react-component-library";
import NavBarStore from "../../Stores/NavBarStore";
import CookieStore from "../../Stores/CookieStore";
import ContractStore from "../../Stores/ContractStore";
import LoginInfo from "../Header/LoginInfo";
import SideNavBar from "./SideNavBar";
import "../../Css/navigationMenu/sideMenu.css";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const SideMenu = () => {
  const navBarStore = useContext(NavBarStore);
  const cookieStore = useContext(CookieStore);
  const contractStore = useContext(ContractStore);

  const navigate = useNavigate();

  const { headerItemMobile } = navBarStore;
  const { isCustomerOfNNHellas } = cookieStore;
  const { contractList } = contractStore;

  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";

  const [sideMenuClass, setSideMenuClass] = useState("active");

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const closeNav = async (url) => {
    setSideMenuClass("inactive");
    await delay(500);
    navBarStore.setShowSideBar(true);
    navigate(url);
  };

  return (
    <>
      <div id="side-menu" className={sideMenuClass}>
        <div className="nn-container">
          <a className="closebtn normal" onClick={closeNav}>
            &times;
          </a>
        </div>
        <div className="nn-container">
          <div className="login-info-wrapper">
            <LoginInfo />
          </div>
          {headerItemMobile && (!checkNN2Users || (isCustomerOfNNHellas && contractList.length > 0)) ? (
            <div className="online-payment">
              <Button
                variant="PrimarySmall"
                onClick={() => closeNav(headerItemMobile.Url)}
              >
                {headerItemMobile.Title}
              </Button>
            </div>
          ) : null}

          <SideNavBar />
        </div>
      </div>
    </>
  );
};

export default observer(SideMenu);
