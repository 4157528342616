import React, {
  Fragment,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ConvertDecimalToString } from "../CommonComp/Functions";
import ContractSelectedDetails from "./ContractSelectedDetails";
import ContractSelectedAccountInvestment from "./ContractSelectedAccountInvestment";
import ContractSelectedAccountPension from "./ContractSelectedAccountPension";
import ContractSelectedCoverage from "./ContractSelectedCoverage";
import ContractSelectedDocument from "./ContractSelectedDocument";
import ContractSelectedPayment from "./ContractSelectedPayment";
import ContractSelectedIntermediary from "./ContractSelectedIntermediary";
import GroupPolicyCoverages from "./GroupPolicyCoverages";
import BreadCrumb from "../BreadCrumb";
import ContractStore from "../../Stores/ContractStore";
import DocumentsStore from "../../Stores/DocumentsStore";
import SpinnerSmall from "../CommonComp/SpinnerSmall";
import LoaderStore from "../../Stores/LoaderStore";
import "../../Css/Contracts_Details/selected-contract-account.css";
import "../../Css/selected-contract-overview.css";
import "../../Css/CommonCss/General.css";
import SpinnerSmall2 from "../CommonComp/SpinnerSmall2";
import SpinnerSmall3 from "../CommonComp/SpinnerSmall3";
import { Tabs } from "@nn-design-system/react-component-library";
import AccountStore from "../../Stores/AccountStore";
import GroupPensionAnalysis from "./GroupPensionAnalysis";
import GroupPensionCertificates from "./GroupPensionCertificates";

const ContractSelected = () => {
  const contractStore = useContext(ContractStore);
  const { documentList } = useContext(DocumentsStore);
  const loaderStore = useContext(LoaderStore);
  const { accountData } = useContext(AccountStore);

  const {
    selectedContractDetails,
    showCoveragesStore,
    showBillStore,
    billHasContent,
    coverageHasContent,
    hasGroupPensionAnalysisData,
    hasGroupPensionCertificatesData,
  } = contractStore;
  const { t } = useTranslation();
  const [contractDetails, setContractDetails] = useState({});
  const [showCoverages, setShowCoverages] = useState(false);
  const [showGroupPensionAnalysis, setShowGroupPensionAnalysis] =
    useState(false);
  const [showGroupPensionCertificates, setShowGroupPensionCertificates] =
    useState(false);
  const [showBill, setShowBill] = useState(false);

  let location = useLocation();

  //we dont dowload documents at the time
  const filterDocuments = (documentList, ContractNumber) => {
    var paymentDoc = documentList.filter(
      (d) => d.ContractNumber === ContractNumber && d.DocumentTypeId == 56,
    );

    if (paymentDoc.length >= 1) {
      return {
        paymentDocLink: paymentDoc[0].DocumentLink,
        paymentDocType: paymentDoc[0].DocumentType,
      };
    }
  };

  const renderProperCoveragesComponent = useCallback(
    (tabs) => {
      if (!contractDetails.IsGroupPensionPolicy) {
        let labText = t("contract.covers");
        if (contractDetails.IsGroupPolicy) {
          tabs.push({
            tabText: labText,
            contentElement: (
              <div className="container miniLoaderParentRelative">
                <SpinnerSmall2 />
                <GroupPolicyCoverages
                  showCoverages={showCoverages}
                  getData={!coverageHasContent}
                  contractId={contractDetails.Number}
                  ProductCode={contractDetails.ProductCode}
                  productCategoryId={contractDetails.ProductCategoryId}
                  Coverages={selectedContractDetails.Coverages}
                />
              </div>
            ),
          });
          return;
        }

        if (selectedContractDetails) {
          tabs.push({
            tabText: `${labText} / ${t("contract.allowance")}`,
            contentElement: (
              <div className="container miniLoaderParentRelative">
                <SpinnerSmall2 />
                <ContractSelectedCoverage
                  showCoverages={showCoverages}
                  getData={!coverageHasContent}
                  contractId={contractDetails.Number}
                  ProductCode={contractDetails.ProductCode}
                  productCategoryId={contractDetails.ProductCategoryId}
                  Insureds={contractDetails.Insureds}
                  PolicyHolder={contractDetails.PolicyHolder}
                  UserId={accountData.AccountId}
                  Coverages={selectedContractDetails.Coverages}
                  Benefits={selectedContractDetails.Benefits}
                  ExtraBenefit={selectedContractDetails.ExtraBenefitsLinks}
                  Clauses={selectedContractDetails.Clauses}
                  contractType={contractDetails.ContractAccountValueType}
                />
              </div>
            ),
          });
        } else {
          tabs.push({
            tabText: `${labText} / ${t("contract.allowance")}`,
            contentElement: (
              <div className="container miniLoaderParentRelative">
                <SpinnerSmall2 />
                <ContractSelectedCoverage
                  Insureds={contractDetails.Insureds}
                  Coverages={selectedContractDetails.Coverages}
                  Benefits={selectedContractDetails.Benefits}
                  ExtraBenefit={selectedContractDetails.ExtraBenefitsLinks}
                  Clauses={selectedContractDetails.Clauses}
                />
              </div>
            ),
          });
        }
      }
    },
    [
      contractDetails,
      selectedContractDetails,
      showCoverages,
      coverageHasContent,
      accountData,
    ],
  );

  const renderGroupPensionTabs = useCallback(
    (tabs) => {
      tabs.push({
        tabText: t("contract.bill-analysis"),
        contentElement: (
          <div className="container miniLoaderParentRelative">
            <SpinnerSmall3 />
            <GroupPensionAnalysis
              showData={showGroupPensionAnalysis}
              contractId={contractDetails.Number}
              ProductCode={contractDetails.ProductCode}
              productCategoryId={contractDetails.ProductCategoryId}
            />
          </div>
        ),
      });

      tabs.push({
        tabText: t("contract.attestations-certificates"),
        contentElement: (
          <div className="container miniLoaderParentRelative">
            <SpinnerSmall3 />
            <GroupPensionCertificates
              showData={showGroupPensionCertificates}
              contractId={contractDetails.Number}
              ProductCode={contractDetails.ProductCode}
              productCategoryId={contractDetails.ProductCategoryId}
            />
          </div>
        ),
      });
    },
    [showGroupPensionCertificates, showGroupPensionAnalysis, contractDetails],
  );

  useEffect(() => {
    if (location.state) {
      // contractStore.loadContractDetail(location.state.Number);
      // console.log(location.state, "for state");
      setContractDetails(location.state);
    }
    return () => {
      contractStore.deloadContractDetail();
      contractStore.setShowCoverageStore();
      contractStore.setShowBillStore();
      contractStore.setShowAgentStore();
    };
  }, [location]);

  useEffect(() => {
    if (contractDetails.Number) {
      contractStore.loadAgent(contractDetails.Number);
    }
  }, [contractDetails.Number]);

  useEffect(() => {
    if (showCoveragesStore) {
      setShowCoverages(true);
      loaderStore.setMiniLoaderForData2(false);
    } else {
      setShowCoverages(false);
      loaderStore.setMiniLoaderForData2(true);
    }
  }, [showCoveragesStore]);

  useEffect(() => {
    if (showBillStore) {
      setShowBill(true);
      loaderStore.setMiniLoaderForData(false);
    } else {
      setShowBill(false);
      loaderStore.setMiniLoaderForData(true);
    }
  }, [showBillStore]);

  useEffect(() => {
    if (contractDetails.IsGroupPensionPolicy) {
      if (hasGroupPensionAnalysisData) {
        setShowGroupPensionAnalysis(true);
      } else {
        setShowGroupPensionAnalysis(false);
      }
    }
  }, [contractDetails.IsGroupPensionPolicy, hasGroupPensionAnalysisData]);

  useEffect(() => {
    if (contractDetails.IsGroupPensionPolicy) {
      if (hasGroupPensionCertificatesData) {
        setShowGroupPensionCertificates(true);
      } else {
        setShowGroupPensionCertificates(false);
      }
    }
  }, [contractDetails.IsGroupPensionPolicy, hasGroupPensionCertificatesData]);

  const getTabs = () => {
    let tabs = [];

    if (
      !contractDetails.IsGroupPensionPolicy &&
      contractDetails.OverviewTabVisible
    ) {
      tabs.push({
        contentElement: (
          <div className="container">
            <ContractSelectedDetails contractDetails={contractDetails} />
          </div>
        ),
        tabText: t("contract.general-elements"),
      });
    }

    if (
      !contractDetails.IsGroupPensionPolicy &&
      contractDetails.ContractValueTabVisible
    ) {
      if (selectedContractDetails) {
        if (contractDetails.ContractAccountValueType === "investment") {
          tabs.push({
            tabText: t("contract.account-value"),
            contentElement: (
              <div className="container miniLoaderParentRelative">
                <SpinnerSmall />
                <ContractSelectedAccountInvestment
                  getData={!billHasContent}
                  contract={contractDetails}
                  contractDetails={selectedContractDetails}
                  OsekaLinks={selectedContractDetails.OsekaInvestmentLinks}
                  adHocSt={selectedContractDetails.InvestmentStatementLink}
                  contractTypes={selectedContractDetails.DocumentTypes}
                />
              </div>
            ),
          });
        } else {
          tabs.push({
            tabText: t("contract.account-value"),
            contentElement: (
              <div className="container miniLoaderParentRelative">
                <SpinnerSmall />
                <ContractSelectedAccountPension
                  getData={!billHasContent}
                  contract={contractDetails}
                  contractDetails={selectedContractDetails}
                  OsekaLinks={selectedContractDetails.OsekaInvestmentLinks}
                />
              </div>
            ),
          });
        }
      }
    }

    renderProperCoveragesComponent(tabs);

    if (contractDetails.IsGroupPensionPolicy) {
      renderGroupPensionTabs(tabs);
    }

    if (contractDetails.DocumentsTabVisible) {
      tabs.push({
        contentElement: (
          <div className="container">
            <SpinnerSmall3 />
            <ContractSelectedDocument contractDetails={contractDetails} />
          </div>
        ),
        tabText: t("contract.documents"),
      });
    }

    if (
      !contractDetails.IsGroupPensionPolicy &&
      contractDetails.Number &&
      contractDetails.PaymentsTabVisible
    ) {
      tabs.push({
        contentElement: (
          <div className="container">
            <ContractSelectedPayment
              contractNumber={contractDetails.Number}
              PaymentFrequency={contractDetails.PaymentFrequency}
              DocumentDetails={filterDocuments(
                documentList,
                contractDetails.Number,
              )}
            />
          </div>
        ),
        tabText: t("navigation.item-payments"),
      });
    }

    if (
      !contractDetails.IsGroupPensionPolicy &&
      contractDetails.AgentTabVisible
    ) {
      if (selectedContractDetails.Agent) {
        tabs.push({
          contentElement: (
            <div className="container miniLoaderParentRelative">
              <ContractSelectedIntermediary
                Agent={selectedContractDetails.Agent}
              />
            </div>
          ),
          tabText: t("contract.mediator"),
        });
      } else {
        tabs.push({
          contentElement: (
            <div className="container miniLoaderParentRelative">
              <SpinnerSmall />
              <ContractSelectedIntermediary />
            </div>
          ),
          tabText: t("contract.mediator"),
        });
      }
    }

    return tabs;
  };

  const isGroupContractOrPension = useMemo(() => {
    return (
      contractDetails.IsGroupPensionPolicy || contractDetails.IsGroupPolicy
    );
  }, [contractDetails.IsGroupPensionPolicy, contractDetails.IsGroupPolicy]);

  return (
    <Fragment>
      {selectedContractDetails && contractDetails ? (
        <Fragment>
          <div className="nn-container">
            <div className="selected-contract-overview">
              <BreadCrumb Title={contractDetails.ProductName} />
              <div className="overview">
                <h1 className="selected-contract-label">
                  {contractDetails.ProductName}
                </h1>
                <div className="insurance-number">
                  <p>{t("document.insurance-policy-number")}:</p>
                  <span>{contractDetails.DisplayNumber}</span>
                </div>
                <div className="contracting">
                  <p>{t("contract.contracting")}:</p>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {contractDetails.PolicyholderSurname}{" "}
                    {isGroupContractOrPension
                      ? ""
                      : contractDetails.PolicyholderFirstName}
                  </span>
                </div>
                <div
                  className={`payment-amount ${
                    isGroupContractOrPension ? "doNotDisplay" : ""
                  }`}
                >
                  <p>{t("online.payment.payment-amount")}:</p>
                  <span>
                    {ConvertDecimalToString(contractDetails.InstallmentAmount)}
                  </span>
                </div>
                <div
                  className={`insurance-status ${
                    isGroupContractOrPension ? "doNotDisplay" : ""
                  }`}
                >
                  <p>{t("contract.insurance-policy-status")}:</p>
                  <span>{contractDetails.Status}</span>
                </div>
                <hr className="overview-line" />
              </div>
            </div>
          </div>

          <div className="nn-container">
            <div
              className={`selected-contract-tabs ${
                isGroupContractOrPension ? "group-policy" : ""
              }`}
            >
              <div className="panel">
                <Tabs centered tabs={getTabs()} />
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default observer(ContractSelected);
