import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import ArticleStore from "../../Stores/ArticleStore";
import ArticleByCategories from "./ArticleByCategories";
import "../../Css/Articles/articles-lists.css";

const ArticleList = () => {
  const { articleCategories } = useContext(ArticleStore);

  return (
    <Fragment>
      {articleCategories ? (
        <div id="articles-lists" className="nn-container">
          {articleCategories.map((element, i) => {
            return <ArticleByCategories key={i} category={element} i={i} />;
          })}
        </div>
      ) : null}
    </Fragment>
  );
};

export default observer(ArticleList);
