import React, { createContext, useContext, useState, useEffect } from "react";

const FormContext = createContext();
const FormContextProvider = ({
  children,
  validation,
  contractNumber,
  customerID,
  notificationTypeDescription,
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    contractNumber,
    customerID,
    notificationTypeDescription,
  });

  useEffect(() => {
    setFormData((previous) => ({ ...previous, values }));
  }, [values]);

  return (
    <FormContext.Provider
      value={{ formData, values, setValues, errors, setErrors, validation }}
    >
      {children}
    </FormContext.Provider>
  );
};

function useFormContext() {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error("useFormContext must be used within a FormContextProvider");
  }

  return context;
}

export { FormContextProvider, useFormContext };
