import React, { useCallback, useState } from "react";
import {
  Icon,
  Label,
  Paragraph,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import {
  ConvertDecimalToString,
  setDateFormatForDateTime,
} from "../CommonComp/Functions";
import Axios from "axios";
import styles from "../../Css/Contracts_Details/GroupPolicyCoverages.module.scss";

const GroupPolicySelectedCoverageRow = (props) => {
  const { t } = useTranslation();
  const downloadBooklet = (myUrl, fileName, mimeType) => {
    function token() {
      const token = sessionStorage.getItem("msal.idtoken");
      return token;
    }
    function impersonated() {
      const client = sessionStorage.getItem("client");
      return client;
    }

    Axios({
      url: myUrl,
      method: "GET",
      responseType: "blob",
      headers: {
        authorization: `Bearer ${token()}`,
        ImpersonatedUser: impersonated(),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.${mimeType}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const disclaimerFirstParagraphText = (text, param1, param2) => {
    return text !== null
      ? `*${text.replace("{0}", setDateFormatForDateTime(param1)).replace("{1}", setDateFormatForDateTime(param2))}`
      : "";
  };

  const expandedSectionOne = useCallback(() => {
    return (
      <>
        <div className="collapsible-container">
          <div className="chevron-down" style={{ visibility: "hidden" }}>
            <Icon name="ChevronUp" variant="Medium" />
          </div>
          <div
            className={`collapsible-container-elements contract-table-header ${styles.header}`}
          >
            <div className={`item ${styles.groupPolicyItem}`}>
              <Label
                text={t("contract.coverage-capital")}
                subtext={ConvertDecimalToString(props.element.SumAssuredAmount)}
              />
            </div>
            <div className={`item ${styles.groupPolicyItem}`}>
              <Label
                text={`${"contract.total-health-benefits"}*`}
                subtext={ConvertDecimalToString(
                  props.element.TotalCompensationAmount,
                )}
              />
            </div>
            <div className={`item ${styles.groupPolicyItem}`}>
              <Label
                text={`${t("contract.out-of-hospital")}*`}
                subtext={ConvertDecimalToString(
                  props.element.OutHospitalCompensationAmount,
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles.disclaimer}>
          <Paragraph>
            {disclaimerFirstParagraphText(
              props.element.CoverageDisclaimer,
              props.element.StartDate,
              props.element.EndDate,
            )}
          </Paragraph>
          {props.element.BookletNote && props.element.BookletLink && (
            <>
              <br />
              <Paragraph>
                {`${props.element.BookletNote} `}
                <a
                  onClick={() => {
                    downloadBooklet(
                      props.element.BookletLink.Path,
                      props.element.BookletLink.Name,
                      props.element.BookletLink.MimeType,
                    );
                  }}
                >
                  {props.element.BookletLink.Title}
                </a>
                .
              </Paragraph>
            </>
          )}
        </div>
      </>
    );
  }, [props]);

  const expandedSectionTwo = useCallback(() => {
    return (
      <>
        <div className="collapsible-container">
          <div className="chevron-down" style={{ visibility: "hidden" }}>
            <Icon name="ChevronUp" variant="Medium" />
          </div>
          <div
            className={`collapsible-container-elements contract-table-header ${styles.header}`}
          >
            <div
              className={`item ${styles.groupPolicyItem} ${styles.groupPolicyExpandedItem}`}
            >
              <Label
                text={t("contract.coverage-capital")}
                subtext={ConvertDecimalToString(props.element.SumAssuredAmount)}
              />
            </div>
          </div>
        </div>
        {props.element.BookletNote && props.element.BookletLink && (
          <div className={styles.disclaimer}>
            <Paragraph>
              {`${props.element.BookletNote} `}
              <a
                onClick={() => {
                  downloadBooklet(
                    props.element.BookletLink.Path,
                    props.element.BookletLink.Name,
                    props.element.BookletLink.MimeType,
                  );
                }}
              >
                {props.element.BookletLink.Title}
              </a>
            </Paragraph>
          </div>
        )}
      </>
    );
  }, [props]);

  const children = useCallback(() => {
    return (
      <div
        className={`collapsible-children-contract ${styles.groupPolicyCollapsibleChildren}`}
      >
        {props.element.HasCompensations
          ? expandedSectionOne()
          : expandedSectionTwo()}
      </div>
    );
  }, [props, expandedSectionOne, expandedSectionTwo]);

  return (
    <>
      <div className={`${"collapsible-table-contract-container"}`}>
        {children()}
      </div>
    </>
  );
};

export default GroupPolicySelectedCoverageRow;
