import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@nn-design-system/react-component-library";

const ContractExtraBenefitRow = (props) => {
  const { t } = useTranslation();
  return (
    <div className="more-coverages">
      <a href={props.ExtraBenefit} target="_blank" rel="noopener noreferrer">
        <Icon name="ChevronRight" color="#EE7F00" variant="Small" />
        <span>{t("contract.additional-privilege-benefits")}</span>
      </a>
    </div>
  );
};

export default ContractExtraBenefitRow;
