import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoaderStore from "../../Stores/LoaderStore";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import PaymentStore from "../../Stores/PaymentStore";
import SpinnerSmall from "../CommonComp/SpinnerSmall";
import queryString from "query-string";

const OnlinePaymentTransactionHandler = () => {
  const onlinePayments = useContext(OnlinePaymentStore);
  const paymentStore = useContext(PaymentStore);
  const loaderStore = useContext(LoaderStore);
  const { paymentList } = paymentStore;
  const [resultData, setResultData] = useState();
  const path = useLocation();

  useEffect(() => {
    if (path.pathname.includes("/online-payment/result")) {
      const queryData = queryString.parse(path.search);
      setResultData(queryData);
    }
  }, [path]);

  useEffect(() => {
    if (resultData) {
      onlinePayments.updateTransactionStatus(resultData);
    }
  }, [resultData]);

  useEffect(() => {
    loaderStore.setMiniLoaderForData(false);
    return loaderStore.setMiniLoaderForData(true);
  }, [loaderStore]);

  return (
    <>
      <SpinnerSmall />
    </>
  );
};

export default OnlinePaymentTransactionHandler;
