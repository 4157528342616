import Axios from "axios";
import { contentTypeToExtension, currencySymbols } from "../HR/Utils/Constants";

export const setDateFormatForDateTime = (val) => {
  if (val !== undefined && val !== "-") {
    if (val) {
      if (!val.includes("/")) {
        let arrayDate = val.split(/\/|-|T/);
        let date = arrayDate[2] + "/" + arrayDate[1] + "/" + arrayDate[0];
        if (date !== "01/01/0001") {
          return date;
        } else {
          return "-";
        }
      } else {
        return val;
      }
    } else {
      return "-";
    }
  } else {
    return "-";
  }
};

export const dateFormatForDateAndTime = (val) => {
  if (val) {
    if (!val.includes("/")) {
      let arrayDate = val.split(/\/|-|T/);
      let date = arrayDate[2] + "/" + arrayDate[1] + "/" + arrayDate[0];
      let time = arrayDate[3];
      let dateTime = {
        date,
        time,
      };
      return dateTime;
    } else {
      return val;
    }
  }
  return "-";
};

export const getdateOrTime = (val) => {
  let inputDate = new Date(val);
  let today = new Date();
  let newDate = dateFormatForDateAndTime(val);
  if (today - inputDate > 24 * 60 * 60 * 1000 || today.getDate() !== inputDate.getDate()) {
    return newDate.date;
  } else {
    return newDate.time.slice(0, 5);
  }
};

export const getdate = (val) => {
  let newDate = dateFormatForDateAndTime(val);
  return newDate.date;
};

export const getMonthYear = (val) => {
  let newDate = dateFormatForDateAndTime(val);
  var dateArray = newDate.date.split("/");
  var mothYear = dateArray[1] + "/" + dateArray[2];
  return mothYear;
};

export const ConvertDecimalToString = (decVal) => {
  if (decVal) {
    let stringVal = decVal.toLocaleString("el-GR");
    return "€ " + stringVal;
  } else {
    return " - ";
  }
};

export const ConvertDecimalToStringWithOutCurrency = (decVal, culture = "el-GR", numberOfFractionDigits = 3) => {
  let stringVal = new Intl.NumberFormat(culture, {
    minimumFractionDigits: 0,
    maximumFractionDigits: numberOfFractionDigits,
  }).format(decVal);
  return decVal ? stringVal : " - ";
};

export const ConvertDecimalToStringWithCurrency = (decVal, currency, zeroSubstitute = " - ") => {  
  let stringVal;
  if (zeroSubstitute) {
    stringVal = decVal 
      ? decVal.toLocaleString("el-GR") 
      : zeroSubstitute;
  }
  else {
    stringVal = new Intl.NumberFormat("el-GR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
      }).format(decVal);
  }
  const symbol = currencySymbols[currency] || "€";
  return `${!zeroSubstitute || decVal ? symbol : ""} ${stringVal}`;
};

export const stringToAmount = (value, numberOfFractionDigits = 2) => {
  let signal = Number(value) >= 0 ? "" : "-";
  let stringVal = new Intl.NumberFormat("el-GR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: numberOfFractionDigits,
  }).format(Math.abs(value));
  return `€ ${signal}${stringVal}`;
};

export const downloadDocument = async (myUrl, fileName, mimeType) => {
  function token() {
    const token = sessionStorage.getItem("msal.idtoken");
    return token;
  }

  try {
    let response = await Axios({
      url: myUrl, //your url
      method: "GET",
      responseType: "blob", // important
      headers: {
        authorization: `Bearer ${token()}`,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const fileExtension = mimeType
      ? `.${mimeType}`
      : contentTypeToExtension[response.headers["content-type"].toString()] || "pdf";

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}${fileExtension}`); //or any other extension
    document.body.appendChild(link);
    link.click();
    return "";
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const removeZeroFromString = (value) => {
  if (value) {
    if (parseInt(value) > 1) {
      let newValue = value.replace(/^0+/, "");
      return newValue + "%";
    } else {
      return "-";
    }
  }
};

export const decimalToPercentage = (value) => {
  if (value) {
    if (parseInt(value) > 1) {
      let newValue = value.replace(".", ",");
      return newValue + "%";
    } else {
      return "-";
    }
  }
};
export const htmlElement = (value) => {
  return <div className="nnerrormessage">{value}</div>;
};

export const normalizeGreek = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export const downloadPdfFromBinaryProp = (base64String, fileName) => {
  const byteCharacters = atob(base64String.toString());
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

export const ConvertDecimalToStringWithCurrencyMaxFractionDigits = (decVal, currency) => {
  decVal = decVal.toLocaleString("el-GR", { maximumFractionDigits: 6 });
  const stringVal = decVal ? decVal : " - ";
  const symbol = currencySymbols[currency] || "€";
  return `${decVal ? symbol : ""} ${stringVal}`;
};

const Functions = () => {};
export default Functions;
