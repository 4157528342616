import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Msal from "msal";
import { useTranslation } from "react-i18next";
import { Icon } from "@nn-design-system/react-component-library";
import CountDown from "../CountDown";
import UserStore from "../../Stores/UserStore";
import AccountStore from "../../Stores/AccountStore";
import InboxStore from "../../Stores/InboxStore";
import B2cStore from "../../Stores/B2cStore";
import NavBarStore from "../../Stores/NavBarStore";
import CookieStore from "../../Stores/CookieStore";
import { observer } from "mobx-react-lite";
import "../../Css/Header/LoginInfo.css";
import HRStore from "../../Stores/HRStore";
import { accountRole } from "../HR/Utils/Constants";
import ContractStore from "../../Stores/ContractStore";
import { LanguageSwitch } from "../CommonComp/LanguageSwitch";

const LoginInfo = () => {
  const { t } = useTranslation();
  const translationsEnabled =
    process.env.REACT_APP_TRANSLATIONS_ENABLED === "1";
  const cookieStore = useContext(CookieStore);
  const { isCustomerSupport } = cookieStore;
  const userStore = useContext(UserStore);
  const { responseUserName } = userStore;
  const inboxStore = useContext(InboxStore);
  const { importantMessageCount } = inboxStore;
  const accountStore = useContext(AccountStore);
  const { accountData, logout, hasMultipleRole } = accountStore;
  const b2cStore = useContext(B2cStore);
  const { contractList } = useContext(ContractStore);
  const { msalConfig } = b2cStore;
  const authService = new Msal.UserAgentApplication(msalConfig);
  const [nameStatus, setNameStatus] = useState();
  const navBarStore = useContext(NavBarStore);
  const { showSideBar } = navBarStore;
  const [displayName, setDisplayName] = useState("");
  const [adminValue, setAdminValue] = useState("");
  const { isHrRole, setIsHrRole } = useContext(HRStore);

  const handleLogout = () => {
    sessionStorage.removeItem("client");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("support");
    sessionStorage.removeItem("LoginLog");
    sessionStorage.removeItem("LoginLog");
    sessionStorage.removeItem("isHrRole");
    logout();
    authService.logout();
  };

  const closeNav = () => {
    navBarStore.setShowSideBar(true);
  };

  const goToAdmin = () => {
    sessionStorage.removeItem("client");
    sessionStorage.removeItem("isHrRole");
    cookieStore.setValueForCustomerSupport(true);
    accountStore.deleteAccountData();
    userStore.deleteIpmeronateUser();
  };

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        setNameStatus(true);
      } else {
        setNameStatus(false);
      }
    }
  }, []);

  useEffect(() => {
    if (nameStatus === false && responseUserName === "") {
      setNameStatus(true);
    }
  }, [nameStatus]);

  useEffect(() => {
    if (isCustomerSupport) {
      setDisplayName(sessionStorage.getItem("userName"));
      setNameStatus(true);
    }
  }, [isCustomerSupport]);

  useEffect(() => {
    if (accountData) {
      if (sessionStorage.hasOwnProperty("support")) {
        let isSupport = sessionStorage.getItem("support");
        if (isSupport === "true") {
          setAdminValue("Admin");
        }
      } else {
        setAdminValue("");
      }
    }
  }, [accountData]);

  return (
    <div className="login item">
      <div className="rigth-login-component">
        <div className="login-info">
          {isCustomerSupport ? (
            <p className="username">{displayName}</p>
          ) : (
            <>
              {nameStatus ? (
                <p className="username">
                  {accountData.Type === "Company" ? (
                    accountData.BrandName ? (
                      accountData.BrandName
                    ) : (
                      "-"
                    )
                  ) : accountData.FirstName && accountData.Surname ? (
                    adminValue === "" ? (
                      accountData.FirstName + " " + accountData.Surname
                    ) : (
                      <>
                        <span id="adminlink" onClick={goToAdmin}>
                          {adminValue}
                        </span>{" "}
                        {" / " +
                          accountData.FirstName +
                          " " +
                          accountData.Surname}
                      </>
                    )
                  ) : (
                    "-"
                  )}
                </p>
              ) : (
                <p className="username">{responseUserName}</p>
              )}
            </>
          )}

          <p className="logout-remaining-time">
            <span>{t("global.disconnect-in")}</span> <CountDown />
          </p>
        </div>
        <div className="nn-icons-header">
          {isCustomerSupport ? (
            <>
              <span>{"  "} </span>
            </>
          ) : (
            <>
              {isHrRole === null && hasMultipleRole ? null : (
                <Link
                  title={t("login.my-account")}
                  to="/myAccount"
                  className="profile-icon"
                  onClick={closeNav}
                >
                  <Icon name="Profile" variant="Large" color="#EA650D" />
                </Link>
              )}
              {hasMultipleRole && !!contractList.length && (
                <Link
                  title={`${t("login.browse-as")}: ${isHrRole ? accountRole.hr : accountRole.individual}\n${t("login.tap-change-profile")}`}
                  to="/home"
                  className="CircularArrows"
                  onClick={() => {
                    setIsHrRole(!isHrRole);
                    sessionStorage.setItem("isHrRole", !isHrRole);
                  }}
                >
                  <Icon name="FileChevrons" variant="Large" color="#EA650D" />
                </Link>
              )}
              {showSideBar && !isHrRole ? (
                <Link
                  title={t("login.my-messages")}
                  to="/myInbox"
                  className="inbox-icon"
                >
                  <Icon name="Envelope" variant="Large" color="#EA650D" />
                  {importantMessageCount ? (
                    <span className="message-count">
                      {importantMessageCount}
                    </span>
                  ) : null}
                </Link>
              ) : null}
            </>
          )}

          <a
            title={t("login.log-out")}
            className="logout"
            onClick={handleLogout}
          >
            <Icon name="ArrowOut" variant="Large" color="#EA650D" />
          </a>
          {translationsEnabled && <LanguageSwitch ml={"36px"} />}
        </div>
      </div>
    </div>
  );
};

export default observer(LoginInfo);
