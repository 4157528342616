import React from "react";

const CookiesPolicy = () => {
  return (
    <div>
      <h2 className="modal-title">
        <span className="light">Πολιτική </span>
        <span className="normal">Cookies </span>
        <span className="dark">myNN </span>
      </h2>

      <p className="text">
        Για να εξασφαλίσουμε τη σωστή λειτουργία του ιστότοπου της Εταιρείας μας, μερικές φορές τοποθετούμε μικρά αρχεία
        δεδομένων στον υπολογιστή σας, τα λεγόμενα «cookies».
      </p>
      <p className="text">
        <b>Τι είναι τα cookies;</b>
      </p>
      <p className="text">
        Τα cookies είναι μικρά αρχεία κειμένου τα οποία ο ιστότοπος της Εταιρείας μας αποθηκεύει στον υπολογιστή σας ή
        στην κινητή σας συσκευή, όταν τον επισκέπτεστε. Με τον τρόπο αυτό, ο ιστότοπός μας θυμάται τις ενέργειές σας και
        τις προτιμήσεις σας (όπως κωδικός σύνδεσης, γλώσσα, μέγεθος γραμματοσειράς και άλλες προτιμήσεις απεικόνισης)
        για όσο χρονικό διάστημα τα cookies παραμένουν αποθηκευμένα στον υπολογιστή σας, κι έτσι δεν χρειάζεται να
        εισάγετε τις προτιμήσεις αυτές κάθε φορά που επισκέπτεστε τον ιστότοπό μας ή φυλλομετρείτε τις σελίδες του.
      </p>
      <p className="text">
        <b>Πως χρησιμοποιούμε τα cookies;</b>
      </p>
      <p className="text">
        Ορισμένες από τις σελίδες μας χρησιμοποιούν cookies για να θυμούνται τις προτιμήσεις σας όσον αφορά την
        απεικόνιση, π.χ. φωτεινότητα ή μέγεθος χαρακτήρων, Η ενεργοποίηση αυτών των cookies δεν είναι απαραίτητη για τη
        λειτουργία του ιστότοπού μας, αλλά μέσω αυτών θα έχετε δυνατότητες για καλύτερη φυλλομέτρηση. Μπορείτε να
        διαγράψετε αυτά τα cookies, ή να αποκλείσετε την πρόσβαση σε αυτά, αλλά, αν το κάνετε, ορισμένα χαρακτηριστικά
        του ιστότοπου ίσως να μην λειτουργούν ικανοποιητικά. Επίσης, τα cookies χρησιμοποιούνται για συλλαγή ανώνυμων
        στοιχείων για τα Google Analytics.
      </p>
      <p className="text">
        Οι πληροφορίες που σχετίζονται με τα cookies δεν χρησιμοποιούνται για να σας ταυτοποιήσουν προσωπικά. Τα cookies
        δεν χρησιμοποιούνται για σκοπούς άλλους από αυτούς που περιγράφονται στο παρόν κείμενο.
      </p>
      <p className="text">Ίσως σας ζητηθεί να συμφωνήσετε στην αποθήκευση αυτών των cookies.</p>
      <p className="text">
        <b>Πώς να ελέγχετε τα cookies</b>
      </p>
      <p className="text">
        Μπορείτε να ελέγχετε και/ή να διαγράφετε τα cookies ανάλογα με τις επιθυμίες σας. Λεπτομέρειες θα βρείτε εδώ:
        aboutcookies.org. Μπορείτε να διαγράψετε όλα τα cookies που βρίσκονται ήδη στον υπολογιστή σας, όπως και να
        ρυθμίσετε τους περισσότερους φυλλομετρητές κατά τρόπο που να μην επιτρέπουν την εγκατάσταση cookies. Ωστόσο,
        στην περίπτωση αυτή, ίσως χρειαστεί να προσαρμόζετε εσείς από μόνοι σας ορισμένες προτιμήσεις κάθε φορά που
        επισκέπτεστε έναν ιστότοπο.
      </p>
    </div>
  );
};

export default CookiesPolicy;
