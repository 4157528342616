import React from "react";
import { ConvertDecimalToStringWithCurrencyMaxFractionDigits } from "../CommonComp/Functions";
import { PortfolioBarChart } from "./Charts/PortfolioBarChart";

export const PortfolioPerProductDetailPanel = ({ element }) => {
  //There should only exist one fund of this kind.
  const dscIndex = element.findIndex(({ FundCode }) =>
    ["DSC1", "DSC2"].includes(FundCode),
  );
  element.push(element.splice(dscIndex, 1)[0]);

  return (
    <>
      {element?.map((e, i) => {
        if (e.UnitPrice === 0) {
          return null;
        }

        return (
          <div
            key={i}
            role="row"
            className="MuiDataGrid-row"
            style={{ display: "flex" }}
          >
            <div
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
              style={{ minWidth: 60, maxWidth: 60 }}
              role="cell"
            ></div>
            <div
              role="cell"
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
              style={{
                minWidth: 222,
                maxWidth: 222,
                whiteSpace: "normal",
              }}
            >
              {e.FundName}
            </div>
            <div
              role="cell"
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
              style={{
                minWidth: 103,
                maxWidth: 103,
              }}
            >
              {e.ContractNumber}
            </div>

            <div
              role="cell"
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
              style={{
                minWidth: 103,
                maxWidth: 103,
              }}
            >
              {ConvertDecimalToStringWithCurrencyMaxFractionDigits(
                Number(e.UnitSpecialPrice),
              )}
            </div>
            <div
              role="cell"
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
              style={{
                minWidth: 103,
                maxWidth: 103,
              }}
            >
              {e.NumberOfUnits?.toLocaleString("el-GR", {
                maximumFractionDigits: 6,
              })}
            </div>
            <div
              role="cell"
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
              style={{
                minWidth: 165,
                maxWidth: 165,
              }}
            >
              {ConvertDecimalToStringWithCurrencyMaxFractionDigits(
                Number(e.UnitPrice),
              )}
            </div>
            <div
              role="cell"
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft"
              style={{
                minWidth: 134,
                maxWidth: 134,
              }}
            >
              {e.FundPercentage.toLocaleString("el-GR", {
                maximumFractionDigits: 2,
              })}{" "}
              %
            </div>
          </div>
        );
      })}
      <PortfolioBarChart contracts={element} />
    </>
  );
};
