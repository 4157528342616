import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ArticleList from "./ArticleList";
import ArticleDetails from "./ArticleDetails";

const ArticleToggleComp = () => {
  const [showArticleList, setShowArticleList] = useState(true);
  const [showVideoDetails, setShowVideoDetailst] = useState(false);
  const path = useLocation();

  useEffect(() => {
    if (path.pathname.includes("details")) {
      setShowArticleList(false);
      if (path.pathname.includes("video")) {
        setShowVideoDetailst(true);
      } else {
        setShowVideoDetailst(false);
      }
    } else {
      setShowArticleList(true);
    }
  }, [path.pathname]);
  if (showArticleList) {
    return <ArticleList />;
  } else {
    return <ArticleDetails />;
  }
};

export default ArticleToggleComp;
