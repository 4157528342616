import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import LoaderStore from "../../Stores/LoaderStore";
import "../../Css/SpinnerSmall.css";

const SpinnerSmall = () => {
  const { miniLoaderForData } = useContext(LoaderStore);
  const [loaderStatus, setLoaderStatus] = useState(false);

  useEffect(() => {
    if (miniLoaderForData) {
      setLoaderStatus(true);
    } else {
      setLoaderStatus(false);
    }
  }, [miniLoaderForData]);

  if (loaderStatus) {
    return (
      <div style={{ alignContent: "center" }} className="small-spinner">
        <div className="sk-folding-cube-small">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
        <div className="gray" style={{ textAlign: "center" }}></div>
      </div>
    );
  }
};

export default observer(SpinnerSmall);
