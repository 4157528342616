import { useContext, useMemo } from "react";
import { Label } from "@nn-design-system/react-component-library";
import ClaimsStore from "../../../Stores/ClaimsStore";
import img from "../../../assets/index";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";

const MaternityExpenseTypeItem = ({
  possibleExpenseTypeCodes,
  displayName,
  selectedExpense,
  isExpenseElementDisabled,
  showExpenseComponent,
  setSelectedExpense,
}) => {
  const claimStore = useContext(ClaimsStore);
  const { claimsListToSubmit } = claimStore;
  
  const expenseTypeListCountNumber = useMemo(() => {
    if (claimsListToSubmit && claimsListToSubmit.length > 0) {
      return claimsListToSubmit?.filter((claim) =>
        possibleExpenseTypeCodes.includes(claim.ExpenseType)
      ).length;
    } else {
      return 0;
    }
  }, [claimsListToSubmit, possibleExpenseTypeCodes]);

  return (
    <div
      className={`${styles.expenseImgElement} ${
        selectedExpense === possibleExpenseTypeCodes[0]
          ? styles.expenseImgElementSelected
          : ""
      } ${
        isExpenseElementDisabled(possibleExpenseTypeCodes[0])
          ? styles.expenseImgElementDisabled
          : ""
      }`}
      style={{ flexBasis: "220px" }}
      onClick={() => {
        if (!showExpenseComponent) {
          setSelectedExpense(possibleExpenseTypeCodes[0]);
        }
      }}
    >
      <img src={img.maternityAllowance} alt={displayName} />
      <Label text={displayName} />
      {Number.isInteger(expenseTypeListCountNumber) &&
        expenseTypeListCountNumber > 0 && (
          <span className={styles.claimCountingBadge}>
            {expenseTypeListCountNumber}
          </span>
        )}
    </div>
  );
};

export default MaternityExpenseTypeItem;
