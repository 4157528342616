import React, { useState, useEffect } from "react";

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = useState(sessionStorage.getItem(localStorageKey) || "");

  useEffect(() => {
    sessionStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

const LoginPage = () => {
  const [value, setValue] = useStateWithLocalStorage("userid");

  const onChange = (event) => setValue(event.target.value);

  return (
    <div>
      <h2>
        <b>Login page</b>
      </h2>

      <div>
        <div>
          <div>
            <input value={value} type="text" onChange={onChange} />

            <div>
              <a href="/home" id="submit">
                submit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
