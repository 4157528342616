import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class InboxStore {
  messageList = [];
  messageCategories = [];
  messageDetails = {};
  nonArchivedMessages = [];
  importantMessageCount = 0;
  alertMessageAlerts = [];
  responseNonBulk;
  inboxHasContent = false;

  constructor() {
    makeObservable(this, {
      messageList: observable,
      messageCategories: observable,
      messageDetails: observable,
      nonArchivedMessages: observable,
      importantMessageCount: observable,
      alertMessageAlerts: observable,
      inboxHasContent: observable,

      inboxMessageList: action,
      getMessageDetails: action,
      isReadPost: action,
      headerMessageCount: action,
      NonBulkMessages: action,
    });
  }

  inboxMessageList = () => {
    this.inboxHasContent = false;
    const responceMessageList = agent.inbox.messages();
    responceMessageList
      .then((result) => {
        if (result.Messages) {
          this.messageList = [...result.Messages];
          this.messageCategories = [...result.Categories];
          this.nonArchivedMessages = [
            ...result.Messages.filter((c) => c.IsArchived === false),
          ];
          this.importantMessageCount = result.Messages.filter(
            (c) => c.IsRead === false
          ).length;
          let alertMessages = result.Messages.filter((c) => c.IsAlert === true)
            .filter((c) => new Date(c.MessageExpireWhen) >= new Date())
            .sort((a, b) => {
              return (
                Number(new Date(b.MessageExpireWhen)) -
                Number(new Date(a.MessageExpireWhen))
              );
            });
          this.alertMessageAlerts = alertMessages.slice(0, 3);
        }
        this.inboxHasContent = true;
      })
      .catch((error) => {
        this.inboxHasContent = true;
      });
  };

  getMessageDetails = (aliasPath) => {
    this.inboxHasContent = false;
    const messsageDetailsResponse = agent.inbox.messageDetails(aliasPath);
    messsageDetailsResponse
      .then((result) => {
        if (result) {
          this.messageDetails = { ...result };
        }
        this.inboxHasContent = true;
      })
      .catch((error) => {
        this.inboxHasContent = true;
      });
  };

  headerMessageCount = (value) => {
    this.importantMessageCount = value;
  };

  isReadPost = (DocumentId, action, ClassName) => {
    let actionType = "message_";
    if (action === "archive") {
      actionType = actionType + "archive";
    }
    if (action === "read") {
      actionType = actionType + "read";
    }
    let body = {
      ClassName: ClassName,
      ActionName: actionType,
      ObjectId: DocumentId,
    };
    const isReadResponse = agent.inbox.isReadStatus(body);
    isReadResponse.then((result) => {
      if (result) {
        const message = this.messageList.find(
          (m) => m.DocumentId === DocumentId && m.ClassName === ClassName
        );
        if (message) {
          if (action === "archive") {
            message.IsArchived = true;
            this.nonArchivedMessages = this.nonArchivedMessages.filter(
              (m) => !(m.DocumentId === DocumentId && m.ClassName === ClassName)
            );
          }
          if (action === "read") {
            message.IsRead = true;
          }
        }
      }
    });
  };

  NonBulkMessages = () => {
    let response;
    response = agent.inbox.registerNonBulk();
    response
      .then((result) => {
        if (result) {
          this.responseNonBulk = { ...result };
          this.inboxMessageList();
        }
      })
      .catch((error) => {
        console.log(error, "from error");
        this.inboxMessageList();
      });
  };

  loaderAction = (val) => {
    this.loaderActive = val;
  };
}
export default createContext(new InboxStore());
