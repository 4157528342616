import React, { Fragment, useContext } from "react";
import MouseListener from "../Hooks/MouseListener";
import TimeoutModal from "../Components/CommonComp/TimeoutModal";
import * as Msal from "msal";
import B2cStore from "../Stores/B2cStore";

const Completionist = () => {
  const b2cStore = useContext(B2cStore);
  const { msalConfig } = b2cStore;
  const authService = new Msal.UserAgentApplication(msalConfig);
  sessionStorage.removeItem("client");
  sessionStorage.removeItem("userName");
  sessionStorage.removeItem("support");
  sessionStorage.removeItem("LoginLog");
  authService.logout();
};

const CountDown = () => {
  let { minutes, seconds } = MouseListener();
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (
    <Fragment>
      {seconds == "00" && minutes == 0 ? (
        <Completionist />
      ) : (
        <>
          <span>
            {minutes}:{seconds}
          </span>
          {minutes === 0 && seconds < 30 ? <TimeoutModal /> : null}
        </>
      )}
    </Fragment>
  );
};

export default CountDown;
