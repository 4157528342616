import React, { useEffect, useState } from "react";
import { IconButton, Modal } from "@nn-design-system/react-component-library";
import Survey from "./Survey";

const SurveyModal = ({ surveyContents, isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  if (!surveyContents) {
    return null;
  }

  function handleClickClose(event) {
    setIsModalOpen(false);
    onClose(event, "clickButton");
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      width="50vw"
      height="80vh"
      hasBackdrop
    >
      <div className="survey-modal-wrapper">
        <div className="survey-modal-close-button">
          <IconButton onClick={handleClickClose} iconName="Cross" />
        </div>
        <Survey surveyContents={surveyContents} isLoggedIn isInModal />
      </div>
    </Modal>
  );
};

export { SurveyModal };
