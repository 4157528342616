import React, { useContext, useEffect, useState } from "react";
import { PrefabModal, Button } from "@nn-design-system/react-component-library";
import dompurify from "dompurify";
import { observer } from "mobx-react-lite";
import ArticleStore from "../../Stores/ArticleStore";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import CookieStore from "../../Stores/CookieStore";
import { useTranslation } from "react-i18next";

const WelcomePopUp = () => {
  const { t } = useTranslation();
  const cookieStore = useContext(CookieStore);
  const { promoArticlesSettings } = cookieStore;
  const { articleList } = useContext(ArticleStore);

  const [modalShow, setModalShow] = useState(false);
  const [promoArticle, setPromoArticle] = useState();
  const sanitizer = dompurify.sanitize;

  const navigate = useNavigate();

  const closemodal = (detail, article) => {
    setModalShow(false);
    cookieStore.upDateWelcomeArticle(article.NodeId);
    cookieStore.updateShowModal();
    navigate(`/knowledgepoint/${detail}`, { state: toJS(article) });
  };

  const handleClose = (article) => {
    setModalShow(false);
    cookieStore.upDateWelcomeArticle(article.NodeId);
    cookieStore.updateShowModal();
  };

  useEffect(() => {
    if (articleList && promoArticlesSettings) {
      let promoArticle = articleList.find(
        (a) => a.NodeId === promoArticlesSettings.PromoArticleId,
      );
      setPromoArticle(promoArticle);
    }
  }, [articleList, promoArticlesSettings]);

  useEffect(() => {
    if (promoArticlesSettings) {
      if (promoArticlesSettings.ViewedByUser) {
        setModalShow(false);
      } else {
        setModalShow(true);
      }
    }
  }, [promoArticlesSettings]);

  return (
    <>
      {promoArticlesSettings && promoArticle ? (
        <>
          <PrefabModal
            isOpen={modalShow}
            hasCloseButton
            children={
              <div className="welcome-popup">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(promoArticlesSettings.PopupContent, {
                      ADD_ATTR: ["target"],
                    }),
                  }}
                />
                <Button
                  mt={"20px"}
                  onClick={() => closemodal("details", promoArticle)}
                  variant="PrimaryMedium"
                >
                  {t("welcome.find-out-here")}
                </Button>
              </div>
            }
            onClose={() => handleClose(promoArticle)}
            width="850px"
            height="auto"
            headingText={" "}
          />
        </>
      ) : null}
    </>
  );
};

export default observer(WelcomePopUp);
