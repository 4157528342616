import React, { Fragment } from "react";
import InboxToggleComp from "./InboxToggleComp";
import PageTheme from "../PageTheme";

const MyInbox = () => {
  return (
    <Fragment>
      <PageTheme />
      <InboxToggleComp />
    </Fragment>
  );
};
export default MyInbox;
