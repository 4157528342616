import React, { useEffect, useState } from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { PortfolioPerProductDetailPanel } from "./PortfolioPerProductDetailPanel";
import { ConvertDecimalToStringWithCurrencyMaxFractionDigits } from "../CommonComp/Functions";

export const PortFolioPerProductTable = ({
  element,
  originalData,
  totalSum,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (element) {
      setData([...element, {}]);
    }
  }, [element]);

  const columns = [
    {
      field: "ProductDescription",
      headerName: t("portfolio.insurance-plan"),
      flex: 2.2,
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      sortable: false,
      renderCell: (row) => {
        if (row.id < data.length - 1) return row.row.ProductDescription;
        return t("global.total");
      },
    },
    {
      field: "ContractNumber",
      headerName: t("portfolio.contract-number"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1,
      sortable: false,
    },
    {
      field: "totalSum",
      headerName: t("portfolio.net-price"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "NumberOfUnits",
      headerName: t("portfolio.share-number"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (rowData) => {
        return (
          rowData.id < data.length - 1 &&
          rowData.row.NumberOfUnits?.toLocaleString("el-GR", {
            maximumFractionDigits: 6,
          })
        );
      },
    },
    {
      field: "x",
      headerName: t("portfolio.investment-account-value"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1.6,
      sortable: false,
      renderCell: (rowData) => {
        if (rowData.id < data.length - 1)
          return ConvertDecimalToStringWithCurrencyMaxFractionDigits(
            rowData.row.TotalAmountInvestment,
          );
        return `${ConvertDecimalToStringWithCurrencyMaxFractionDigits(totalSum(element, rowData.row.Currency))}`;
      },
    },
    {
      field: "z",
      headerName: `${t("portfolio.investment-allocation")} (%)`,
      headerAlign: "left",
      align: "left",
      flex: 1.3,
      sortable: false,
      headerClassName: "textAlignLeft",
      renderCell: (rowData) => {
        if (rowData.id < data.length - 1) return rowData?.row?.totalSum;
        return rowData.row.FundPercentage;
      },
    },
  ];
  const rows = data?.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });

  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hasAutoHeight
      testId={"fund-portfolio-table"}
      getRowHeight={() => "auto"}
      getDetailPanelContent={(rowData) => {
        let filterDataByProduct = originalData?.filter(
          (el) => el.ContractNumber === rowData.row.ContractNumber,
        );
        if (rowData.id < data.length - 1)
          return (
            <div style={{ overflow: "hidden", paddingBottom: 50 }}>
              <PortfolioPerProductDetailPanel element={filterDataByProduct} />
            </div>
          );
      }}
      getDetailPanelHeight={() => {
        return "auto";
      }}
      hideFooter
    ></Table>
  );
};
