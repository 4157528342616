import { createContext } from "react";
import { action, makeObservable, observable } from "mobx";
import agent from "../Api/agent";

class CookieStore {
  cookies = null;
  activeGA = true;
  cookiesToBeRemoved = null;
  cookieHasContent = false;
  showCookies = null;
  promoArticlesSettings = null;
  contentPageSettings = null;
  marketingConsentSettings = {};
  dropDownContactValues = null;
  isCustomerSupport = null;
  hasCookies = false;
  isCustomerOfNNHellas = false;
  isCustomerOfNNHellas2 = false;

  constructor() {
    makeObservable(this, {
      cookies: observable,
      activeGA: observable,
      cookiesToBeRemoved: observable,
      cookieHasContent: observable,
      showCookies: observable,
      promoArticlesSettings: observable,
      isCustomerSupport: observable,
      hasCookies: observable,
      contentPageSettings: observable,
      marketingConsentSettings: observable,
      dropDownContactValues: observable,

      loadCookies: action,
      upDateCookies: action,
      upDateWelcomeArticle: action,
      updateShowModalTermPage: action,
      setValueForCustomerSupport: action,
      setCheckboxStateSMS: action,
      setCheckboxStateCall: action,
      setCheckboxStateEmail: action,
    });
  }

  loadCookies = () => {
    this.cookieHasContent = false;
    const allCookies = agent.cookies.getCookies();
    allCookies
      .then((result) => {
        if (result) {
          this.hasCookies = false;
          this.isCustomerSupport = result.Roles.IsCustomerSupport;

          if (result.Roles.IsCustomerSupport) {
            sessionStorage.setItem("support", result.Roles.IsCustomerSupport);
            //edw einai na allaxei
            let name = result.FullName.split(" ");
            sessionStorage.setItem("userName", name[0] + " " + name[1]);
          }
          this.cookies = { ...result };
          this.showCookies = result.CookieSettings.ActedOn;
          result.CookieSettings.CookieGroups.map((cookieElement, i) => {
            this.hasCookies = true;
            if (
              cookieElement.Title === "Cookies για στατιστικές επισκεψιμότητας"
            ) {
              if (cookieElement.CookiePreference.State === 2) {
                this.activeGA = false;
                this.cookiesToBeRemoved = [...cookieElement.Cookies];
              } else {
                this.activeGA = true;
              }
            }
          });
          if (result.PromoArticlesSettings) {
            this.promoArticlesSettings = { ...result.PromoArticlesSettings };
          }

          if (result.ContentPageSettings) {
            this.contentPageSettings = { ...result.ContentPageSettings };
          }

          if (result.MarketingConsentSettings) {
            this.marketingConsentSettings = {
              ...result.MarketingConsentSettings,
            };
          }
          if (result.AllowedApplications) {
            this.isCustomerOfNNHellas =
              result.AllowedApplications.includes("myNN");
            this.isCustomerOfNNHellas2 =
              result.AllowedApplications.includes("eServices");
          }
          if (result.ContactFormDropDownSubjects) {
            this.dropDownContactValues = [
              ...result.ContactFormDropDownSubjects,
            ];
          }
        }
        this.cookieHasContent = true;
      })
      .catch((error) => {
        this.cookieHasContent = true;
      });
  };

  upDateCookies = (body) => {
    const updateCookies = agent.cookies.updateCookies(body);
    updateCookies
      .then((result) => {
        if (result.status === 200) {
          body.CookieSettings.CookieGroups.map((cookieElement, i) => {
            if (
              cookieElement.Title === "Cookies για στατιστικές επισκεψιμότητας"
            ) {
              if (cookieElement.CookiePreference.State === 2) {
                this.activeGA = false;
                this.cookiesToBeRemoved = [...cookieElement.Cookies];
              } else {
                this.activeGA = true;
              }
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  upDateWelcomeArticle = (articleId) => {
    var body = {
      PromoArticleId: articleId,
    };

    const updateCookies = agent.cookies.updateWelcomePopUp(body);
    updateCookies
      .then((result) => {
        //do nothing
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setCookies = (body) => {
    this.cookies = body;
  };

  updateShowModal = () => {
    this.promoArticlesSettings.ViewedByUser = true;
  };

  updateShowModalTermPage = () => {
    this.contentPageSettings.AcceptedByUser = true;
    this.marketingConsentSettings.ContactedConsent = true;
  };

  updateShowModalConsent = () => {
    this.MarketingConsentSettings.ContactedConsent = true;
  };

  setCheckboxStateSMS = (value) => {
    this.marketingConsentSettings.ConsentSMS = value;
  };
  setCheckboxStateEmail = (value) => {
    this.marketingConsentSettings.ConsentEmail = value;
  };
  setCheckboxStateCall = (value) => {
    this.marketingConsentSettings.ConsentCall = value;
  };

  setValueForCustomerSupport = (value) => {
    this.isCustomerSupport = value;
  };
}

export default createContext(new CookieStore());
