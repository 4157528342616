import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import NavBarStore from "../Stores/NavBarStore";
import {
  NAVBAR_ITEMS,
  NAVBAR_ITEMS_MOBILE,
  NAVBAR_ITEMS_HR,
} from "./constants";

function useNavBar() {
  const showStaticNav = String(process.env.REACT_APP_STATIC_TOP_NAV) === "1";
  const { t } = useTranslation();
  const layoutStore = useContext(NavBarStore);
  const { navBarItems, navBarItemsHR, navBarItemsMobile } = layoutStore;
  const staticNavBarItems = NAVBAR_ITEMS.map((item) => ({
    ...item,
    Title: t(`navigation.item-${item.NodeAlias}`),
  }));
  const staticNavBarItemsMobile = NAVBAR_ITEMS_MOBILE.map((item) => ({
    ...item,
    Title: t(`navigation.item-${item.NodeAlias}`),
  }));
  const staticNavBarItemsHR = NAVBAR_ITEMS_HR.map((item) => ({
    ...item,
    Title: t(`navigation.item-${item.NodeAlias}`),
  }));

  useEffect(() => {
    let ignore = false;

    if (!showStaticNav && !ignore) {
      layoutStore.loadNavbarItems();
      layoutStore.loadHeaders();
    }

    return () => {
      ignore = true;
    };
  }, [layoutStore, showStaticNav]);

  return {
    navBarItems: showStaticNav ? staticNavBarItems : navBarItems,
    navBarItemsMobile: showStaticNav
      ? staticNavBarItemsMobile
      : navBarItemsMobile,
    navBarItemsHR: showStaticNav ? staticNavBarItemsHR : navBarItemsHR,
  };
}

export { useNavBar };
