import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Paragraph,
  Button,
  List,
  ListItem,
  Label,
  Icon,
  RadioButtonGroup,
  RadioButton,
  AttentionBlock,
  Link,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import "../../Css/Kyc/kyc.css";
import AccountKycButton from "../Account/AccountKycButton";
import KycStore from "../../Stores/KycStore";
import AccountStore from "../../Stores/AccountStore";

const InfoKyc = () => {
  const { t } = useTranslation();
  const { kycUrl, getKycUrl, kycUrlIsLoading } = useContext(KycStore);
  const { accountData } = useContext(AccountStore);
  const navigate = useNavigate();
  const [radioBtnValue, setRadioBtnValue] = useState("");
  const [notificationError, setNotificationError] = useState(false);

  useEffect(() => {
    if (kycUrl?.KYCUrl) {
      window.location.href = kycUrl.KYCUrl;
    }
  }, [kycUrl]);

  const DynamicList = () => {
    let valuesString = "";
    if (radioBtnValue && JSON.parse(radioBtnValue)) {
      valuesString = "identity,income,contactInfo,professionalActivity";
    } else {
      valuesString = "identity,contactInfo,professionalActivity";
    }
    if (valuesString) {
      const valuesArray = valuesString.split(",");
      return (
        <List>
          {valuesArray.map((value, index) => (
            <ListItem key={index} variant="Default">
              {getValueLabel(value)}
            </ListItem>
          ))}
        </List>
      );
    }
  };

  const getValueLabel = (value) => {
    switch (value) {
      case "identity":
        return t("kyc.identity");
      case "income":
        return t("kyc.income-details");
      case "contactInfo":
        return t("kyc.contact-information");
      case "professionalActivity":
        return t("kyc.details-professional-activity");
      default:
        return "";
    }
  };
  return (
    <>
      <article className="info-kyc kyc-customer-status">
        <Label text={`${t("kyc.customer-status")}:`}></Label>
        {accountData.KYCVerificationDate ? (
          <div className="kyc-status">
            <Icon name="Checkmark" variant="Small" color="green" />
            <Paragraph hasMargin={false}>{t("kyc.updated")}</Paragraph>
          </div>
        ) : (
          <div className="kyc-status">
            <Icon name="Cross" variant="Small" color="red" />
            <Paragraph hasMargin={false}>{t("kyc.not-updated")}</Paragraph>
          </div>
        )}
      </article>
      <article className="info-kyc">
        <Paragraph>{t("kyc.update-data-msg")}</Paragraph>
        <Paragraph>{t("kyc.process-for-update-msg")}</Paragraph>
        <Paragraph mb="32px">{t("kyc.can-update-information-msg")}</Paragraph>
        <RadioButtonGroup
          labelText={t("kyc.select-items-wish-update")}
          onChange={(ev) => {
            setRadioBtnValue(ev.target.value);
            setNotificationError(false);
          }}
          value={radioBtnValue}
        >
          <div className="radiogroup_kyc">
            <RadioButton labelText={t("kyc.full-data-update")} value={true} />
            <RadioButton
              mt="12px"
              labelText={t("kyc.info-radio-btn-false-msg")}
              value={false}
            />
          </div>
        </RadioButtonGroup>
        {notificationError && (
          <AttentionBlock variant="Error">
            <Paragraph>{t("kyc.select-type-update")}</Paragraph>
          </AttentionBlock>
        )}
        {radioBtnValue && (
          <Paragraph mb="24px">{t("kyc.information-updated")}</Paragraph>
        )}
        {radioBtnValue && DynamicList(kycUrl?.KYCquery)}
        <Paragraph mb="32px">{t("kyc.update-details-msg")}</Paragraph>
        <List>
          <ListItem variant="Default">
            {t("kyc.access-codes-taxisnet")}
          </ListItem>
          <ListItem variant="Default">
            {t("kyc.certified-info-details")}
            <Link
              href=" https://notify.gov.gr"
              testId="link-test-id"
              text=" (https://notify.gov.gr)"
              variant="SecondaryStrong"
            />{" "}
            {t("kyc.re-login-msg")}
          </ListItem>
        </List>
      </article>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          variant="SecondaryMedium"
          onClick={() => {
            navigate("/myAccount");
          }}
          mt="35px"
        >
          {t("global.return")}
        </Button>
        <AccountKycButton
          isLoading={kycUrlIsLoading}
          hasVatNumber={true}
          onClick={() => {
            if (radioBtnValue) {
              getKycUrl({ hasKycAllParams: radioBtnValue });
            } else {
              setNotificationError(true);
            }
          }}
        />
      </div>
    </>
  );
};

export default observer(InfoKyc);
