import React, { useState, useEffect, useContext } from "react";
import { Label } from "@nn-design-system/react-component-library";
import GroupPensionAnalysisNNTable from "./GroupPensionAnalysisNNTable";
import GroupPensionAnalysisList from "./GroupPensionAnalysisList";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";
import ContractStore from "../../Stores/ContractStore";
import LoaderStore from "../../Stores/LoaderStore";
import {
  VictoryChart,
  VictoryBar,
  VictoryGroup,
  VictoryTheme,
  VictoryTooltip,
} from "victory";
import { useTranslation } from "react-i18next";
import styles from "../../Css/GroupPension/GroupPension.module.scss";
import GroupPensionDoughnut from "./GroupPensionDoughnut";
import { decimalToPercentage } from "../CommonComp/Functions";
import { colorsList } from "../HR/Utils/Constants";

const GroupPensionAnalysis = (props) => {
  const { t } = useTranslation();
  const contractStore = useContext(ContractStore);
  const { setMiniLoaderForData3 } = useContext(LoaderStore);
  const {
    hasGroupPensionAnalysisData,
    groupPensionAnalysisData,
    groupPensionCertificatesErrorMsg,
  } = contractStore;
  const [tableData, setTableData] = useState([]);
  const [listData, setListData] = useState([]);
  const [investmentValuesData, setInvestmentValuesData] = useState([]);
  const [investmentBarOptionsData, setInvestmentBarOptionsData] = useState([]);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (props.contractId) {
      contractStore.loadGroupPensionAnalysisData(props.contractId);
    }
  }, [props.contractId, contractStore]);

  useEffect(() => {
    const selectedContractPensionAnalysisData = groupPensionAnalysisData.find(
      (elem) => elem.contractId === props.contractId,
    );
    if (hasGroupPensionAnalysisData && selectedContractPensionAnalysisData) {
      if (
        selectedContractPensionAnalysisData.Investments &&
        selectedContractPensionAnalysisData.InvestmentOptionsGroups
      ) {
        const contractsInvestmentData = {
          investments: selectedContractPensionAnalysisData.Investments,
          balanceLastUpdateOn:
            selectedContractPensionAnalysisData.BalanceLastUpdateOn,
        };

        const investmentDoughnutData = contractsInvestmentData.investments
          .filter((elem) => !elem.IsChild && elem.InvestmentType)
          .map((elem) => {
            return {
              x: elem.InvestmentType,
              y: elem.DistributionPercentage,
              totalAmount: elem.TotalAmount,
            };
          });
        setListData(contractsInvestmentData);

        if (
          contractsInvestmentData.investments &&
          contractsInvestmentData.investments.length > 0 &&
          contractsInvestmentData.investments[
            contractsInvestmentData.investments.length - 1
          ].TotalShares !== -1
        ) {
          contractsInvestmentData.investments.push({
            InvestmentType: "",
            EmployerContribution:
              selectedContractPensionAnalysisData.TotalEmployerContribution,
            VoluntaryContribution:
              selectedContractPensionAnalysisData.TotalVoluntaryContribution,
            TotalAmount: selectedContractPensionAnalysisData.GrandTotalAmount,
            TotalShares: -1,
            SharePrice: -1,
            DistributionPercentage: -1,
            SharePriceHistory: undefined,
          });
        }
        setTableData(contractsInvestmentData);
        setInvestmentValuesData(
          investmentDoughnutData.sort((a, b) => a.x.localeCompare(b.x)),
        );
        const employersInvestmentBarOptionsData =
          selectedContractPensionAnalysisData.InvestmentOptionsGroups.find(
            (elem) =>
              elem.InvestmentOptionsGroupName === t("contract.employers"),
          );
        const voluntaryInvestmentBarOptionsData =
          selectedContractPensionAnalysisData.InvestmentOptionsGroups.find(
            (elem) =>
              elem.InvestmentOptionsGroupName === t("contract.voluntary"),
          );
        if (
          employersInvestmentBarOptionsData.InvestmentOptions.length ===
          voluntaryInvestmentBarOptionsData.InvestmentOptions.length
        ) {
          let tempInvestmentBarOptionsData = [];
          for (
            let iterationIndex = 0;
            iterationIndex <
            employersInvestmentBarOptionsData.InvestmentOptions.length;
            iterationIndex++
          ) {
            tempInvestmentBarOptionsData.push({
              employersInvestmentBarValue:
                employersInvestmentBarOptionsData.InvestmentOptions[
                  iterationIndex
                ].InvestmentOptionValue,
              voluntaryInvestmentBarValue:
                voluntaryInvestmentBarOptionsData.InvestmentOptions[
                  iterationIndex
                ].InvestmentOptionValue,
              InvestmentOptionType:
                employersInvestmentBarOptionsData.InvestmentOptions[
                  iterationIndex
                ].InvestmentOptionType,
            });
          }
          setInvestmentBarOptionsData(
            [...tempInvestmentBarOptionsData].sort((a, b) =>
              a.InvestmentOptionType.localeCompare(b.InvestmentOptionType),
            ),
          );
        }
      }
      setMiniLoaderForData3(false);
    } else {
      if (groupPensionCertificatesErrorMsg) return setMiniLoaderForData3(false);
      setMiniLoaderForData3(true);
    }
  }, [
    hasGroupPensionAnalysisData,
    groupPensionAnalysisData,
    props.contractId,
    setMiniLoaderForData3,
    setInvestmentValuesData,
    setInvestmentBarOptionsData,
  ]);

  useEffect(() => {
    if (width < 580) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  return (
    <>
      {props.showData && (
        <>
          {!groupPensionCertificatesErrorMsg && (
            <div className={styles.analysisChartSection}>
              <div className={styles.analysisChartElement}>
                <Label text={t("contract.allow-investments")} />
                <div className={styles.analysisChartSeparator} />
                <GroupPensionDoughnut data={investmentValuesData} />
              </div>
              <div className={styles.analysisChartElement}>
                <Label text={t("contract.current-investments-options")} />
                <div className={styles.analysisChartSeparator} />
                <VictoryChart
                  theme={VictoryTheme.material}
                  domainPadding={{ x: [140, 140] }}
                  width={430}
                  height={400}
                >
                  <VictoryGroup
                    offset={100 / investmentBarOptionsData.length}
                    colorScale={"qualitative"}
                  >
                    {investmentBarOptionsData?.map((elem, index) => {
                      return (
                        <VictoryBar
                          key={index}
                          barWidth={() => 100 / investmentBarOptionsData.length}
                          style={{
                            data: {
                              fill: ({ datum }) => datum.color,
                            },
                          }}
                          data={[
                            {
                              x: t("contract.employers"),
                              y: elem.employersInvestmentBarValue,
                              label: `${elem.InvestmentOptionType}:\n${decimalToPercentage(parseFloat(elem.employersInvestmentBarValue * 100).toFixed(0))}`,
                              color: colorsList[index],
                            },
                            {
                              x: t("contract.voluntary"),
                              y: elem.voluntaryInvestmentBarValue,
                              label: `${elem.InvestmentOptionType}:\n${decimalToPercentage(parseFloat(elem.voluntaryInvestmentBarValue * 100).toFixed(0))}`,
                              color: colorsList[index],
                            },
                          ]}
                          labelComponent={
                            <VictoryTooltip
                              cornerRadius={0}
                              style={{
                                fontSize: 10,
                                fontWeight: 100,
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </VictoryGroup>
                </VictoryChart>
              </div>
            </div>
          )}

          <div>
            {isSmallSize ? (
              <GroupPensionAnalysisList tableData={listData.investments} />
            ) : (
              <GroupPensionAnalysisNNTable
                errMsg={groupPensionCertificatesErrorMsg}
                tableData={tableData.investments}
              />
            )}
          </div>
          <div className={styles.chartDisclaimerAnalysis}>
            {tableData.balanceLastUpdateOn && (
              <span>
                {t("contract.last-balance-update")}:{" "}
                {tableData.balanceLastUpdateOn}
              </span>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GroupPensionAnalysis;
