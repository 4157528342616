import React from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { getMonthYear } from "../CommonComp/Functions";

export const AccountPaymentsIn = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "a",
      headerName: t("account.type"),
      flex: 0.8,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value === "Direct Debit"
              ? t("account.standing-order")
              : t("account.credit-card")}
          </span>
        );
      },
    },
    {
      field: "b",
      headerName: "IBAN",
      headerAlign: "left",
      align: "left",
      flex: 2.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value.ProfileType === "Direct Debit"
              ? params.value.Iban
              : ""}
          </span>
        );
      },
    },
    {
      field: "c",
      headerName: t("account.account-number"),
      headerAlign: "left",
      align: "left",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "d",
      headerName: t("account.bank"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "e",
      headerName: t("account.beneficiary"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "f",
      headerName: t("account.date-expiration"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value ? getMonthYear(params.value) : "-"}</span>;
      },
    },
  ];

  const rows = data.map((e, i) => {
    return {
      id: i,
      a: e.ProfileType,
      b: { ProfileType: e.ProfileType, Iban: e.Iban },
      c: e.AccountNumber,
      d: e.BankName,
      e: e.AccountHolder,
      f: e.ExpirationDate,
    };
  });

  return (
    <Table
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      localeText={{ noRowsLabel: t("global.no-results-found") }}
    />
  );
};
