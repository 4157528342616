import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SpecialPagesStore from "../../Stores/SpecialPagesStore";
import AccountRegLoginHeader from "../Header/AccountRegLoginHeader";
import dompurify from "dompurify";
import UserStore from "../../Stores/UserStore";

const ExpiredPage = () => {
  const specialPagesStore = useContext(SpecialPagesStore);
  const { isAuthorized } = useContext(UserStore);
  const { specialPageResponse } = specialPagesStore;
  const [content, setContent] = useState();

  const path = useLocation();

  const sanitizer = dompurify.sanitize;

  useEffect(() => {
    specialPagesStore.loadSpecialPage(path.pathname);
  }, []);

  useEffect(() => {
    if (specialPageResponse) {
      setContent(specialPageResponse);
    }
  }, [specialPageResponse]);

  return (
    <>
      {content ? (
        <>
          <div className="nn-container">
            {isAuthorized ? null : (
              <header>
                <AccountRegLoginHeader />
              </header>
            )}
            <div id="registration-body">
              <div dangerouslySetInnerHTML={{ __html: sanitizer(content.Text, { ADD_ATTR: ["target"] }) }} />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default observer(ExpiredPage);
