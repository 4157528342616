import React, { useContext, useEffect } from "react";
import ContractStore from "../../Stores/ContractStore";
import PensionAccountContentForVisibleFields from "./PensionAccountContentForVisibleFields";
import PensionAccountContent from "./PensionAccountContent";
import SpinnerSmall from "../CommonComp/SpinnerSmall";

const ContractSelectedAccountPension = (props) => {
  const contractStore = useContext(ContractStore);
  const { showBillStore } = contractStore;

  useEffect(() => {
    if (props.contract.Number && props.getData) {
      contractStore.loadBill(props.contract.Number, props.contract.ProductCode, props.contract.ProductCategoryId);
    }
  }, [props.contract.Number, props.getData]);

  return (
    <>
      {showBillStore ? (
        <>
          {props.contractDetails.VisibleFields ? (
            <>
              <PensionAccountContentForVisibleFields
                contractDetails={props.contractDetails}
                contract={props.contract}
                OsekaLinks={props.OsekaLinks}
              />
            </>
          ) : (
            <PensionAccountContent contractDetails={props.contractDetails} contract={props.contract} OsekaLinks={props.OsekaLinks} />
          )}
        </>
      ) : (
        <SpinnerSmall />
      )}
    </>
  );
};

export default ContractSelectedAccountPension;
