import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import agent from "../../Api/agent";
import UrlStore from "../../Stores/UrlStore";
import ArticleDetails from "../Articles/ArticleDetails";
import InboxMessageDetails from "../Inbox/InboxMessageDetails";
import PageTheme from "../PageTheme";
import PreviewPage from "./PreviewPage";

const Preview = () => {
  const urlStore = useContext(UrlStore);
  const { baseURL } = urlStore;
  const [componentName, setComponentName] = useState();
  const [data, setData] = useState();
  const location = useLocation();

  const getId = (url) => {
    if (url) {
      let contractKey = url.split("/");
      if (contractKey.length === 3) {
        return contractKey[2];
      } else {
        console.log("wrong Key", contractKey);
      }
    }
  };

  useEffect(() => {
    if (baseURL.length === 0) {
      console.log("loadurl");
      urlStore.loadURL(process.env.REACT_APP_ENVIRONMENT);
    }
  }, [baseURL]);

  useEffect(() => {
    const urlKey = getId(location.pathname);
    console.log(urlKey);
    const test = agent.preview.getPreviewElement(urlKey);
    test.then((result) => {
      if (result) {
        if (result.ClassName) {
          setComponentName(result.ClassName);
          const datatest = { ...result };
          setData(datatest);
        } else {
          setData(result);
        }
      }
    });
  }, []);

  if (componentName === "NN.Message" && data) {
    return <InboxMessageDetails data={data} />;
  } else if (componentName === "NN.Article" && data) {
    return <ArticleDetails data={data} />;
  } else if (componentName === "NN.Page") {
    return <PageTheme data={data} />;
  } else {
    return <PreviewPage data={data} />;
  }
};

export default Preview;
