import React, { useState, useContext, useEffect } from "react";
import {
  Heading,
  Button,
  InlineMessage,
  Paragraph,
} from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import UnregisterStore from "../../Stores/UnregisterStore";
import { RadiogroupGridSection } from "./RadiogroupGridSection";
import { Section } from "./SurveySection";
import { useFormContext } from "./context";
import i18n from "../../i18n";

// TODO Simplify validation after changing the requirement to make Score reasons not required
function validate(values, rules) {
  const errors = {};
  Object.keys(rules).forEach((key) => {
    if (!values[key] || !values[key].value) {
      errors[key] = i18n.t("survey.field-required");
    }

    if (
      rules[key].isTextRequired &&
      values[key]?.value === "false" &&
      !values[key]?.text
    ) {
      errors[key] = i18n.t("survey.field-required");
    }
  });

  return errors;
}

const Form = ({ survey }) => {
  const { t } = useTranslation();

  const { Sections, Title, IntroText } = survey;
  const { submitSurvey, surveyResult } = useContext(UnregisterStore);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formData, validation, setErrors } = useFormContext();

  useEffect(() => {
    if (isSubmitting && surveyResult) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, surveyResult]);

  function handleSubmit(event) {
    event.preventDefault();

    const { contractNumber, customerID, values, notificationTypeDescription } =
      formData;
    const questionIds = Object.keys(values);
    const validationErrors = validate(values, validation);

    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }

    const answers = questionIds.map((id) => {
      const { value, text } = values[id];
      return {
        QuestionId: id,
        AnswerValue: value,
        AnswerText: text,
      };
    });

    setIsSubmitting(true);

    // Send answers
    submitSurvey({
      ContractNumber: contractNumber,
      CustomerId: customerID,
      Answers: answers,
      SurveyNotificationDescription: notificationTypeDescription,
      // This field is hardcoded
      ServiceRequestSourceAnswer: "Email",
    });
  }

  if (surveyResult?.Success) {
    const success = (
      <Paragraph mt="0">
        {i18n.t("survey.thanks-msg")} <br /> Η {i18n.t("survey.team-nn")}
      </Paragraph>
    );
    const message = surveyResult.Message ? (
      <Paragraph mt="0">{t("survey.answers-already-sent")}</Paragraph>
    ) : (
      success
    );
    return <div>{message}</div>;
  }

  return (
    <>
      <Heading variant="XXL" mt="0">
        {Title}
      </Heading>

      <Paragraph>{IntroText}</Paragraph>

      <form onSubmit={handleSubmit}>
        {Sections.map(({ Title, TitleText, Blocks }) => {
          const isRadiogroupGrid = Blocks.every(({ FormItems }) => {
            return FormItems.length === 1 && FormItems[0].Type === "radiogroup";
          });

          return (
            <section key={Title}>
              <h2 className="section-title">{Title}</h2>
              {TitleText && (
                <div className="radiogroup-grid-question">
                  <strong>{TitleText}</strong>
                </div>
              )}
              {isRadiogroupGrid ? (
                <RadiogroupGridSection blocks={Blocks} />
              ) : (
                <Section blocks={Blocks} />
              )}
            </section>
          );
        })}
        <div className="survey-submit">
          <Button
            type="Submit"
            variant="PrimaryMedium"
            isLoading={isSubmitting}
          >
            {t("survey.submit-answer")}
          </Button>
        </div>
        {surveyResult?.Success === false && (
          <InlineMessage
            text={i18n.t("survey.problem-ocurred")}
            variant="Error"
            mt="24px"
          />
        )}
      </form>
    </>
  );
};

export default observer(Form);
