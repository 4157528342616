import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, TextInput } from "@nn-design-system/react-component-library";
import img from "../../assets/index";
import "../../Css/CustomerSupport/customerSearch.css";
import CookieStore from "../../Stores/CookieStore";
import UserStore from "../../Stores/UserStore";

const CustomerSearch = () => {
  const { t } = useTranslation();
  const userStore = useContext(UserStore);
  const { impersonateUserResponse } = userStore;
  const cookieStore = useContext(CookieStore);
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const onChange = (event) => {
    if (event.target.id === "email-input") {
      setUserEmail(event.target.value);
      if (errorMessage) {
        setErrorMessage("");
      }
    }
  };

  useEffect(() => {
    if (impersonateUserResponse) {
      if (impersonateUserResponse.Status === 1) {
        setErrorMessage(impersonateUserResponse.Message);
      } else {
        cookieStore.loadCookies();
        navigate(`/home`);
        setErrorMessage("");
      }
    }
  }, [impersonateUserResponse]);

  const sendEmail = () => {
    userStore.getImpersonateUser(userEmail);
  };

  return (
    <>
      <div id="customerSearch">
        <div class="nn-container registration">
          <div class="left">
            <div>
              <img src={img.search_vis} alt="registration" />
            </div>
          </div>
          <div class="right">
            <div class="top">
              <div>
                <h1>
                  <span class="light">{t("customer.welcome")}</span>{" "}
                  <span class="normal">{t("customer.on-search-page")}</span>{" "}
                  <span class="dark">{t("customer.its-users")} myNN</span>
                </h1>
                <p>{t("customer.enter-customer-email")}</p>
                <div class="cs-form-element">
                  <TextInput
                    labelText={t("customer.email")}
                    name="name"
                    onChange={onChange}
                    id="email-input"
                    value={userEmail}
                    placeholderText={t("customer.fill-in-customer-email")}
                  />
                </div>{" "}
                <Button mt="-3px" variant="PrimaryMedium" onClick={sendEmail}>
                  <a>{t("customer.login-as-customer")}</a>
                </Button>
              </div>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(CustomerSearch);
