import { Icon } from "@nn-design-system/react-component-library";
import React from "react";
import { useTranslation } from "react-i18next";

const ContractSelectedOseka = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.OsekaInvestmentLinks ? (
        <div className="account-oseka">
          <p>{t("contract.links-documents-info-investments")}</p>
          {props.OsekaInvestmentLinks.length !== 0 ? (
            props.OsekaInvestmentLinks.map((sudoListElement, i) => {
              return (
                <div key={i} className="chevron-link">
                  <a href={sudoListElement.Url} target={sudoListElement.Target}>
                    <span>{sudoListElement.Title}</span>
                    <div>
                      <Icon
                        name="ChevronRight"
                        variant="Small"
                        color="#EE7F00"
                      />
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <span>-</span>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ContractSelectedOseka;
