import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Popover } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import HeaderMessage from "./HeaderMessage";
import HeaderImage from "../Components/HeaderImage";
import ClaimsStore from "../Stores/ClaimsStore";
import SettingsStore from "../Stores/SettingsStore";
import "../Css/selected-contract-overview.css";
import "../Css/CommonCss/General.css";
import "../Css/Myheaders.css";
import "../Css/page-theme.css";
import AccountKycButton from "./Account/AccountKycButton";
import AccountStore from "../Stores/AccountStore";
import ContractStore from "../Stores/ContractStore";
import { useTranslations as useTranslationsNN } from "../Context/i18n";

const PageTheme = (props) => {
  const { t } = useTranslation();
  const { language } = useTranslationsNN();
  const [showMessage, setShowMessage] = useState(true);
  const { privateSettings } = useContext(SettingsStore);
  const { portfolioIsShown } = useContext(ContractStore);

  const {
    groupPoliciesWithEligibilitySubmissionHasContent,
    groupPoliciesWithEligibilitySubmission,
  } = useContext(ClaimsStore);
  const { accountData } = useContext(AccountStore);

  const path = useLocation();
  const navigate = useNavigate();

  const notEligibleMessage = useMemo(() => {
    if (privateSettings?.eClaims?.ResourceStrings) {
      return DOMPurify.sanitize(
        privateSettings.eClaims.ResourceStrings[
          "NN.Claim.Submission.NotEligibleMessage"
        ],
        {
          USE_PROFILES: { html: true },
        },
      );
    }
  }, [privateSettings?.eClaims?.ResourceStrings]);

  useEffect(() => {
    if (path.pathname.includes("details")) {
      setShowMessage(false);
    } else {
      setShowMessage(true);
    }
  }, [path]);

  const isClaimSubmission = useMemo(() => {
    return !!(
      path.pathname.includes("myClaims") &&
      groupPoliciesWithEligibilitySubmissionHasContent &&
      groupPoliciesWithEligibilitySubmission &&
      groupPoliciesWithEligibilitySubmission.length > 0 &&
      groupPoliciesWithEligibilitySubmission.some(
        (groupPolicy) => groupPolicy.IsEligibleForSubmission,
      )
    );
  }, [
    groupPoliciesWithEligibilitySubmission,
    groupPoliciesWithEligibilitySubmissionHasContent,
    path.pathname,
  ]);

  const renderClaimSubmissionButton = useMemo(() => {
    if (isClaimSubmission) {
      return (
        <div>
          <Button
            variant="PrimarySmall"
            mt="35px"
            onClick={(event) => {
              event.preventDefault();
              navigate("/myClaims/submission");
            }}
          >
            {t("claim.new-entry")}
          </Button>
        </div>
      );
    } else if (
      path.pathname.includes("myClaims") &&
      groupPoliciesWithEligibilitySubmissionHasContent &&
      groupPoliciesWithEligibilitySubmission &&
      groupPoliciesWithEligibilitySubmission.length > 0
    ) {
      return (
        <div className="claimSubmissionButton">
          <Popover
            variant="Link"
            headingText=" "
            linkVariantProps={{ text: t("claim.new-entry") }}
            placement="bottom-end"
          >
            <div className="claimSubmissionPopover">
              <p
                className="htmlParagraph"
                dangerouslySetInnerHTML={{
                  __html: notEligibleMessage,
                }}
              ></p>
            </div>
          </Popover>
        </div>
      );
    }
    return null;
  }, [
    isClaimSubmission,
    path.pathname,
    groupPoliciesWithEligibilitySubmissionHasContent,
    groupPoliciesWithEligibilitySubmission,
    navigate,
    notEligibleMessage,
    language,
  ]);

  return (
    <Fragment>
      <HeaderImage data={props.data} />
      <div className="nn-container">
        {showMessage ? (
          <section className="pageThemeHeaderButtonWrapper-container">
            <div className="pageThemeHeaderButtonWrapper">
              <HeaderMessage data={props.data} />
            </div>
            {renderClaimSubmissionButton}
            {path.pathname.includes("myAccount") &&
            accountData.Type === "Individual" ? (
              <AccountKycButton
                hasVatNumber={accountData.VatNumber}
                onClick={() => {
                  navigate("/oauth/kyc/request_for_information_update");
                }}
              />
            ) : null}
            {path.pathname.includes("myContracts") && portfolioIsShown ? (
              <Button onClick={() => navigate("/myPortfolio")}>
                {t("contracts.my-investment-choices")}
              </Button>
            ) : null}
          </section>
        ) : null}
      </div>
    </Fragment>
  );
};
export default observer(PageTheme);
