import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class UnregisterStore {
  unRegDocuments = [];
  isDocsExists = null;
  docsToDownload = [];
  authStatus = null;
  getResponce = false;
  responseStatus = null;
  applicationNumber = null;
  productDescription = null;
  surveyContents = null;
  unauthorizedSurveyContents = null;
  surveyResult = null;
  surveyIsAnswered = null;
  surveyIsExpired = null;

  constructor() {
    makeObservable(this, {
      unRegDocuments: observable,
      isDocsExists: observable,
      docsToDownload: observable,
      authStatus: observable,
      getResponce: observable,
      responseStatus: observable,
      productDescription: observable,
      surveyContents: observable,
      unauthorizedSurveyContents: observable,
      surveyResult: observable,
      surveyIsAnswered: observable,
      surveyIsExpired: observable,

      confirmDocumentsDownload: action,
      getDocsAndSurvey: action,
      getUnauthorizedSurvey: action,
    });
  }

  confirmDocumentsDownload = (urlId) => {
    try {
      if (urlId) {
        const responce = agent.unregisterDownload.successContract(urlId);
        responce.then((result) => {
          this.responseStatus = result.status;
          //console.log(result, "from result");
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getDocsAndSurvey = (token) => {
    try {
      const response =
        agent.unregisterDownload.getUnregisteredDocsAndSurvey(token);

      response.then((result) => {
        this.getResponce = true;

        const { encUnregisteredDownloadModel, survey } = result;
        const { Status, ContractNumber, EncApp, EncDocs, ProductDescription } =
          encUnregisteredDownloadModel;

        if (Status === 0) {
          this.authStatus = Status;
          if (ContractNumber) {
            this.isDocsExists = ContractNumber;
            this.applicationNumber = EncApp;
          }
          if (EncDocs) {
            this.docsToDownload = [...EncDocs];
          }
          if (ProductDescription) {
            this.productDescription = ProductDescription;
          }
        } else {
          this.authStatus = Status;
        }

        if (survey) {
          this.surveyContents = result;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  submitSurvey = (body) => {
    try {
      const response = agent.unregisterDownload.submitSurvey(body);

      response.then((result) => {
        this.surveyResult = result;
      });
    } catch (error) {
      console.log(error);
    }
  };

  getUnauthorizedSurvey = async (token) => {
    try {
      const response =
        await agent.unregisterDownload.getUnauthorizedContractSurvey(token);
      const {
        survey,
        encUnregisteredDownloadModel: { Message },
      } = response;

      if (survey) this.unauthorizedSurveyContents = response;
      if (Message === "IsAnswered") this.surveyIsAnswered = true;
      if (Message === "SurveyExpired") this.surveyIsExpired = true;
    } catch (err) {
      console.log(err);
    }
  };
}
export default createContext(new UnregisterStore());
