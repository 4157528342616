import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  Circle,
  Icon,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import InboxStore from "../../Stores/InboxStore";
import dompurify from "dompurify";
import "../../Css/Inbox/inbox-item.css";

const InboxMessageDetails = (props) => {
  const { t } = useTranslation();
  const inboxStore = useContext(InboxStore);
  const { messageDetails } = inboxStore;
  const [message, setMessage] = useState();
  const [archive, setArchive] = useState();

  const sanitizer = dompurify.sanitize;

  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state) {
      setMessage(location.state);
      inboxStore.getMessageDetails(location.state.NodeAliasPath);
      checkIfCanBeArchived(location.state.MessageExpireWhen);
    }
  }, [location.state, inboxStore]);

  useEffect(() => {
    if (props.data) {
      setMessage(props.data);
      checkIfCanBeArchived(props.data.MessageExpireWhen);
    }
  }, []);

  const checkIfCanBeArchived = (val) => {
    let inputDate = new Date(val);
    let today = new Date();
    if (inputDate < today) {
      setArchive(true);
    } else {
      setArchive(false);
    }
  };

  const archiveMessage = (DocumentId, ClassName) => {
    inboxStore.isReadPost(DocumentId, "archive", ClassName);
    setArchive(false);
  };
  return (
    <Fragment>
      {message ? (
        <>
          <div className="nn-container">
            <article id="inbox-message">
              <div className="message-header">
                <div className="header-row">
                  <div className="header-column">
                    <span className="goback">
                      <Link to="/myInbox">
                        <Circle variant="SmallAttentional">
                          <Icon
                            variant="Small"
                            name="ChevronLeft"
                            color="white"
                          />
                        </Circle>
                      </Link>
                    </span>
                    <h1 className="title">{message.Title}</h1>
                  </div>
                  <div className="header-column">
                    {message.IsArchived ? (
                      <div className="archive-message">
                        <span>{t("messages.archived-msg")}</span>
                      </div>
                    ) : archive ? (
                      <Button
                        onClick={() =>
                          archiveMessage(message.DocumentId, message.ClassName)
                        }
                        variant="PrimaryMedium"
                      >
                        {t("messages.archiving")}
                      </Button>
                    ) : (
                      <div className="button archived">
                        <span> </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="header-row">
                  <div className="header-column">
                    <span className="category">
                      {message.category
                        ? message.category
                        : message.DocumentParentName}
                    </span>
                  </div>
                  <div className="header-column">
                    {message.dateTime ? (
                      <time dateTime={message.dateTime}>
                        <b>{message.dateTime}</b>
                      </time>
                    ) : (
                      <span>
                        {new Date(message.message).toLocaleDateString("el-GR")}{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="message-body">
                {messageDetails ? (
                  <div className="body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(messageDetails.Text, {
                          ADD_ATTR: ["target"],
                        }),
                      }}
                    />
                  </div>
                ) : null}
                {props.data && message ? (
                  <div className="body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(message.Text, {
                          ADD_ATTR: ["target"],
                        }),
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </article>
          </div>
        </>
      ) : null}
    </Fragment>
  );
};

export default observer(InboxMessageDetails);
