import React, { Fragment, useContext } from "react";
import "../../../Css/home-contracts-table.css";
import "../../../";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";
import { observer } from "mobx-react-lite";
import { Table, Link } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import DetailsNavigation from "../../CommonComp/DetailsNavigation";
import { useNavigate } from "react-router-dom";
import HRStore from "../../../Stores/HRStore";

import { activeGroupContractStatus } from "../Utils/Constants";
import { RESTRICTED_ACCESS_CONTRACTS } from "../../Contracts/constants";

const ContractListHR = () => {
  const { t } = useTranslation();
  const { hrContractsList } = useContext(HRStore);
  const navigate = useNavigate();

  return (
    <>
      {hrContractsList.length > 0 ? (
        <>
          <div className="nn-container table-container home-contracts">
            <Fragment>
              <div className="table-label-with-navigation">
                <h1 className="table-label">
                  {t("navigation.item-Contracts")}
                </h1>
                <Link
                  onClick={() => navigate("/hr/contracts")}
                  text={t("hr.view-all-contracts")}
                  variant="PrimaryStandAloneStrong"
                ></Link>
              </div>
              <Table
                localeText={{ noRowsLabel: t("global.no-results-found") }}
                columns={[
                  {
                    field: "InsuranceProgram",
                    flex: 1.5,
                    maxWidth: 300,
                    headerName: t("contracts.product-name"),
                    sortable: false,
                  },
                  {
                    field: "DisplayPolicyNumber",
                    flex: 1,
                    maxWidth: 280,
                    headerName: t("contracts.insurance-number"),
                    sortable: false,
                  },
                  {
                    field: "CompanyName",
                    flex: 1,
                    maxWidth: 280,
                    headerName: t("hr.policyholder"),
                    sortable: false,
                  },
                  {
                    field: "CancellationDate",
                    flex: 1,
                    maxWidth: 220,
                    headerName: t("contracts.expiration-date"),
                    sortable: false,
                    renderCell: (params) => {
                      const valueFormatted = setDateFormatForDateTime(
                        params.row.CancellationDate,
                      );
                      return `${valueFormatted}`;
                    },
                  },
                  {
                    field: "ContractStatus",
                    headerName: "",
                    flex: 1,
                    maxWidth: 130,
                    align: "right",
                    sortable: false,
                    renderCell: (params) => {
                      const isRestrictedAccess =
                        RESTRICTED_ACCESS_CONTRACTS.some((contract) =>
                          params.row.PolicyNumber.includes(contract),
                        );
                      return (
                        activeGroupContractStatus.includes(
                          params.row.ContractStatus,
                        ) && (
                          <DetailsNavigation
                            details={params.row}
                            isDisabled={
                              sessionStorage.getItem("support") &&
                              isRestrictedAccess
                            }
                          />
                        )
                      );
                    },
                  },
                ]}
                rows={hrContractsList.map((elem, i) => {
                  return {
                    id: i,
                    ...elem,
                  };
                })}
                disableColumnReorder={true}
              />
            </Fragment>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default observer(ContractListHR);
