import React, { useCallback, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HRStore from "../../../Stores/HRStore";
import LoaderStore from "../../../Stores/LoaderStore";
import SpinnerSmall3 from "../../CommonComp/SpinnerSmall3";
import GroupPensionPaymentsSelectedTableHR from "./GroupPensionPaymentsSelectedTableHR";

const GroupPensionPayments = ({ contract }) => {
  const loaderStore = useContext(LoaderStore);
  const hrStore = useContext(HRStore);
  const { hasGroupPensionPaymentsData, groupPensionPaymentsData } = hrStore;
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (contract && !hasGroupPensionPaymentsData) {
      hrStore.loadGroupPensionHRPayments(contract);
    }
    loaderStore.setMiniLoaderForData3(true);
  }, [contract, hasGroupPensionPaymentsData, loaderStore, hrStore]);

  useEffect(() => {
    if (hasGroupPensionPaymentsData) {
      const parsePaymentPeriod = (period) => {
        const endPeriod = period.split(" - ")[1];
        const [month, year] = endPeriod.split("/").map(Number);
        return new Date(year, month - 1);
      };

      loaderStore.setMiniLoaderForData3(false);
      const data = groupPensionPaymentsData.find(
        (elem) => elem.contractId === contract,
      );

      data?.payments
        ?.sort((a, b) => new Date(b.PaymentDate) - new Date(a.PaymentDate))
        .sort((a, b) => {
          if (b.PaymentDate === a.PaymentDate) {
            return (
              parsePaymentPeriod(b.PaymentPeriod) -
              parsePaymentPeriod(a.PaymentPeriod)
            );
          }
        });
      if (data) {
        setPayments(data.payments);
      }
    }
  }, [hasGroupPensionPaymentsData, groupPensionPaymentsData, contract]);

  return (
    <div className="contract-hr-tab-container">
      {hasGroupPensionPaymentsData ? (
        <div className="selected-contract-coverages hr-coverage-selected">
          <GroupPensionPaymentsSelectedTableHR
            contract={contract}
            element={payments}
          />
        </div>
      ) : (
        <SpinnerSmall3></SpinnerSmall3>
      )}
    </div>
  );
};

export default observer(GroupPensionPayments);
