import React, { useEffect } from "react";

const Logout = (props) => {
  useEffect(() => {
    sessionStorage.removeItem("client");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("support");
    sessionStorage.removeItem("LoginLog");

    props.authService.logout();
  }, []);

  return (
    <>
      {/* <h1>you are not authorized to see this page</h1>
      <h1> press the button to be redirected to login page</h1>
      <button onClick={Testlogout}>logout</button> */}
    </>
  );
};

export default Logout;
