import {
  Paragraph,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({
  showConfirmationModal,
  handleConfirm,
  handleCancel,
  message,
}) => {
  const { t } = useTranslation();
  return (
    <PrefabModal
      isOpen={showConfirmationModal}
      onClose={() => handleCancel()}
      width="500px"
      height="auto"
      headingText={""}
      children={
        <Paragraph mb="10px" variant="Medium">
          {message}
        </Paragraph>
      }
      primaryActionProps={{
        onClick: () => {
          handleCancel();
        },
        text: t("claim.modal.confirmation.annulment"),
      }}
      secondaryActionProps={{
        onClick: () => {
          handleConfirm();
        },
        text: t("claim.modal.confirmation.confirmation"),
      }}
    />
  );
};

export default ConfirmationModal;
