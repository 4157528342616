import { useState, useMemo, useEffect } from "react";

export const usePortfolioLineChart = ({ fundPrices, dateRange }) => {
  const [zoomDomain, setZoomDomain] = useState({});

  let domainY = useMemo(() => fundPrices && Math.max(...fundPrices?.map((obj) => obj.UnitBarePrice)) + 1, [fundPrices]);

  let domainX = useMemo(() => fundPrices && Math.min(...fundPrices?.map((obj) => obj.UnitBarePrice)) - 1, [fundPrices]);

  let minimumDate = useMemo(() => fundPrices && Math.min(...fundPrices?.map((item) => new Date(item.EffectiveDate))), [fundPrices]);

  let maximumDate = useMemo(() => fundPrices && Math.max(...fundPrices?.map((item) => new Date(item.EffectiveDate))), [fundPrices]);

  let finalData = useMemo(
    () =>
      fundPrices?.map((e) => ({
        x: new Date(e.EffectiveDate),
        y: e.UnitBarePrice,
      })),
    [fundPrices]
  );

  useEffect(() => {
    const filterData = () => {
      const today = new Date(maximumDate);
      switch (dateRange) {
        case "week":
          return today.setDate(today.getDate() - 8);
        case "month":
          return today.setMonth(today.getMonth() - 1);
        case "6months":
          return today.setMonth(today.getMonth() - 6);
        case "year":
          return today.setFullYear(today.getFullYear() - 1);
        default:
          return minimumDate;
      }
    };
    setZoomDomain({ x: [filterData(), new Date(maximumDate)] });
  }, [dateRange, fundPrices]);

  return {
    zoomDomain,
    fundPrices,
    domainY,
    finalData,
    domainX,
  };
};
