import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import ContractStore from "../../Stores/ContractStore";
import CookieStore from "../../Stores/CookieStore";
import ContentModal from "../CommonComp/ContentModal";
import TermsAndConditions from "../TextDocuments/TermsAndConditions";
import OnlinePaymentPromo from "./OnlinePaymentPromo";
import PaymentStore from "../../Stores/PaymentStore";
import ModalStore from "../../Stores/ModalStore";
import { Tabs } from "@nn-design-system/react-component-library";
import { ChangeOnlinePromo } from "./ChangeOnlinePromo";

const OnlinePayments = () => {
  const { t } = useTranslation();
  const changeCard = true;
  const [isChangeCreditCard, setIsChangeCreditCard] = useState(
    sessionStorage.IsChangeCard,
  );
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const paymentStore = useContext(PaymentStore);
  const { contractList, contractsHasContent } = useContext(ContractStore);
  const { isCustomerOfNNHellas, cookieHasContent } = useContext(CookieStore);

  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";

  const { toggleTerms, showTerms } = useContext(ModalStore);
  const { paymentList } = paymentStore;
  const { transactionStatus, responsePayment } = onlinePaymentStore;

  const navigate = useNavigate();

  useEffect(() => {
    const tabs = document.querySelectorAll('[role="tab"]');
    if (isChangeCreditCard && JSON.parse(isChangeCreditCard) && tabs) {
      onlinePaymentStore.setIsChangeOnlinePaymentMehod(
        JSON.parse(isChangeCreditCard),
      );
      tabs[1].click();
    }
  }, [isChangeCreditCard]);

  useEffect(() => {
    if (transactionStatus === "Success") {
      navigate(`/online-payment/success`);
    }
    if (transactionStatus === "Failure") {
      navigate(`/online-payment/failure`);
    }
    if (transactionStatus === "TransactionInProgress") {
      navigate(`/SpecialPages/expiredPage`);
    }
  }, [transactionStatus]);

  useEffect(() => {
    if (
      checkNN2Users &&
      ((cookieHasContent && !isCustomerOfNNHellas) ||
        (contractsHasContent && contractList.length <= 0))
    ) {
      navigate(`/home`);
    }
  }, [contractsHasContent, cookieHasContent]);

  useEffect(() => {
    if (responsePayment && paymentList && transactionStatus === "Success") {
      paymentStore.updatePayment(
        responsePayment.ContractNumber,
        responsePayment.InvoiceNumber,
        responsePayment.PaidAmount,
        responsePayment.PaymentDate,
        responsePayment.StatusCode,
        responsePayment.StatusDescription,
      );
    }
  }, [responsePayment, paymentList, transactionStatus]);

  const getTabs = () => {
    let tabs = [
      {
        contentElement: (
          <div>
            <div className={`nn-container registration `}>
              <div className="left">
                <OnlinePaymentPromo />
              </div>
              <div className="right online-payment">
                <div className="top">
                  <Outlet />
                </div>
                <div className="bottom"></div>
              </div>
            </div>
          </div>
        ),
        tabText: t("online.payment"),
      },
      {
        contentElement: (
          <div>
            <div className={`nn-container registration `}>
              <div className="left">
                <div>
                  <ChangeOnlinePromo />
                </div>
              </div>
              <div className="right online-payment">
                <div className="top">
                  <Outlet context={changeCard} />
                </div>
                <div className="bottom"></div>
              </div>
            </div>
          </div>
        ),
        tabText: t("online.payment.change-card"),
      },
    ];

    return tabs;
  };

  return (
    <>
      <div id="registration-body">
        <div className="panel-payments-online">
          <Tabs tabs={getTabs()} variant="AlwaysHorizontal" />
        </div>
      </div>
      <ContentModal
        content={<TermsAndConditions />}
        showModal={showTerms}
        toggleShowModal={toggleTerms}
      />
    </>
  );
};

export default observer(OnlinePayments);
