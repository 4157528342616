import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import NavBarStore from "../../Stores/NavBarStore";
import InboxStore from "../../Stores/InboxStore";
import CookieStore from "../../Stores/CookieStore";
import ContractStore from "../../Stores/ContractStore";
import "../../Css/navigationMenu/sideNavbar.css";
import HRStore from "../../Stores/HRStore";
import { useNavBar } from "../useNavBar";

const SideNavBar = () => {
  const navBarStore = useContext(NavBarStore);
  const { navBarItemsMobile, navBarItemsHR } = useNavBar();
  const inboxStore = useContext(InboxStore);
  const { importantMessageCount } = inboxStore;
  const { isHrRole } = useContext(HRStore);

  const cookieStore = useContext(CookieStore);
  const { isCustomerOfNNHellas } = cookieStore;

  const contractStore = useContext(ContractStore);
  const { contractList, hasAtLeastOneGroupPolicy } = contractStore;

  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";

  useEffect(() => {
    navBarStore.loadNavbarItems();
    navBarStore.loadHeaders();
  }, [navBarStore]);

  const closeNav = () => {
    navBarStore.setShowSideBar(true);
  };
  return (
    <>
      {!isHrRole ? (
        <div className="navigation">
          {navBarItemsMobile?.map((element) => {
            if (element.NodeAlias === "Refunds" && !hasAtLeastOneGroupPolicy) {
              return null;
            }
            if (
              checkNN2Users &&
              (!isCustomerOfNNHellas || contractList.length <= 0) &&
              element.NodeAlias != "Home" &&
              element.NodeAlias != "myInbox"
            ) {
              return null;
            }
            let show = false;
            if (element.NodeAlias === "myInbox") {
              if (importantMessageCount != 0) {
                show = true;
              }
            } else {
              show = false;
            }
            //add onclick to close menu
            return (
              <Fragment key={element.NodeAlias}>
                <NavLink
                  to={element.Url}
                  className={(navData) =>
                    navData.isActive ? "activeLink" : element.DocumentMenuClass
                  }
                  onClick={closeNav}
                >
                  {element.Title}
                  {show ? (
                    <span id="message-count-navbar">
                      {importantMessageCount}
                    </span>
                  ) : null}
                </NavLink>
                <br></br>
              </Fragment>
            );
          })}
          <span className="force-same-line"> </span>
        </div>
      ) : (
        navBarItemsHR.map((element) => {
          return (
            <div className="navigation" key={element.NodeAlias}>
              <NavLink
                to={element.Url}
                className={(navData) =>
                  navData.isActive ? "activeLink" : element.DocumentMenuClass
                }
                onClick={closeNav}
              >
                {element.Title}
                {/* {true ? <span id="message-count-navbar">{importantMessageCount}</span> : null} */}
              </NavLink>
              <br></br>
            </div>
          );
        })
      )}
    </>
  );
};

export default observer(SideNavBar);
