import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class SpecialPagesStore {
  specialPageResponse = null;
  infoPageResponse = null;
  infoPublicPageResponse = null;
  consentPublicPageResponse = null;
  consentPageResponse = null;
  eClaimsTermsAndConditionsPageResponse = null;

  constructor() {
    makeObservable(this, {
      specialPageResponse: observable,
      infoPublicPageResponse: observable,
      infoPageResponse: observable,
      consentPublicPageResponse: observable,
      consentPageResponse: observable,
      eClaimsTermsAndConditionsPageResponse: observable,

      loadSpecialPage: action,
      loadInfoPage: action,
      loadInfoPublicPage: action,
      loadEClaimsTermsAndConditionsPage: action,
    });
  }

  loadSpecialPage = (url) => {
    const specialPage = agent.specialPages.getSpecialPage(url);
    specialPage
      .then((result) => {
        this.specialPageResponse = { ...result };
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loadInfoPage = (url) => {
    const specialPage = agent.infoPages.getInfoPage(url);
    specialPage
      .then((result) => {
        this.infoPageResponse = { ...result };
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loadInfoPublicPage = (url) => {
    const specialPage = agent.infoPages.getInfoPublicPage(url);
    specialPage
      .then((result) => {
        this.infoPublicPageResponse = { ...result };
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loadConsentPage = (url) => {
    const specialPage = agent.consentPages.getConsentPage(url);
    specialPage
      .then((result) => {
        this.consentPageResponse = { ...result };
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loadConsentPublicPage = (url) => {
    const specialPage = agent.consentPages.getConsentPublicPage(url);
    specialPage
      .then((result) => {
        this.consentPublicPageResponse = { ...result };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadEClaimsTermsAndConditionsPage = (url) => {
    const response = agent.eClaimsTermsAndConditionsPages.getEClaimsTermsAndConditionsPage(url);
    response
      .then((result) => {
        this.eClaimsTermsAndConditionsPageResponse = { ...result };
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export default createContext(new SpecialPagesStore());
