import React from "react";
import { Icon, SpinnerLoader } from "@nn-design-system/react-component-library";
import { useState } from "react";

export const PrintButtonAsBinary = ({ base64String }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadDocument = async (base64String) => {
    setIsLoading(true);
    const byteCharacters = atob(base64String.toString());
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const dataUrl = URL.createObjectURL(blob);

    const newWindow = window.open(dataUrl, "_blank");
    setIsLoading(false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    newWindow.focus();
    newWindow.print();
  };
  return (
    <>
      {isLoading ? (
        <SpinnerLoader variant="Medium" />
      ) : (
        <a className="active" onClick={async () => await downloadDocument(base64String, "test to print")}>
          {<Icon name="Printer" variant="Large" color="#EE7F00" />}
        </a>
      )}
    </>
  );
};
