import React from "react";
import { VictoryPie, VictoryTooltip } from "victory";

export const PortfolioPieChart = ({ data }) => {
  return (
    <section style={{ width: 300, height: 300 }}>
      <VictoryPie
        innerRadius={100}
        labelRadius={120}
        labels={({ datum }) => `€ ${datum?.fundValue?.toLocaleString("el-GR")}`}
        data={data}
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
          },
          labels: { fill: "black", fontSize: 16 },
        }}
        labelComponent={
          <VictoryTooltip
            flyoutStyle={{ strokeWidth: 0, fill: "transparent" }}
            constrainToVisibleArea
            flyoutWidth={100}
            flyoutHeight={100}
            pointerLength={0}
            cornerRadius={50}
            orientation="top"
            x={200}
            y={250}
          />
        }
      />
    </section>
  );
};
