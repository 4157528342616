import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";

class ModalStore {
  showTerms = false;
  showRegExample = false;
  showGlobalCon = false;

  constructor() {
    makeObservable(this, {
      showTerms: observable,
      showRegExample: observable,
      showGlobalCon: observable,

      toggleTerms: action,
      toggleRegExample: action,
      toggleGlobalCon: action,
    });
  }
  toggleTerms = () => {
    this.showTerms = !this.showTerms;
  };
  toggleRegExample = () => {
    this.showRegExample = !this.showRegExample;
  };
  toggleGlobalCon = () => {
    this.showGlobalCon = !this.showGlobalCon;
  };
}
export default createContext(new ModalStore());
