import React, { useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import OnlinePaymentStep1 from "./OnlinePaymentStep1";
import ChangeCardStep1 from "./ChangeCardStep1";

const OnlinePaymentTransactions = () => {
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const changeCard = useOutletContext();

  useEffect(() => {
    onlinePaymentStore.setIsChangeOnlinePaymentMehod(changeCard);
  }, [changeCard]);

  return !changeCard ? <OnlinePaymentStep1 /> : <ChangeCardStep1 />;
};

export default OnlinePaymentTransactions;
