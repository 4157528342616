import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import $ from "jquery";
window.jQuery = $;
require("signalr");

class SignalRStore {
  hubConnection = null;
  hubConnectionId = null;
  constructor() {
    makeObservable(this, {
      hubConnection: observable,
      hubConnectionId: observable,

      createHubConnection: action,
      createHubProxy: action,
      startHubConnection: action,
      stopHubConnection: action,
      getHubConnectionId: action,
    });
  }
  createHubConnection = (baseURL) => {
    this.hubConnection = $.hubConnection(baseURL);
  };

  createHubProxy = (hubName) => {
    return this.hubConnection?.createHubProxy(hubName);
  };

  startHubConnection = () => {
    return this.hubConnection?.start({ withCredentials: false }).always(() => {
      this.hubConnectionId = this.hubConnection?.id;
    });
  };

  stopHubConnection = () => {
    this.hubConnection?.stop(false, true);
    this.hubConnection = null;
  };

  getHubConnectionId = () => {
    return this.hubConnectionId;
  };
}

export default createContext(new SignalRStore());
