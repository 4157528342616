import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CheckBox from "../Inbox/CheckBox";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import SettingsStore from "../../Stores/SettingsStore";
import { observer } from "mobx-react-lite";
import { Button } from "@nn-design-system/react-component-library";
import ModalStore from "../../Stores/ModalStore";
const RegisterFormStep3 = (props) => {
  const { t } = useTranslation();
  const [activeSubmit, setActiveSubmit] = useState(false);
  const { publicSettings } = useContext(SettingsStore);
  const { toggleTerms, toggleGlobalCon } = useContext(ModalStore);
  const [toggleLoader, setToggleLoader] = useState("hide");
  const [buttonVariable, setButtonVariable] = useState("SecondaryMedium");
  const navigate = useNavigate();

  const onClick = () => {
    if (activeSubmit) {
      setToggleLoader("");
      props.submitReg();
    }
  };

  useEffect(() => {
    if (
      props.regValues.isChecked &&
      props.regValues.captchaValue &&
      props.regValues.consentisChecked
    ) {
      setActiveSubmit(true);
      setButtonVariable("PrimaryMedium");
    } else {
      setActiveSubmit(false);
      setButtonVariable("SecondaryMedium");
    }
  }, [
    props.regValues.isChecked,
    props.regValues.captchaValue,
    props.regValues.consentisChecked,
  ]);

  const setValueFromCaptcha = (value) => {
    props.regValues.setCaptchaValue(value);
  };

  return (
    <>
      {publicSettings ? (
        <>
          <div>
            <h2>
              <span className="light">{t("register.sign-up")}</span>{" "}
              <span className="normal">{t("register.in-the-nn")}</span>
            </h2>
            <div className="form-element checkbox nn">
              <label>{t("online.payment.accept-terms-use")}</label>
              <div className=" checkbox-container-register-3">
                <CheckBox
                  id={"checkbox-register-3"}
                  dataName={"useTerms"}
                  data={[props.regValues, "normal"]}
                  innerText={t("online.payment.text-terms-use")}
                />
                <div className="anchor-modal">
                  <a
                    onClick={() => toggleTerms()}
                    className="link"
                    data-toggle="modal"
                    data-target="#modal-terms"
                  >
                    {t("global.terms_of_use")}
                  </a>
                </div>
              </div>
              <br />
              <label>{t("register.acceptance-upgrading-electronic")}</label>
              <div className=" checkbox-container-register-3">
                <CheckBox
                  id={"checkbox-register-3"}
                  dataName={"useGlobalConsent"}
                  data={[props.regValues, "global"]}
                  innerText={t("online.payment.text-terms-use")}
                />
                <div className="anchor-modal">
                  <a
                    onClick={() => toggleGlobalCon()}
                    className="link"
                    data-toggle="modal"
                    data-target="#modal-globalconsent"
                  >
                    {t("register.terms-exclusive-electronic")}
                  </a>
                </div>
              </div>
            </div>
            <div className="form-element right-padded">
              <label>{t("register.complete-challenge")}</label>
              <ReCAPTCHA
                sitekey={publicSettings.CaptchaSiteKey}
                onChange={setValueFromCaptcha}
              />
            </div>
            <div className={`reg-loader ${toggleLoader}`}>
              <div></div>
            </div>
            <div className="form-element button">
              <div className={"radiogroup topbutton"}>
                <Button
                  onClick={() =>
                    navigate(
                      props.partnerRegistration
                        ? "/partner/register/2"
                        : "/register/2",
                    )
                  }
                  variant={"SecondaryMedium"}
                >
                  {t("global.previous")}
                </Button>
                <Button onClick={() => onClick()} variant={buttonVariable}>
                  {t("commons.submission")}
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default observer(RegisterFormStep3);
