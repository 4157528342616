import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
const BreadCrumb = (props) => {
  const { t } = useTranslation();
  const [primaryLink, setPrimaryLink] = useState("");
  const [primaryName, setPrimaryName] = useState("");
  const [secondaryName, setSecondaryName] = useState("");
  const path = useLocation();

  useEffect(() => {
    if (path.pathname.includes("myContracts")) {
      setPrimaryLink("/myContracts");
      setPrimaryName(t("global.my-contracts"));
      if (props) {
        setSecondaryName(props.Title);
      }
    }
    if (path.pathname.includes("knowledgepoint")) {
      setPrimaryLink("/knowledgepoint");
      setPrimaryName(t("breadcrumbs.mynn-articles"));
      if (props) {
        setSecondaryName(props.Title);
      }
    }
    if (path.pathname.includes("hr/contracts")) {
      setPrimaryLink("/hr/contracts");
      setPrimaryName(t("navigation.item-Contracts"));
      if (props) {
        setSecondaryName(props.Title);
      }
    }

    if (path.pathname.includes("preview")) {
      setPrimaryLink(path.pathname);
      setPrimaryName(t("breadcrumbs.mynn-articles"));
      if (props) {
        setSecondaryName(props.Title);
      }
    }
    return () => {
      setPrimaryLink("");
      setPrimaryName("");
      setSecondaryName("");
    };
  }, [props]);

  return (
    <div className="breadcrumbs">
      <Link to={primaryLink} style={{ textDecoration: "none" }}>
        {primaryName}
      </Link>
      <span className="separator">{" / "}</span>
      <span>{secondaryName}</span>
    </div>
  );
};

export default BreadCrumb;
