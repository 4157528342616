import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import GroupPensionAccountBalanceTable from "./GroupPensionAccountBalanceTable";
import styles from "../../../Css/GroupPension/GroupPension.module.scss";
import HRStore from "../../../Stores/HRStore";
import LoaderStore from "../../../Stores/LoaderStore";
import SpinnerSmall3 from "../../CommonComp/SpinnerSmall3";
const GroupPensionAccountBalance = ({ contract }) => {
  const { t } = useTranslation();
  const loaderStore = useContext(LoaderStore);
  const hrStore = useContext(HRStore);
  const { hasGroupPensionAccountBalanceData, groupPensionAccountBalanceData } =
    hrStore;
  const [accountBalanceData, setAccountBalanceData] = useState([]);
  const [balanceLastUpdateOn, setBalanceLastUpdateOn] = useState("");

  useEffect(() => {
    if (contract && !hasGroupPensionAccountBalanceData) {
      hrStore.loadGroupPensionHRAccountBalance(contract);
    }
    loaderStore.setMiniLoaderForData3(true);
  }, [contract, groupPensionAccountBalanceData, loaderStore, hrStore]);

  useEffect(() => {
    if (hasGroupPensionAccountBalanceData) {
      loaderStore.setMiniLoaderForData3(false);
      const data = groupPensionAccountBalanceData.find(
        (elem) => elem.contractId === contract,
      );
      if (data) {
        setAccountBalanceData(data.Investments);
        setBalanceLastUpdateOn(data.BalanceLastUpdateOn);
      }
    }
  }, [
    hasGroupPensionAccountBalanceData,
    groupPensionAccountBalanceData,
    contract,
  ]);

  return (
    <div className="contract-hr-tab-container">
      {hasGroupPensionAccountBalanceData ? (
        <>
          <GroupPensionAccountBalanceTable data={accountBalanceData} />
          <div className={styles.chartDisclaimer}>
            <span>
              {t("hr.last-balance-update")}: {balanceLastUpdateOn}
            </span>
          </div>
        </>
      ) : (
        <SpinnerSmall3></SpinnerSmall3>
      )}
    </div>
  );
};
export default observer(GroupPensionAccountBalance);
