import React, { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import {
  Label,
  RadioButtonGroup,
  RadioButton,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ClaimElementMobileView from "./ClaimElementMobileView";
import ElementDownload from "../Document/ElementDownload";
import styles from "../../Css/Claims/Claims.module.scss";

const ClaimsListMobileView = (props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [modalDocList, setModalDocList] = useState([]);
  const [radioBtnsValue, setRadioBtnsValue] = useState("");
  const [filteredClaimsList, setFilteredClaimsList] = useState(
    props.claimsList,
  );

  const handleModalClick = useCallback(
    (props) => {
      setShowModal(props.showModalVal);
      setModalDocList(props.documentList);
    },
    [setModalDocList, setShowModal],
  );

  const getStatusFilteredContractList = useCallback(
    (statusValue) => {
      if (statusValue === "Processing") {
        return props.claimsList.filter(
          (c) => c.Status === statusValue || c.Status === "Pending",
        );
      }
      return props.claimsList;
    },
    [props.claimsList],
  );

  const radioBtnsHandler = useCallback(
    (e) => {
      if (e.target.value === "Processing") {
        setRadioBtnsValue("Processing");
      } else {
        setRadioBtnsValue("");
      }
      setFilteredClaimsList(getStatusFilteredContractList(e.target.value));
    },
    [setFilteredClaimsList, getStatusFilteredContractList],
  );

  return (
    <>
      <div className="nn-container">
        <PrefabModal
          isOpen={showModal}
          hasCloseButton
          children={
            <div className={styles.mobileViewModalContentContainer}>
              <span className={styles.header}>
                <Label text={t("claim.detail")} />
              </span>
              {modalDocList &&
                modalDocList.map((elem) => {
                  return (
                    <div className={styles.modalRow}>
                      <Label text={elem.File.Title} />
                      <ElementDownload
                        DocumentLink={elem.File.Path}
                        DocumentType={elem.File.Name}
                        MimeType={elem.File.MimeType}
                      />
                    </div>
                  );
                })}
            </div>
          }
          onClose={() => {
            setShowModal(false);
          }}
          width="auto"
          height="auto"
          headingText={" "}
        />
        <RadioButtonGroup
          labelText=""
          mt={"-15px"}
          mb={"20px"}
          value={radioBtnsValue}
          onChange={radioBtnsHandler}
          variant={"Default"}
        >
          <div
            className={`${styles.mobileView} ${styles.radioBtnsGroupContainer}`}
          >
            <RadioButton labelText={t("global.pending")} value={"Processing"} />
            <RadioButton labelText={t("global.all")} value={""} />
          </div>
        </RadioButtonGroup>

        {filteredClaimsList &&
          filteredClaimsList.map((element, i) => {
            return (
              <ClaimElementMobileView
                key={element.RowId}
                element={element}
                handleModalClick={(modalInfo) => {
                  handleModalClick(modalInfo);
                }}
              />
            );
          })}
        {filteredClaimsList && filteredClaimsList.length === 0 && (
          <div className={styles.mobileViewNoRecords}>
            <span>{t("claim.no-entries")}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(ClaimsListMobileView);
